.m-infovcard {
    $root: &;
    .a-headline {
        line-height: 24px;
        margin-bottom: 50px;
        @include breakpoint(xmedium) {
            margin-bottom: 80px;
            line-height: 1.3;
        }
    }

    &__contacts {
        display: flex;
        flex-wrap: wrap;

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-bottom: 50px;

            @include breakpoint(xmedium) {
                width: 50%;
                margin-bottom: 80px;
                flex-direction: row;
                align-items: flex-start;

                &:nth-child(odd) {
                    padding-right: 20px;
                }
                &:nth-child(even) {
                    padding-left: 20px;
                }
            }
        }

		&__wrapper--gridContext{
			@include breakpoint(xmedium) {
				width: 100% !important;
			}
		}

        &__informations {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 15px 0;

            @include breakpoint(xmedium) {
                align-items: flex-start;
                padding: 22px 55px 30px 18px;
                flex-direction: row;
                flex-wrap: wrap;
            }

            .a-headline {
                margin-bottom: 8px;
                @include use-font(bold);

                @include breakpoint(xmedium) {
                    width: 100%;
                }
            }

            &__title {
                margin-bottom: 16px;
                @include breakpoint(xmedium) {
                    width: 100%;
                }
            }

            &__text {
                margin-bottom: 16px;
                text-align: center;
                @include breakpoint(xmedium) {
					width: 100%;
                    margin-bottom: 40px;
                    text-align: left;
                }
            }

            &__links {
                display: flex;
                flex-direction: column;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 37px;

                @include breakpoint(xmedium) {
                    flex: 3;
                    margin-bottom: 0;
                }

				ul{
					list-style-type: none;
					margin-left: 0;
				}

                span {
                    @include use-font(bold);
                }
            }

            &__socials {
                width: 70%;
                display: flex;
                justify-content: space-between;
                justify-content: space-evenly;

                @include breakpoint(xmedium) {
                    flex: 1;
                    padding-top: 8px;
                }
            }

            &--backgroundColor {
                background: $primary-color;
            }
        }
    }

    &__image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin-bottom: 15px;

        @include breakpoint(xmedium) {
            width: 170px;
            height: 170px;
            margin-right: 20px;
        }
    }
}
