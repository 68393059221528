.m-contentnav {
	$root: &;
	$decoration-width: 60px;
	$decoration-height: calc(1rem - 2px);
	$decoration-margin: map-get($spacing, small);

	position: relative;
	height: $contentnav-height;

	&__container {
		display: flex;
		align-items: center;
		transform: translateX(#{$decoration-width + $decoration-margin});
		transition: transform 400ms ease-out;
		max-width: calc(100% - #{$decoration-width + $decoration-margin});
	}

	&__outer-container {
		&:before {
			position: absolute;
			top: 50%;
			content: '';
			display: block;
			width: $decoration-width;
			height: $decoration-height;
			background-color: $primary-color;
			margin-right: $global-margin;
			transform: translateY(-50%);
			transform-origin: left center;
			transition: transform 300ms ease-out 200ms;
		}
	}

	&__nav {
		transition: top $expo-tween-t1-time $expo-tween-t1;
		background-color: $white;
		width: 100%;
		padding: 0;

		&.is-sticky {
			z-index: z(content-navigation);
			background-color: $white;
			max-width: $global-max-width;
			position: fixed;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			border-bottom: 1px solid $color-borders;

			// set top distance only if header is visible -> is hidden in Viega app
			.is-scrollup:not(.has-hidden-header) & {
				top: $header-height--mobile;

				@include breakpoint(xmedium) {
					top: $header-height;
				}
			}

			#{$root}__container {
				transform: translateX(0);
				max-width: inherit;
				transition-delay: 200ms;
			}

			#{$root}__outer-container {
				@include xy-grid-container;

				&:before {
					transform: translateY(-50%) scaleX(0);
					transition-delay: 0s;
				}
			}
		}
	}

	&__items {
		@include use-font(roman);
		flex: 1;
		position: relative;
		font-size: $global-font-size;
		list-style: none;
		display: flex;
		flex-flow: nowrap;
		align-items: center;
		margin: 0;
		overflow-x: auto;
		scrollbar-width: none;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;

		&::-webkit-scrollbar {
			width: 0 !important;
			height: 0 !important;
		}

		&:after {
			position: relative;
			padding-right: map-get($spacing, xsmall);
			height: 1px;
			display: block;
			content: '';
		}
	}

	&__item {
		white-space: nowrap;

		&:first-child {
			#{$root}__link {
				padding-left: 0;
			}
		}
	}

	&__link {
		color: $light-gray;
		padding: map-get($spacing, xsmall) map-get($spacing, small);
		display: block;
		transition: color 300ms ease-out;

		&:focus {
			color: $light-gray;
		}

		&:hover,
		&.is-active {
			color: $secondary-color;
		}
	}

	.is-scrollup & {
		#{$root}__nav.is-stuck {
			top: $header-height--mobile !important;

			@include breakpoint(xmedium) {
				top: $header-height !important;
			}
		}
	}

	&__underline {
		&:after {
			position: relative;
			display: block;
			content: '';
			z-index: 3;
		}
	}

	&__arrow {
		display: flex;
		z-index: 2;

		&.is-active {
			.a-svgicon {
				color: $secondary-color;
			}
		}

		&[data-arrow-direction='left'] {
			margin-right: map-get($spacing, xsmall);
		}

		&[data-arrow-direction='right'] {
			margin-left: map-get($spacing, xsmall);
		}
	}

	&__icon {
		color: $light-gray;
	}
}
