.m-searchinputnew {
	$root: &;

	display: flex;
	align-items: center;
	height: 100%;

	&__container {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&__input[type='search'] {
		@include use-font(bold);
		background-color: $dark-gray;
		// -webkit-appearance: none;
		appearance: none;
		border: none;
		border-radius: 0;
		box-shadow: none;
		font-size: rem-calc(18px);
		text-align: right;
		color: $primary-color;
		margin: 0 12px 0 0;
		padding: 0;
		overflow: hidden;

		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			// -webkit-appearance:none;
			display: none;
		}

		&::placeholder {
			@include use-font(light);
			padding: 0;
			color: $light-gray;
			transform-origin: right center;
			transition: all 300ms ease-out;
		}

		&::-ms-clear {
			display: none;
		}

		&:hover,
		&:focus {
			~ #{$root}__search-submit {
				color: $primary-color;
			}
		}

		&:focus {
			caret-color: $primary-color;
			background: transparent;
			box-shadow: none;

			&::placeholder {
				transform: scaleX(0);
				opacity: 0;
			}
		}

		&:not(:placeholder-shown){
			~ #{$root}__search-clear {
				opacity: 1;
				margin: 0 12px 0 0;
			}
		}

	}

	&__search-submit {
		color: $white;
		cursor: pointer;
		border-radius: 0;
		transition: color 300ms ease-out;

		&:hover {
			color: $primary-color;
		}
	}

	&__search-clear {
		opacity: 0;
		transition: opacity 300ms ease-out, margin 300ms ease-out;
		cursor: pointer;
		color: $light-gray;
		margin: 0;
	}

	&__overlay {
		position: absolute;
		top: $header-height--mobile;
		left: 0;
		width: 100%;
		min-height: calc(100vh);
		pointer-events: none;

		&.is-active {
			pointer-events: all;
		}

		@include breakpoint(xmedium) {
			top: $header-height;
		}
	}

	&__shader {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		will-change: background-color;
		// close animation
		background-color: transparent;
		transition: background-color .2s $expo-tween-t1 300ms;
		z-index: z(content-shader);

		.is-active > & {
			// open animation
			background-color: $color-search-overlay;
			transition: background-color $expo-tween-t1-time $expo-tween-t1;

			// IE11 variant
			@include ie11Check {
				background-color: rgba(45, 45, 45, 0.97);
			}
		}
	}

	&__overlay-content {
		@include xy-grid-container;
		@include xy-grid;
		position: relative;
		flex-direction: column;
		justify-content: space-between;
		padding-top: map-get($spacing, small);
		opacity: 0;
		will-change: transform, opacity;
		transform: translateY(-1 * map-get($spacing, medium));
		// close animation
		transition: transform .2s $expo-tween-t1,
			opacity .2s $expo-tween-t1;
		z-index: z(navigation-flyout);
		overflow: scroll;

		.is-active > & {
			transform: translateY(0%);
			opacity: 1;
			// open animation
			transition: transform $expo-tween-t1-time $expo-tween-t1,
				opacity $expo-tween-t1-time $expo-tween-t1 200ms;
		}

		@include breakpoint(large) {
			padding-top: map-get($spacing, large);
			flex-direction: row;
		}
	}

	&__overlay-column {
		@include breakpoint(large) {
			@include xy-cell(1 of 2);
		}
	}

	&__close-button,
	&__clear-button {
		display: inline-block;
		margin-bottom: map-get($spacing, small);
		color: $white;
	}

	&__close-button {
		display: inline-block;
		margin-bottom: map-get($spacing, small);
		color: $white;

		&:hover {
			color: $white;
		}
	}

	&__recent-searches-headline,
	&__search-item-headline {
		@include use-font(light);
		display: block;
		color: $white;
		margin: 0;
		padding: map-get($spacing, xsmall) 0;
		line-height: 24px;
		font-size: 16px;
	}

	&__search-item-headline {
		padding-top: map-get($spacing, medium);
	}

	&__recent-searches {
		margin: 0 0 map-get($spacing, xsmall) 0;
	}

	&__recent-search {
		color: $light-gray;
		display: flex;
		justify-content: space-between;
		padding: map-get($spacing, xsmall) 0;

		&.is-active {
			color: $white;
		}
	}

	&__recent-search-text {
		cursor: pointer;
		flex-grow: 1;
		flex-shrink: 1;
		hyphens: auto;
		margin-right: map-get($spacing, small);
	}

	&__suggestion {
		position: relative;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		color: $light-gray;

		&.is-active {
			color: $white;

			&::before {
				background-color: $color-search-result;
			}
		}

		&::before {
			content: '';
			position: absolute;
			display: block;
			left: -1 * map-get($spacing, small);
			top: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;
			pointer-events: none;

			@include breakpoint(large) {
				width: 100%;
			}
		}
	}

	&__suggestion-text {
		padding: map-get($spacing, xsmall) 0;
		cursor: pointer;
		flex-grow: 1;
		margin-right: map-get($spacing, small);
	}

	&__suggestion-button {
		display: flex;
		align-items: center;
		justify-content: center;
		color: currentColor;
		cursor: pointer;
		flex-shrink: 0;
		font-family: sans-serif;
		height: 40px;
		width: 40px;

		@include breakpoint(large) {
			display: none;
		}
	}

	&__result {
		display: flex;
		align-items: flex-start;
		margin-bottom: map-get($spacing, xsmall);
		padding: map-get($spacing, small) 0;
		position: relative;
		cursor: pointer;

		&.is-active {
			&::before {
				background-color: $color-search-result;
			}
		}

		&::before {
			content: '';
			position: absolute;
			display: block;
			left: -1 * map-get($spacing, small);
			top: 0;
			width: 100vw;
			height: 100%;
			background-color: transparent;

			@include breakpoint(large) {
				width: 100%;
			}
		}
	}

	&__result-image-container {
		display: none;

		@include breakpoint(large) {
			@include xy-cell($size: 1 of 4, $gutter-position: right);
			position: relative;
			height: 0;
			padding-bottom: 20%;
			min-height: 50px;
			z-index: 1;
			display: block;
		}
	}

	&__result-image {
		display: none;

		@include breakpoint(large) {
			@include object-fit(cover);
			display: block;
			z-index: 1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__result-copy {
		z-index: 1;
		color: #fff;

		@include breakpoint(large) {
			@include xy-cell($size: 3 of 4, $gutter-position: left);
		}
	}

	&__result-description {
		@include use-font(light);
	}

	strong {
		@include use-font(light);
	}
}
