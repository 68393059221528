.g-spacing {
	// generate spacer styles
	@each $spacingName, $spacingSize in $spacing {
		&-bottom--#{$spacingName} {
			margin-bottom: $spacingSize;
		}
	}
	@each $breakpointName, $breakpointSize in $breakpoints {
		@each $spacingName, $spacingSize in $spacing {
			@if $breakpointSize != 0 {
				@include breakpoint($breakpointName) {
					&-bottom--#{$spacingName}\@#{$breakpointName} {
						margin-bottom: $spacingSize;
					}
				}
			}
		}
	}
}
