.m-hotspotimage {
	$root: &;
	position: relative;
	width: 100%;
	margin: auto;

	&.is-cropped {
		margin: 10%;
	}

	&__image {
		max-height: 100%;
		width: 100%;
	}

	&__hotspot-list {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		list-style-type: none;
		margin: 0;
		transition: opacity $expo-tween-t1-time $expo-tween-t1;
	}

	&__hotspot {
		position: absolute;
		top: 0;
		transform: translate(0, 0);

		@include breakpoint(medium) {
			z-index: z(hotspot);
		}

		&--left {
			#{$root}__hotspot-content {
				@include breakpoint(xmedium) {
					left: -376px;
					transform-origin: top right;
				}
			}
		}

		&--up {
			#{$root}__hotspot-content {
				@include breakpoint(xmedium) {
					transform-origin: bottom left;
				}
			}
		}

		&--up.m-hotspotimage__hotspot--left {
			#{$root}__hotspot-content {
				@include breakpoint(xmedium) {
					transform-origin: bottom right;
				}
			}
		}

		&--center-self {
			transform: translate(
				-#{$hotspot-button-size / 2},
				-#{$hotspot-button-size / 2}
			);

			&.is-active {
				transform: none;

				@include breakpoint(xmedium) {
					transform: translate(
						-#{$hotspot-button-size / 2},
						-#{$hotspot-button-size / 2}
					);
				}
			}
		}

		&--translate-xy {
			transform: translate(-$hotspot-button-size, -$hotspot-button-size);

			&.is-active {
				transform: none;

				@include breakpoint(xmedium) {
					transform: translate(
						-$hotspot-button-size,
						-$hotspot-button-size
					);
				}
			}
		}

		&--translate-x {
			transform: translate(-$hotspot-button-size, 0);

			&.is-active {
				transform: none;

				@include breakpoint(xmedium) {
					transform: translate(-$hotspot-button-size, 0);
				}
			}
		}

		&--translate-y {
			transform: translate(0, -$hotspot-button-size);

			&.is-active {
				transform: none;

				@include breakpoint(xmedium) {
					transform: translate(0, -$hotspot-button-size);
				}
			}
		}

		&:nth-child(2n) {
			#{$root}__hotspot-button {
				animation-delay: 0.7s;
			}
		}

		&:nth-child(3n) {
			#{$root}__hotspot-button {
				animation-delay: 0.4s;
			}
		}

		&.is-active {
			@include breakpoint(medium down) {
				position: relative;
				top: -20px !important;
				left: 0 !important;
				height: 100vh;
			}

			&:hover {
				@include svg-scale;
			}

			#{$root}__hotspot-icon {
				opacity: 1;
				transform: scale3d(1, 1, 1);
			}

			#{$root}__hotspot-button {
				position: fixed;
				animation: none;
				border: 0;
				background-color: transparent;
				z-index: 3;
				width: 50px;
				height: 50px;
				right: 0;
				top: auto;
				left: 50%;
				transform: translateX(-50%);
				bottom: 30px;

				@include breakpoint(xmedium) {
					position: absolute;
					width: $hotspot-button-size;
					height: $hotspot-button-size;
					bottom: auto;
					right: initial;
					background-color: $primary-color;
					transform: none;
				}
			}

			#{$root}__hotspot-content {
				transform: translateY(0);
				opacity: 1;
				visibility: visible;
				transition: all 200ms ease-out;

				// will trigger if hotspot element is moved via javascript -> see m-hotspotimage_controller.js line 32
				animation: show-hotspot-content 200ms ease-out forwards;

				@keyframes show-hotspot-content {
					from {
						transform: translateY(100%);
					}
					to {
						transform: translateY(0);
					}
				}

				@include breakpoint(xmedium) {
					transform: scale(1);
					height: auto;

					@keyframes show-hotspot-content {
						from {
							transform: scale(0);
						}
						to {
							transform: scale(1);
						}
					}
				}
			}
		}
	}

	&__hotspot-button {
		animation: pulsate 2s ease-out infinite;
		cursor: pointer;
		border-radius: 50%;
		width: $hotspot-button-size;
		height: $hotspot-button-size;
		position: absolute;
		top: 0;
		z-index: 1;
		background-color: $primary-color;
		transition: background-color 400ms;

		@include breakpoint(xmedium) {
			top: 0;
			left: 0;
			box-shadow: 0 0 4px rgba(#000, 0.5);
			background-color: $primary-color;
			transition: none;
		}
	}

	&__hotspot-icon {
		opacity: 0;
		transform: scale3d(0, 0, 0);
		transition: all 200ms ease-out;
	}

	&__hotspot-content {
		@include use-font(normal);
		background: $primary-color;
		padding: 30px;
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0;
		visibility: hidden;
		user-select: none;
		z-index: 2;
		width: 100vw;
		height: 100vh;
		transform: translateY(100%);
		display: flex;
		flex-flow: column;
		justify-content: center;

		@include breakpoint(xmedium) {
			display: block;
			position: absolute;
			transition: all 200ms ease-out;
			transform-origin: top left;
			top: 16px;
			left: 16px;
			width: 390px;
			height: auto;
			transform: scale(0);
		}
	}

	&__hotspot-headline {
		@include use-font(bold);

		&::after {
			display: block;
			content: '';
			border-top: 4px solid currentColor;
			width: 90px;
			margin: 20px 0;
		}
	}
}
