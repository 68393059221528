.o-gallery {
	$root: &;

	opacity: 0;
	pointer-events: none;
	transition: opacity 200ms ease-out;

	&.is-active {
		opacity: 1;
		pointer-events: all;

		#{$root}__galleryarrows {
			opacity: 0;
			pointer-events: none;

			@include breakpoint(xmedium) {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

	&__slider-container {
		position: relative;
		z-index: 1;
		border: 1px solid $color-borders;
	}

	// force each slide to be a flex container
	&__slider {
		&.slick-initialized {
			.slick-slide {
				display: flex;
				flex-direction: column;
			}
		}
	}

	// force same height of each slide
	.slick-track {
		display: flex !important;
		margin: 0;
	}

	&__slide {
		position: relative;

		&.slick-slide {
			height: inherit !important;
		}
	}

	&__image {
		@include object-fit(contain);
		height: 100%;
		max-height: calc(90vh - #{$header-height});
	}

	/**
	 *	Dots-navigation
	 */
	&__dots {
		@include slider-dots();
	}

	/**
	 *	Description
	 */
	&__caption-container {
		$slider-container: &;
		position: relative;
		background-color: $accent-color;
		flex-grow: 1;
		display: flex;
		align-items: center;
	}

	&__caption {
		padding: 20px;
		display: flex;

		@include breakpoint(xmedium) {
			padding: 20px 100px 20px 20px;
		}
	}

	&__caption-index {
		@include use-font(bold);
		margin-right: 20px;
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}

	&__caption-text {
		margin: 0;
		display: flex;
		align-items: center;
		text-align: left;
		flex-shrink: 1;
	}

	/**
	 *	Thumbs-navigation
	 */
	&__thumb-list {
		position: relative;
		list-style-type: none;
		margin: map-get($spacing, small) 0 0 0;
		display: flex;
		flex-flow: row wrap;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			margin-left: -(map-get($spacing, small));
		}

		@supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));
			grid-template-rows: auto;
			grid-gap: map-get($spacing, small);
		}
	}

	&__thumb-image {
		@include object-fit(contain);
		transition: opacity 0.3s $expo-tween-t1;
		opacity: 0.4;
		width: auto;
		height: 100%;

		&:hover {
			opacity: 1;
		}
	}

	&__thumb-item {
		cursor: pointer;
		position: relative;
		border: 1px solid $color-borders;
		text-align: center;
		width: 98px;
		height: 98px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			margin: 0 0 map-get($spacing, small) map-get($spacing, small);
		}

		&.is-active {
			#{$root}__thumb-image {
				opacity: 1;
			}
		}
	}
}
