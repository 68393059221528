.m-overlay {
	$root: &;

	background-color: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	z-index: z(overlays);

	&.is-active {
		display: flex;
		align-items: center;
	}

	&__container {
		@include xy-grid-container;
		background-color: $white;
		margin: auto;
		padding-top: map-get($spacing, small);
		padding-bottom: map-get($spacing, small);
		height: auto;
		max-height: 90vh;
		overflow-y: auto;
		width: 100%;

		&-html {
			@include xy-cell(12 of 12);
			margin-top: map-get($spacing, xsmall);
			position: relative;
		}

		&-close {
			margin-left: auto;
			margin-right: rem-calc(map-get($grid-margin-gutters, medium) / 2);
			display: flex;
			align-items: center;
			justify-content: flex-end;
			text-align: right;
			cursor: pointer;
		}
	}

	&--medium-width {
		#{$root}__container {
			max-width: 768px;
		}
	}
}
