.o-seminarregistrationform {
	$root: &;

	&__webinarFirstNameWrapper,
	&__webinarSecondNameWrapper,
	&__webinarMailWrapper {
		@include xy-cell(13 of 13);
		@include breakpoint(large) {
			@include xy-cell(4 of 13);
		}
	}

	&__regDeleteButton {
		@include xy-cell(13 of 12);
		@include breakpoint(large) {
			@include xy-cell(1 of 13);
		}
	}

	&__entry-wrapper {
		width: 100%;
	}

	&__webinarFields {
		margin-top: map-get($spacing, medium);
	}

	&__seminarregister {
		margin-bottom: 50px;
	}

	&__delete-registration {
		margin: 16px 16px 11px;
		margin-right: 0;
		border-radius: 4px;

		@include breakpoint(large) {
			height: 44px;
		}
	}

	&__delete-icon {
		margin: 12px;
		width: 16px;
		height: 45px;
	}

	&__delete-webinar-icon {
		width: 16px;
		height: 20px;
		@include breakpoint(large) {
			margin-top: 25px;
		}
	}

	&__entry-input {
		float: left;
		width: 75%;
		@include breakpoint(medium) {
			width: 80%;
		}
		@include breakpoint(xxlarge) {
			width: 90%;
		}
	}

	&__delete-registration {
		float: right;
	}

	&__emptyLabel > .a-input__input{
		@include breakpoint(medium) {
			margin-top: 1.875rem;
		}
	}

	&__registration-entries {
		&.is-active {
			#{$root}__delete-button {
				display: inline-block;
			}
		}

		#{$root}__registration-entry.g-form__block {
			display: flex;
			align-items: flex-end;
			margin-top: map-get($spacing, small);
			@include breakpoint(large) {
				margin-top: 0;
			}
		}

		#{$root}__web-registration-entry.g-form__block {
			display: flex;
			align-items: flex-end;
			margin-top: map-get($spacing, small);
			@include breakpoint(large) {
				margin-top: 0;
			}
		}
	}

	&__privacy-policy {
		margin-bottom: map-get($spacing, small);
	}
}
