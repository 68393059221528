.m-teasertransition {
	$root: &;

	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;

	&:before {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.3);
		transition: background-color 300ms ease-out;
		content: '';
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	&:hover {
		&:before {
			background-color: rgba(0, 0, 0, 0.6);
		}

		#{$root}__image {
			transform: scale(1.1);
		}
	}

	&__image {
		width: 100%;
		transition: transform 300ms ease-out;
	}

	&__text {
		@include absolute-center;
		@include use-font(bold);

		width: 100%;
		z-index: 9;
		padding: 0 map-get($spacing, medium);
		color: $white;
		font-size: rem-calc(24px);
		text-transform: uppercase;
		text-align: center;
		hyphens: auto;

		@include breakpoint(large) {
			font-size: rem-calc(26px);
		}
	}

	&:not(:has(img)){
		height: 50px;
	}
}
