.c-viegavoice {
	$root: &;

	&__iframe {
		position: relative;
		padding-top: 56.25%; // almost 16:9 aspect-ratio

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__thumbnails {
		@include xy-grid-container;

		display: grid;
		margin: 0;
		padding: 0;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: auto;

		@include breakpoint(xmedium) {
			grid-template-columns: repeat(6, 1fr);
			grid-template-rows: auto;
		}
	}

	&__thumb-image {
		opacity: 0.4;
	}

	&__thumb-item {
		margin: map-get($spacing, small) map-get($spacing, xsmall) 0
			map-get($spacing, xsmall);

		&.is-active {
			#{$root}__thumb-image {
				opacity: 1;
			}
		}
	}
}
