.m-customerreview {
	$root: &;

	@include xy-grid;

	&__image {
		width: 105px;
		height: 105px;
		border-radius: 50%;
		margin-right: map-get($spacing, small);

		&-placeholder {
			border-radius: 0;
		}
	}

	&__text-container {
		flex: 1;
	}

	&__text {
		@include use-font(light);
		font-style: $cite-font-style;
	}

	&__person {
		@include use-font(bold);
		font-size: rem-calc(14);
	}

	&--text-only {
		#{$root}__text-container {
			display: flex;
			align-items: center;
		}

		#{$root}__text {
			margin-bottom: 0;
		}
	}
}
