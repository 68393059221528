.o-dealerlocator {
	$root: &;
	$custom-gap: 5px;
	//@include xy-grid-container;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-template-areas: 'head' 'search' 'map' 'counter' 'results';
	gap: map-get($spacing, small);

	@include breakpoint(xmedium) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas: 'head head' 'search search' 'map map' 'results results';
	}

	@include breakpoint(large) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto;
		grid-template-areas: 'head head head' 'search search submit' 'map map results';
	}

	&__headline {
		&.a-headline--h1 {
			grid-area: head;
			margin-bottom: 0;
		}
	}

	&__search {
		grid-area: search;

		&-wrapper {
			@include breakpoint(medium) {
				display: flex;
				align-items: center;
			}
		}

		&-container {
			position: relative;
			margin-bottom: map-get($spacing, xsmall);
			flex-grow: 1;

			@include breakpoint(medium) {
				margin-right: $grid-gutter;
				margin-bottom: 0;
			}

			@include breakpoint(xmedium) {
				flex-grow: 0;
				margin-right: map-get($spacing, xsmall);
				width: calc(50% - #{$grid-gutter});
			}

			@include breakpoint(large) {
				flex-grow: 1;
			}
		}

		&-input {
			&[type='search'] {
				@include yellow-bar-hover;

				padding-right: map-get($spacing, large);
				margin-bottom: 0;
			}
		}

		&-location {
			position: absolute;
			top: 2px;
			right: 0;
			height: 43px;
			width: 43px;
			cursor: pointer;
		}

		&-error {
			color: $input-error-color;
			margin-top: map-get($spacing, xsmall);
			margin-bottom: 0;
		}

		&-submit {
			margin-bottom: 2px;
		}
	}

	&__map {
		grid-area: map;
		min-height: 400px;

		@include breakpoint(xmedium) {
			min-height: 500px;
		}
	}

	.m-placeholderimage {
		grid-area: map;
		min-height: 400px;

		@include breakpoint(xmedium) {
			min-height: 500px;
		}
	}

	&__results {
		@include breakpoint(xmedium) {
			grid-area: results;
		}

		.a-select {
			@include breakpoint(xmedium) {
				margin-left: auto;
				width: 50.5%;
			}

			@include breakpoint(large) {
				width: 100%;
			}
		}
	}

	&__result-counter {
		grid-area: counter;
		margin-bottom: 0;

		&:empty {
			display: none;
		}

		@include breakpoint(xmedium) {
			grid-area: results;
			justify-self: start;
		}

		@include breakpoint(large) {
			grid-area: submit;
			justify-self: end;
		}
	}

	&__result-container {
		@extend %scrollbar;
		grid-area: results;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		gap: $custom-gap;
		list-style-type: none;
		margin: 0;

		@include breakpoint(xmedium only) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			max-height: 100vh;
			overflow-y: auto;
		}

		@include breakpoint(large) {
			max-height: 500px;
			overflow-y: auto;
		}
	}

	&__result-item {
		background-color: $light-gray-01;
		padding: map-get($spacing, small);
		cursor: pointer;
		transition: background-color $expo-tween-t1-time $expo-tween-t1;

		&.is-active {
			background-color: $accent-color;
		}
	}

	&__result-entry {
		font-style: normal;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__result-headline {
		@include use-font(bold);
		font-size: 18px;
		margin-bottom: 0;
	}

	&__link {
		@include yellow-bar-hover;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;

		&:empty {
			display: none;
		}
	}

	&__link-text {
		&:empty {
			display: none;

			& + #{$root}__icon {
				display: none;
			}
		}
	}

	&__icon {
		margin-right: map-get($spacing, xsmall);
	}
}
