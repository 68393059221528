.m-stepbystepgallery {
    $root: &;
    position: relative;
    &__headline {
        line-height: 36px;
        margin-bottom: 50px;
        @include breakpoint(xmedium) {
            margin-bottom: 80px;
            line-height: 1.3;
        }
    }

    &__gallery {
        .slick-track {
            display:flex;

            .slick-slide {
                display: flex;
                height: auto;
                justify-content: center;

				> div {
					width: 100%;
				}
            }
        }

        margin-bottom: 30px;
        &__slide {
            $slide: &;
            display: flex !important;
            flex-direction: column;
            height:100%;

            @include breakpoint(large) {
                flex-direction: row;
            }

            &__image {
                img {
                    max-height: 140px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;

                    @include breakpoint(xmedium) {
                        max-height: 313px;
                    }

                    @include breakpoint(large) {
                        max-height: 600px;
                    }
                }
                @include breakpoint(large) {
                    padding-right: 15px;
                    flex: 0 1 50%;
                }
            }

            &__info {
                flex: 0 1 50%;
                padding-top: 30px;
                @include breakpoint(large) {
                    padding-left: 15px;
                    padding-top: 36px;
                }

                &__text {
                    @include breakpoint(large) {
                        padding-bottom: 60px;
                    }
                }

                &__subline {
                    display: block;
                    margin-bottom: 10px;
                }

                &__headline {
                    margin-bottom: 30px;
                    font-size: 24px;
                }
            }

            &--imageRight {
                @include breakpoint(large) {
                    flex-direction: row-reverse;

                    #{$slide}__image {
                        padding-right: 0;
                        padding-left: 15px;
                    }
                    #{$slide}__info {
                        padding-left: 0;
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    &__arrows {
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(large) {
            position: absolute;
            bottom: 20px;
            right: 18%;
        }

        &__left, &__right {
            cursor: pointer;
            padding: 20px;
            transition: opacity 0.3s ease;

            &.slick-disabled {
                opacity: 0;
                cursor: initial;
            }
        }
    }

    &__counter {
        &__current {
            font-size: 18px;
            line-height: 22px;
            margin-left: 30px;
            padding-right: 3px;
        }
        &__summery {
            font-size: 18px;
            line-height: 22px;
            margin-right: 30px;
            padding-left: 3px;
            color: #6F6F6F;
        }
    }

    &--imageRight {
        @include breakpoint(large) {
        #{$root}__gallery {
            &__slide {
                    flex-direction: row-reverse;

                    &__image {
                        padding-right: 0;
                        padding-left: 15px;
                    }
                    &__info {
                        padding-left: 0;
                        padding-right: 15px;
                    }
                }
            }
            #{$root}__arrows {
                justify-content: flex-start;
                left: 18%;
                right: 0;
            }
        }
    }
}
