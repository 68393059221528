.m-vcard {
	$root: &;

	&__contact-title {
		@include use-font(roman);
		hyphens: auto;

		&.a-headline--h5 {
			margin-bottom: 0;
		}
	}

	&__contact-information {
		list-style-type: none;
		margin: 0;
		margin-top: map-get($spacing, xsmall);

		&:not(:last-of-type) {
			margin-right: map-get($spacing, large);
		}
	}

	&__information-item {
		line-height: rem-calc(27px);
	}

	&__link {
		word-break: break-all;
		box-shadow: none;
	}

	&--2column {
		#{$root}__contact-container {
			display: flex;
		}
	}
}
