.m-stickertag {
	@mixin choose-sticker-color($colorName) {
		$bg-colors: (
			sticker-violet: rgb(184, 167, 189),
			sticker-black: $black,
			sticker-gold: rgb(243, 207, 142),
			sticker-cadetblue: rgb(166, 194, 196),
			sticker-viega-yellow: $viega-yellow, // Viega Yellow
			sticker-white: $white, // White
			sticker-dark-grey: $dark-grey, // Dark Grey
			sticker-light-grey: $light-grey, // Light Grey
			sticker-mid-grey: $mid-grey, // Mid Grey
			sticker-stone-dark: $stone-dark, // Stone Dark
			sticker-stone-light: $stone-light, // Stone Light
			sticker-blue: $blue, // Blue
			sticker-mid-blue: $mid-blue, // Mid Blue
			sticker-light-blue: $light-blue, // Light Blue
			sticker-red: $red, // Red
			sticker-green: $green, // Green
			sticker-grey: $grey, // Grey
			sticker-viega-black: rgb(136, 136, 136),
			sticker-hover-yellow: rgb(255, 248, 153)
		);

		@if(map-has-key($bg-colors, $colorName)) {
			background-color: map-get($bg-colors, $colorName);
		} @else {
			background-color: $white;
		}
	}

	display: inline;
	padding: 1px 4px;
	border-radius: 3px;

	&--violet {
		@include choose-sticker-color(sticker-violet);
	}
	&--black {
		@include choose-sticker-color(sticker-black);
	}
	&--gold {
		@include choose-sticker-color(sticker-gold);
	}
	&--cadetblue {
		@include choose-sticker-color(sticker-cadetblue);
	}
	&--viega-yellow {
		@include choose-sticker-color(sticker-viega-yellow);
	}
	&--white {
		@include choose-sticker-color(sticker-white);
	}
	&--dark-grey {
		@include choose-sticker-color(sticker-dark-grey);
	}
	&--light-grey {
		@include choose-sticker-color(sticker-light-grey);
	}
	&--mid-grey {
		@include choose-sticker-color(sticker-mid-grey);
	}
	&--stone-dark {
		@include choose-sticker-color(sticker-stone-dark);
	}
	&--stone-light {
		@include choose-sticker-color(sticker-stone-light);
	}
	&--blue {
		@include choose-sticker-color(sticker-blue);
	}
	&--mid-blue {
		@include choose-sticker-color(sticker-mid-blue);
	}
	&--light-blue {
		@include choose-sticker-color(sticker-light-blue);
	}
	&--red {
		@include choose-sticker-color(sticker-red);
	}
	&--green {
		@include choose-sticker-color(sticker-green);
	}
	&--grey {
		@include choose-sticker-color(sticker-grey);
	}
	&--viega-black {
		@include choose-sticker-color(sticker-viega-black);
	}
	&--hover-yellow {
		@include choose-sticker-color(sticker-hover-yellow);
	}

	&__sticker {
		@include use-font(bold);
		color: $white;
		text-decoration: none;
		font-size: rem-calc(14px);

		&:hover,
		&:focus {
			color: $white;
		}
	}
}
