.o-videochapters {
	&__chapter-list {
		@include xy-grid;
		margin: 0;
		list-style: none;
	}

	&__chapter {
		display: flex;
		align-items: center;
		color: $black;
	}

	&__chapter-text {
		@include use-font(roman);
		color: $light-gray;
		cursor: pointer;
		margin-right: map-get($spacing, xsmall);

		&.is-active {
			color: $black;
		}
	}
}
