.o-mtrform {
	&__error-message {
		display: none;
		color: $alert-color;
		margin-bottom: map-get($spacing, small);
		font-size: 13px;
	}

	&__error-message {
		&.is-active {
			display: block;
		}
	}

	&__result-container {
		.o-gridcontainer {
			padding: 0;
			margin-bottom: map-get($spacing, small);
		}
	}

	&__details-label {
		@include use-font(bold);
	}

	&__details-item {
		padding: 8px;
		transition: background-color 0.6s $expo-tween-t1;

		&:nth-child(even),
		&:nth-child(odd) {
			&:hover {
				background-color: $table-row-hover;
			}
		}

		&:nth-child(even) {
			background-color: $light-gray-01;
		}

		&:nth-child(odd) {
			background-color: $white;
		}
	}

	&__details-label {
		@include use-font(bold);
	}

	&__submit-cta {
		display: flex;
		justify-self: end;
		justify-content: flex-end;
	}
}
