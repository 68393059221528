.o-documentsearch {
	$root: &;

	ul {
		list-style: none;
		margin: 0 0 map-get($spacing, small);
		padding-left: 0;
		padding-right: 0;

		ul {
			margin: map-get($spacing, xsmall) 0 map-get($spacing, small)
				map-get($spacing, small);
		}
	}

	li {
		margin-bottom: map-get($spacing, xsmall);
	}

	&__folders {
		padding: 0;
	}

	&__files-container {
		margin-top: map-get($spacing, xmedium);
	}

	&__file {
		background-color: $body-background;
		padding: map-get($spacing, small);

		p:not(#{$root}__file-description) {
			margin-bottom: 0;
		}
	}

	&__pagination {
		display: flex;
		justify-content: center;
	}

	&__split {
		margin-top: map-get($spacing, medium);
		display: flex;
		justify-content: space-between;
		flex-flow: column;

		@include breakpoint(medium) {
			margin-top: map-get($spacing, large);
			flex-flow: row wrap;
			align-items: flex-end;
		}
	}

	&__results-headline.a-headline {
		@include breakpoint(medium) {
			margin-bottom: 0;
		}
	}

	&__search-text {
		margin-bottom: map-get($spacing, small);
	}

	&__search-sort {
		width: 100%;

		@include breakpoint(medium) {
			flex: 0 0 315px;
		}
	}

	&__separator {
		margin: 0 7px;
	}
}
