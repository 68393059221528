.m-iframe {
	position: relative;
	&__iframe {
		display: block;
		width: 100%;
		height: 100vh;
		border: none;
		overflow: hidden;
	}

	&__video {
		position: relative;
		padding-top: 56.25%; // almost 16:9 aspect-ratio

		iframe {
			//margin-top: -56.25%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.m-placeholderimage {
			margin-top: -56.25%;
		}
	}
}
