.a-socialicon {
	$root: &;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
	padding: 0 20px;

	@include breakpoint(xmedium) {
		flex: 0 1 15%;
		margin: 0 20px 40px;
	}

	& .a-svgicon {
		width: 50px;
		height: 50px;

		@include breakpoint(xmedium) {
			width: 60px;
			height: 60px;
		}
	}

	&__label {
		padding-top: 12px;
		font-size: 16px;
		line-height: 18px;
		max-width: 72px;
		text-align: center;
	}

	&:hover {
		& .a-svgicon {
			transform: none;

			@include breakpoint(xmedium) {
				transform: scale3d(1.2, 1.2, 1.2)
			}
		}

	}
}
