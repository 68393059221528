.a-image {
	width: 100%;
	transition: filter 800ms, -webkit-filter 800ms, transform 300ms ease-out;

	&.lazyload:not([src]) {
		// hide the alt text, which is shown blurry before the image is lazyloaded
		visibility: hidden;
	}

	&.blur-up {
		filter: blur(5px);
		// fix: use hardware acceleration on iOS to prevent image rendering glitches
		-webkit-transform: translateZ(0);
		-webkit-perspective: 1000;
		-webkit-backface-visibility: hidden;
	}

	&.blur-up.lazyloaded {
		filter: blur(0);
	}

	&.is-cropped {
		@include object-fit(contain);
		padding: 10%;
	}

	&.has-zoom {
		user-select: none;
		-webkit-user-select: none;
		-webkit-touch-callout: none;

		&:hover {
			cursor: crosshair;
		}
	}
}

// drift-zoom plugin styles
.drift-zoom-pane {
	// add non-transparent background to fully cover a transformed image in the background which could shine through by js property 'showWhitespaceAtEdges'
	background: #fff;
	/* This is required because of a bug that causes border-radius to not
	work with child elements in certain cases. */
	transform: translate3d(0, 0, 0);
}

.drift-zoom-pane.drift-opening {
	animation: drift-fadeZoomIn 300ms ease-out;
}

.drift-zoom-pane.drift-closing {
	animation: drift-fadeZoomOut 200ms ease-in;
}

.drift-zoom-pane.drift-inline {
	position: absolute;
	width: 250px;
	height: 250px;
	border-radius: 125px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.drift-zoom-pane img {
	@include object-fit(contain);
}

.drift-zoom-pane-loader {
	display: none;
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 66px;
	height: 20px;
	animation: drift-loader-rotate 1800ms infinite linear;
}

.drift-zoom-pane-loader:before,
.drift-zoom-pane-loader:after {
	content: '';
	display: block;
	width: 20px;
	height: 20px;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	border-radius: 20px;
	background: rgba(255, 255, 255, 0.9);
}

.drift-zoom-pane-loader:before {
	left: 0;
	animation: drift-loader-before 1800ms infinite linear;
}

.drift-zoom-pane-loader:after {
	right: 0;
	animation: drift-loader-after 1800ms infinite linear;
	animation-delay: -900ms;
}

@keyframes drift-fadeZoomIn {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes drift-fadeZoomOut {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0);
		opacity: 0;
	}
}

@keyframes drift-loader-rotate {
	0% {
		transform: translate(-50%, -50%) rotate(0);
	}
	50% {
		transform: translate(-50%, -50%) rotate(-180deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(-360deg);
	}
}

@keyframes drift-loader-before {
	0% {
		transform: scale(1);
	}
	10% {
		transform: scale(1.2) translateX(6px);
	}
	25% {
		transform: scale(1.3) translateX(8px);
	}
	40% {
		transform: scale(1.2) translateX(6px);
	}
	50% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.8) translateX(6px);
	}
	75% {
		transform: scale(0.7) translateX(8px);
	}
	90% {
		transform: scale(0.8) translateX(6px);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes drift-loader-after {
	0% {
		transform: scale(1);
	}
	10% {
		transform: scale(1.2) translateX(-6px);
	}
	25% {
		transform: scale(1.3) translateX(-8px);
	}
	40% {
		transform: scale(1.2) translateX(-6px);
	}
	50% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.8) translateX(-6px);
	}
	75% {
		transform: scale(0.7) translateX(-8px);
	}
	90% {
		transform: scale(0.8) translateX(-6px);
	}
	100% {
		transform: scale(1);
	}
}
