$viega-yellow: rgb(255,237,0); // Viega Yellow
$black: rgb(0,0,0); // Black
// $white: rgb(255,255,255); // White
$dark-grey: rgb(95,105,110); // Dark Grey
$light-grey: rgb(200,205,211); // Light Grey
$mid-grey: rgb(148,159,167); // Mid Grey
$stone-dark: rgb(163,154,147); // Stone Dark
$stone-light: rgb(191,187,178); // Stone Light
$blue: rgb(85,167,206); // Blue
$mid-blue: rgb(142,187,222); // Mid Blue
$light-blue: rgb(179,209,234); // Light Blue
$red: rgb(204,61,91); // Red
$green: rgb(72,155,72); // Green
$grey: rgb(131,134,139); // Grey
$very-light-grey: rgb(239,242,242); // Very Light Grey
