.m-overlayconsent {
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
	opacity: 0;
	width: 300px;
	background: $white;
	padding: map-get($spacing, medium) 0;
	border-top: 1px solid $light-gray;
	z-index: z(main-navigation);
	transition: transform 300ms ease-out, opacity 300ms ease-out;

	@include breakpoint(medium) {
		padding: map-get($spacing, small) 0;
		width: 400px;

	}

	&.is-active {
		opacity: 1;
		//transform: translateY(0);
	}

	&__content {
		@include xy-grid-container;
		@include xy-grid;

		align-items: center;
		flex-flow: column wrap;

		@include breakpoint(medium) {
			flex-flow: column nowrap;
		}
	}

	&__headline {
		text-align: center;
		width: 100%;
	}

	&__richtext {
		flex: 1 1 auto;
		text-align: center;
		width: 100%;

		@include breakpoint(medium) {
			margin-bottom: map-get($spacing, small);
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		flex: 0 0 auto;
		margin: map-get($spacing, xsmall) 0 0 0;
		align-items: center;

		@include breakpoint(medium) {
			margin: 0;
			width: auto;
		}

		button {
			align-self: center;
		}
	}
}
