.o-footerlite {
	background: $secondary-color;
	color: $light-gray;
	max-width: $global-max-width;
	min-height: $footerlite-min-height;
	margin: auto;
	position: relative;

	&__navigation {
		a {
			@include use-font(roman);
			font-size: $global-font-size;
			color: $light-gray;
			box-shadow: none;
			transition: color 0.3s ease-out;

			&:hover:not(:disabled) {
				// overrule global link styling/animation
				color: $white;
				box-shadow: none !important;
			}
		}
	}

	&__content {
		@include xy-grid-container;
		padding-top: map-get($spacing, xmedium);
		padding-bottom: map-get($spacing, xmedium);
	}

	&__button {
		position: absolute;
		top: map-get($spacing, xmedium);
		right: map-get($spacing, medium);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		background: $white;
		border-radius: 4px;
		padding: 7px;
		transition: transform 300ms ease-out;

		.a-svgicon {
			use {
				color: $secondary-color;
			}
		}

		@include breakpoint(xmedium down) {
			right: map-get($spacing, small);
		}

		&:hover {
			transform: scale(1.2);
			cursor: pointer;
		}
	}
}
