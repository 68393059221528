.m-video {
	&__video-wrapper {
		overflow: hidden;
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%;
		margin-bottom: map-get($spacing, small);
	}

	&__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__player {
		margin-bottom: map-get($spacing, small);
	}

	&__iframe {
		position: relative;
		padding-top: 56.25%; // almost 16:9 aspect-ratio

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&__placeholder-image {
		position: relative;
	}
}
