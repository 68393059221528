.m-teaserblog {
	$root: &;

	@include xy-grid(vertical);
	background-color: $light-gray-01;
	height: 100%;

	&__standalone {
		@include xy-grid(horizontal);
	}

	&__image-container {
		@include xy-cell(12 of 12, $gutters: 0);
		display: block;
		overflow: hidden;
		background: $white;
		max-height: calc(90vh - #{$header-height});

		&--standalone{
			@include breakpoint(medium) {
				@include xy-cell(6 of 12, $gutters: 0);
				max-height: calc(60vh - #{$header-height});
			}
		}
	}

	&__image {
		@include object-fit(cover);
		width: 100%;
		height: 100%;
	}

	&__content {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	&__sticker-bookmark-container{
		display: flex;
		justify-content: space-between;
		padding: map-get($spacing, small);
	}

	&__sticker-container {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
	}

	&__sticker-container > * {
		margin-right: map-get($spacing, xsmall);
	}

	&__sticker {
		margin-right: 5px;
		margin-bottom: 5px;
	}

	&__headline {
		@include use-font(roman);
		margin-bottom: map-get($spacing, xsmall);
		hyphens: auto;
	}

	&__text-container {
		padding: 15px map-get($spacing, small) map-get($spacing, small);
		display: block;
		margin-top: auto;
	}
}
