.o-smallgrid {
	&__headline {
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 50px;
		@include breakpoint(xmedium) {
			margin-bottom: 80px;
			font-size: 30px;
			line-height: 1.3;
		}
	}
	@include xy-grid-container;

	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto;
	grid-gap: $grid-gutter;
	width: 100%;
	margin-bottom: map-get($spacing, large);

	@include breakpoint(xmedium) {
		padding: 0 15%;
	}

	@include breakpoint(xlarge) {
		margin-bottom: map-get($spacing, xxlarge);
	}

	&--nomargin {
		margin-bottom: 0;

		@include breakpoint(xlarge) {
			margin-bottom: 0;
		}
	}

	// no spacing on nested grids
	.o-smallgrid {

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	> .a-button {
		margin-right: auto;

		&--right {
			margin-right: 0;
			margin-left: auto;
		}
	}

	.m-interactiveimages {
		&__grid {
			@include xy-grid-container(100%, 0);
	
			display: grid;
			grid-template-columns: 100%;
			grid-auto-rows: minmax(0, 200px);
			grid-gap: $grid-gutter;
			width: 100%;
	
			@include breakpoint(medium) {
				grid-template-columns: repeat(2, minmax(0, 450px));
				grid-template-rows: auto;
				grid-auto-rows: auto;
				grid-gap: $grid-gutter;
			}
		
			@include breakpoint(large) {
				grid-template-columns: repeat(3, minmax(0, 450px));
				grid-template-rows: auto;
				grid-gap: $grid-gutter;
			}
		}
	}

	.m-infovcard {
		&__contacts {
			&__wrapper {
				flex-direction: column;
	
				@include breakpoint(xmedium) {
					flex-direction: row;
					width: 100%;
	
					&:nth-child(odd) {
						padding-right: 0;
					}
					&:nth-child(even) {
						padding-left: 0;
					}
				}
	
				@include breakpoint(large) {
					width: 50%;
					margin-bottom: 80px;
					flex-direction: column;
					align-items: center;
	
					&:nth-child(odd) {
						padding-right: 20px;
					}
					&:nth-child(even) {
						padding-left: 20px;
					}
				}
			}
	
			&__informations {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: 100%;
				padding: 15px 0;
	
				@include breakpoint(xmedium) {
					padding: 22px 55px 30px 18px;
					flex-direction: row;
					align-items: center;
					justify-content: center;
				}

				@include breakpoint(large) {
					flex-direction: column;
				}
	
				.a-headline {
					margin-bottom: 8px;
					@include use-font(bold);
	
					@include breakpoint(xmedium) {
						width: 100%;
						text-align: center;
					}
				}
	
				&__title {
					margin-bottom: 16px;
					@include breakpoint(large) {
						width: 100%;
						text-align: center;
					}
				}
	
				&__text {
					margin-bottom: 16px;
					text-align: center;
				}
	
				&__links {
					display: flex;
					flex-direction: column;
					font-size: 16px;
					line-height: 26px;
					margin-bottom: 16px;
	
					span {
						@include use-font(bold);
					}
				}
	
				&__socials {
					width: 70%;
					display: flex;
					justify-content: center;
	
					@include breakpoint(large) {
						flex: 1;
						padding-top: 8px;
					}
				}
	
				&__social {
					margin: 0 8px;
				}
	
				&--backgroundColor {
					background: $primary-color;
				}
			}
		}
	}

	.m-stepbystepgallery {
		&__arrows {
			@include breakpoint(large) {
				position: absolute;
				bottom: 0px;
				right: 18%;
			}
		}
	}

	.o-tab {
		&__grid {
			grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
		}
	}
}
