.a-link {
	$root: &;

	@include use-font(bold);
	@include yellow-bar-hover(true);

	-ms-word-break: break-all;
	word-break: break-word;

	&__icon {
		margin-left: map-get($spacing, 'xsmall');
		flex-shrink: 0;
	}

	&--with-icon {
		display: inline-flex;
		align-items: center;
	}

	&--icon-left {
		flex-flow: row-reverse;

		#{$root}__icon {
			margin-right: map-get($spacing, 'xsmall');
			margin-left: 0;
		}
	}

	&--link-list{
		list-style: none;
	}
}
