// 	Table of Contents:
//
// 	1. Lorem
//
//	2. Ipsum
//

/**
 * x. Base
 */
$global-max-width: 1920px;
$mobile-landscape: 'screen and (max-height: 400px) and (orientation: landscape)';

/**
 * x. Colors
 */

// base color-set
$white: #fff;
$primary-color: #ffed00;
$secondary-color: #111;
$accent-color: #e1e7e7;

$success-color: #489b48;
$success-bg-color: #eaf4e8;
$alert-color: #cc3d5b;
$alert-bg-color: #f4e9e8;

// color variants
$dark-gray: lighten($secondary-color, 10%);
$gray: lighten($secondary-color, 25%);
$light-gray: lighten($secondary-color, 50%);
$light-gray-01: lighten($accent-color, 5%);
$light-gray-02: lighten($secondary-color, 76%);
$light-yellow: lighten($primary-color, 40.4%);

// specific recurring color usages
$color-shader: rgba($secondary-color, 0.7);
$color-search-overlay: rgba($secondary-color, 0.8);
$color-search-result: rgba($white, 0.15);
$color-global-font: $secondary-color;
$color-borders: $accent-color;

$colorMap: (
	primary: $primary-color,
	secondary: $secondary-color,
	accent: $accent-color,
	muted: #fff,
	success: $success-color,
	warning: #fff,
	danger: $alert-color,
);

/**
 * x. Animations
 */
$expo-tween-t1: cubic-bezier(0.191, 1, 0.22, 1);
$expo-tween-t1-time: 1.1s;
$expo-tween-t2: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$transition-fade: transform $expo-tween-t1-time $expo-tween-t1;

/**
* x. Breakpoints
*/
$breakpoints: (
	small: 0,
	medium: 640px,
	xmedium: 768px,
	large: 1024px,
	xlarge: 1200px,
	xxlarge: 1440px,
	);

/**
 * x. Spacing
 */
$spacing: (
	xsmall: 10px,
	small: 20px,
	medium: 30px,
	xmedium: 40px,
	large: 50px,
	xlarge: 60px,
	xxlarge: 80px,
);

$grid-gutter: map-get($spacing, medium);

/**
 * x. Header
 */
$nav-height: 75px;
$nav-height--mobile: 60px;
$searchbar-height: 80px;
$header-height: $nav-height + $searchbar-height;
$header-height--mobile: $nav-height--mobile + $searchbar-height;
$header-scroll-threshold: 200;
$headerlite-height: $nav-height;
$headerlite-height--mobile: $nav-height--mobile;


/**
 * x. Footer
 */
 $footerlite-min-height: 120px;


/**
 * x. ContentNav
 */
$contentnav-height: 46px;

/**
 * x. States
 */
$is-active: '.is-active';
$is-active-navitem: '.is-active-navitem';
$is-inactive: '.is-inactive';
$is-animating: '.is-animating';
$is-paused: '.is-paused';
$is-empty: '.is-empty';
$is-sticky: '.is-sticky';
$is-hidden: '.is-hidden';
$is-scrolldown: '.is-scrolldown';
$is-scrollup: '.is-scrollup';
$is-top: '.is-top';
$is-bottom: '.is-bottom';
$is-active-shader: '.is-active-shader';
$is-visible: '.is-visible';
$is-loading: '.is-loading';
$is-successful: '.is-successful';
$is-disabled: '.is-disabled';
$has-error: '.has-error';
$is-blurry: '.is-blurry';
$is-sortable: '.is-sortable';
$is-sorttarget: '.is-sorttarget';
$is-reverse: '.is-reverse';
$is-number: '.is-number';
$is-date: '.is-date';
$is-even: '.is-even';
$is-odd: '.is-odd';
$has-filter: '.has-filter';
$has-transition: '.has-transition';
$has-placeholder: '.has-placeholder';
$header-hidden: '.has-hidden-header';
$contentnav-sticky: '.has-sticky-nav';
$cssStateMap: (
	HEADER_HEIGHT: $header-height / 1px,
	HEADER_HEIGHT_MOBILE: $header-height--mobile / 1px,
	HEADER_SCROLL_THRESHOLD: $header-scroll-threshold / 1px,
	HEADER_HIDDEN: str-slice(#{$header-hidden}, 2),
	CONTENTNAV_HEIGHT: $contentnav-height / 1px,
	CONTENTNAV_STICKY: str-slice(#{$contentnav-sticky}, 2),
	IS_ACTIVE: str-slice(#{$is-active}, 2),
	IS_ACTIVE_NAVITEM: str-slice(#{$is-active-navitem}, 2),
	IS_INACTIVE: str-slice(#{$is-inactive}, 2),
	IS_ANIMATING: str-slice(#{$is-animating}, 2),
	IS_PAUSED: str-slice(#{$is-paused}, 2),
	IS_EMPTY: str-slice(#{$is-empty}, 2),
	IS_HIDDEN: str-slice(#{$is-hidden}, 2),
	IS_STICKY: str-slice(#{$is-sticky}, 2),
	IS_SCROLLDOWN: str-slice(#{$is-scrolldown}, 2),
	IS_SCROLLUP: str-slice(#{$is-scrollup}, 2),
	IS_TOP: str-slice(#{$is-top}, 2),
	IS_BOTTOM: str-slice(#{$is-bottom}, 2),
	IS_ACTIVE_SHADER: str-slice(#{$is-active-shader}, 2),
	IS_VISIBLE: str-slice(#{$is-visible}, 2),
	IS_LOADING: str-slice(#{$is-loading}, 2),
	IS_SUCCESSFUL: str-slice(#{$is-successful}, 2),
	IS_DISABLED: str-slice(#{$is-disabled}, 2),
	IS_BLURRY: str-slice(#{$is-blurry}, 2),
	IS_SORTABLE: str-slice(#{$is-sortable}, 2),
	IS_SORTTARGET: str-slice(#{$is-sorttarget}, 2),
	IS_REVERSE: str-slice(#{$is-reverse}, 2),
	IS_NUMBER: str-slice(#{$is-number}, 2),
	IS_DATE: str-slice(#{$is-date}, 2),
	IS_EVEN: str-slice(#{$is-even}, 2),
	IS_ODD: str-slice(#{$is-odd}, 2),
	HAS_FILTER: str-slice(#{$has-filter}, 2),
	HAS_ERROR: str-slice(#{$has-error}, 2),
	HAS_TRANSITION: str-slice(#{$has-transition}, 2),
	HAS_PLACEHOLDER: str-slice(#{$has-placeholder}, 2),
);

/**
 * x. z-index Layer
 */
$z-layers: (
	'fixed-table-head',
	'content-navigation',
	'is-drilldown',
	'hint',
	'content-shader',
	'search-input',
	'search-navigation',
	'navigation-flyout',
	'navigation-flyout-animate',
	'main-navigation',
	'hotspot',
	'stickybutton',
	'notification',
	'logo',
	'overlays'
);

/**
 * x. Countryflyout
 */
$countryflyout-char-size: 24px;
$countryflyout-maxheight: 400px;

/**
 * x. Gallery
 */
$hotspot-button-size: 32px;

/**
 * x. Loading
 */
$loadingAnimationGradient: linear-gradient(
	45deg,
	rgba(225, 231, 231, 1) 0%,
	rgba(211, 211, 211, 1) 50%,
	rgba(225, 231, 231, 1) 100%
);
$loadingAnimationGradientDark: linear-gradient(
	45deg,
	rgb(78, 82, 82) 0%,
	rgb(78, 78, 78) 50%,
	rgb(78, 82, 82) 100%
);
$animateLoadingTeaser: teaserLoadingAnimation 2s ease-in-out infinite;
$animateLoadingTeaserDark: teaserLoadingAnimationDark 2s ease-in-out infinite;
/**
 * x. Carouselstage
 */
$slider-nav-height: 40px;
