.m-mainnav__watchlist {
	$root: &;

	&-icon {
		color: inherit;
	}

	&-icon-container {
		position: relative;
		height: 24px;
	}

	&-counter {
		@include use-font(bold);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 6px;
		right: -7px;
		height: 20px;
		min-width: 20px;
		margin: 0;
		padding: 0 5px;
		z-index: z(overlays);
		border: 2px solid $secondary-color;
		border-radius: 15px;
		font-size: rem-calc(14px);
		color: $secondary-color;
		line-height: 1;
		background-color: $primary-color;
	}

	&-headline {
		@include use-font(roman);
	}

	&-intro {
		@include xy-cell(3 of 12, $gutters: rem-calc(60px));
		margin-top: map-get($spacing, xmedium);

		@include breakpoint(xxlarge) {
			@include xy-cell(3 of 12);
		}
	}

	&-container.m-mainnav__submenu--1 {
		padding-top: 0;
		padding-bottom: 0;

		&.is-empty {
			#{$root}-intro,
			#{$root}-items {
				display: none;
			}

			#{$root}-empty {
				display: block;
				padding: map-get($spacing, xmedium) 0;
			}
		}
	}

	&-items {
		@include xy-cell(9 of 12);
		@include xy-grid;
		@include xy-grid-layout(
			1,
			'.m-watchlistflyoutteaser',
			$gutters: map-get($spacing, xsmall)
		);
		padding-top: map-get($spacing, xmedium);
		padding-bottom: map-get($spacing, xmedium);

		@include breakpoint(xmedium) {
			@include xy-grid-layout(
				2,
				'.m-watchlistflyoutteaser',
				$gutters: map-get($spacing, xsmall)
			);
		}

		@include breakpoint(xlarge) {
			@include xy-grid-layout(
				3,
				'.m-watchlistflyoutteaser',
				$gutters: map-get($spacing, xsmall)
			);
		}
	}
	.m-watchlistflyoutteaser {
		background-color: $light-gray-01;
		padding: map-get($spacing, small);

		@include breakpoint(xmedium) {
			margin-bottom: map-get($spacing, xsmall);
		}
	}

	&-link {
		overflow: auto; //IE Fix
	}

	&-empty {
		@include xy-cell(12 of 12);
		display: none;
		text-transform: uppercase;
		text-align: center;
	}
}
