.o-tab {
	$root: &;
	hyphens: auto;

	&__headline {
		margin-bottom: 50px;

		@include breakpoint(xmedium) {
			margin-bottom: 80px;
		}
	}

	&__grid {
        $grid: &;
		@include xy-grid-container(100%, 0);

		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
		column-gap: 1px;
		width: 100%;

		@include breakpoint(xmedium) {
			column-gap: 2px;
		}

		&__container {
			$tab: &;
			background: #e7e7e7;
			position: relative;
			$container: &;
			cursor: pointer;
			transition: all 0.6s $expo-tween-t1;
			display: flex;
			align-items: end;
			transform-origin: bottom;
			overflow: hidden;
            will-change: background-color, transform;
			z-index: 1;

			input {
				opacity: 0;
				height: 0;
				position: absolute;
				left: -2000px;
			}

			&__image {
				object-fit: cover;
				pointer-events: none;
				transform-origin: center;
				z-index: 1;
				height: 100%;
				max-height: 105px;
				will-change: transform;

				@include breakpoint(medium) {
					max-height: 490px;
				}
			}

			&__label {
				padding: 10px 10px;
				color: $secondary-color;
				font-size: 16px;
				line-height: 18px;
				display: block;
				pointer-events: none;
				transition: all 0.6s $expo-tween-t1;
				overflow-wrap: anywhere;
				@include use-font(light);

                @include breakpoint(xmedium) {
                    padding: 16px 20px;
                    font-size: 24px;
                    line-height: 29px;
                }

				&--withImage {
					position: absolute;
					bottom: 4px;
					color: $white;
					z-index: 2;
				}
			}

			&.active, &:hover {
				background: $accent-color;
				transform: scaleY(1.05);

				@include breakpoint(xmedium) {
					transform: scaleY(1.10);
				}

				#{$tab} {
					&__image {
						transform: scale(1.10);
						
						@include breakpoint(xmedium) {
							transform: scale(1.05);
						}
					}

					&__label {
						@include use-font(roman);
					}
				}
			}
		}
        &--withImage {
            column-gap: 7px;

            #{$grid} {
                &__container {
					transform-origin: top left;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        background: rgba(0, 0, 0, .4);
                        transform-origin: top left;
                        transition: transform 0.6s $expo-tween-t1;
                        z-index: 1;
                    }

                    &.active, &:hover {
						transform: scaleY(1.10);

						@include breakpoint(xmedium) {
							transform: scaleY(1.05);
						}

                        &:after {
                            transform: scaleY(1.10);

							@include breakpoint(xmedium) {
								transform: scaleY(1.05);
							}
                        }
                    }
                }
            }

            @include breakpoint(xmedium) {
                column-gap: 20px;
            }
        }

	}

	&__content {
		background: $accent-color;
		padding: 40px 15px;

        @include breakpoint(xmedium) {
            padding: 40px 20px;
        }
	}
}
