.m-teasernews {
	&__headline,
	&__link {
		@include use-font(bold);
	}

	&__headline {
		hyphens: auto;
	}

	&__link-text {
		@include yellow-bar-hover;
	}
}
