.a-quantity {
	display: flex;
	align-items: center;
	white-space: nowrap;

	&__minus {
		margin-right: map-get($spacing, xsmall);
		cursor: pointer;
	}

	&__input {
		display: inline-block;

		input {
			@include use-font(bold);
			background-color: $white;
			border: 1px solid $light-gray-01;
			text-align: center;
			width: rem-calc(60);
			-moz-appearance: textfield; // Firefox

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&:focus,
			&:active {
				background-color: $white;
				border: 1px solid $light-gray-01;
			}

			&:only-child {
				margin-bottom: 0;
			}
		}
	}

	&__plus {
		margin-left: map-get($spacing, xsmall);
		cursor: pointer;
	}
}
