.o-header {
	$root: &;

	height: $header-height--mobile;
	background-color: $secondary-color;
	max-width: $global-max-width;
	margin: auto;

	@include breakpoint(xmedium) {
		height: $header-height;
	}

	&.is-sticky {
		.m-mainnav__logo-container {
			transform: translateY(-100%);
		}

		#{$root}__container {
			position: fixed;
			transform: translateY(-$header-height--mobile);

			@include breakpoint(xmedium) {
				transform: translateY(-$header-height);
			}
		}

		&.show-search {
			#{$root}__container {
				transform: translateY(-$nav-height--mobile);

				@include breakpoint(xmedium) {
					transform: translateY(-$nav-height);
				}
			}
		}

		&.is-active {
			.m-mainnav__logo-container,
			#{$root}__container {
				transform: translateY(0);

				@include breakpoint(xmedium) {
					transform: translateY(0);
				}
			}
		}
	}

	&__container {
		transform: translateY(0);
		z-index: z(main-navigation);
		position: relative;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		max-width: 1920px;
		width: 100%;
		will-change: transform;

		&.has-transition {
			transition: transform $expo-tween-t1-time $expo-tween-t1;
		}
	}
}
