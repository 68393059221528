.c-explodedviewlegend {
	&__image {
		@include object-fit(contain);
		max-height: calc(90vh - #{$header-height});
		border: 1px solid $accent-color;

		&.is-cropped {
			padding: map-get($spacing, xsmall);
		}
	}

	&__list {
		@include use-font(bold);
		list-style-position: outside;
	}

	&__list-item {
		margin-left: 20px;
	}
}
