@function z($layer) {
	@if (null == index($z-layers, $layer)) {
		@error "No layer found for `#{$layer}` in $z-layers map. Property omitted.";
	}

	@return index($z-layers, $layer) * 100;
}

/// Converts a map to an URL-encoded string, like this: `key1=value1&key2=value2`.
@function map-serialize($map) {
	$str: '';
	@each $key, $value in $map {
		$str: $str + $key + '=' + $value + '&';
	}
	$str: str-slice($str, 1, -2);

	@return $str;
}

@function screenSize($key) {
	@if map-has-key($breakpoints, $key) {
		@return map-get($breakpoints, $key);
	}
}
