.m-searchtextfilter {
	&__input-container {
		display: flex;
		align-items: flex-end;
	}

	&__icon-container {
		cursor: pointer;
		height: 45px;
		display: flex;
		align-items: center;
	}

	&__input {
		width: 100%;
		margin-right: map-get($spacing, xsmall);

		@include breakpoint(medium) {
			width: 315px;
		}

		.a-input__input {
			margin-bottom: 0;
		}
	}

	&__description {
		margin-bottom: 0;
		font-size: rem-calc(12px);
		line-height: 14px;
		margin-top: 5px;

		@include breakpoint(medium) {
			font-size: rem-calc(14px);
			margin-left: map-get($spacing, medium);
			margin-top: 0;
		}
	}

	&__container {
		display: flex;
		flex-flow: column;

		@include breakpoint(medium) {
			align-items: center;
			flex-flow: row;
		}
	}
}
