.m-searchoptionsfilter {
	$root: &;

	padding-top: map-get($spacing, small);

	&__container {
		@include xy-grid;
		@include xy-grid-layout(1, '.m-searchoptionsfilter__option');

		@include breakpoint(medium) {
			@include xy-grid-layout(3, '.m-searchoptionsfilter__option');
		}

		@include breakpoint(xmedium) {
			@include xy-grid-layout(4, '.m-searchoptionsfilter__option');
		}

		@include breakpoint(large) {
			@include xy-grid-layout(5, '.m-searchoptionsfilter__option');
		}
	}

	&__option {
		margin-bottom: map-get($spacing, small);

		@include breakpoint(medium) {
			&:nth-last-child(-n + 3) {
				margin-bottom: 0;
			}
		}
	}

	&__legals-container {
		display: flex;
		justify-content: flex-end;
	}

	&__legal {
		@include xy-gutters;
		font-size: rem-calc(12px);
		display: inline-block;
	}
}
