.m-mainnav {
	$root: &;

	@include use-font(roman);
	width: 100%;
	background-color: $secondary-color;

	ul {
		list-style: none;
	}

	.js-drilldown-back {
		a {
			color: $white;
			padding-left: map-get($spacing, small);
		}
	}

	.is-drilldown {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: z('is-drilldown');
		width: 100%;
		transform: translateY(100%);

		a#{$root}__submenu-link,
		#{$root}__main-link,
		#{$root}__back-button-link {
			border-bottom: 1px solid $secondary-color;
			padding: 25px map-get($spacing, small);
		}

		a#{$root}__submenu-link {
			background-color: $light-gray-01;
			color: $secondary-color;
		}

		#{$root}__main-link {
			background-color: $white;
			color: $secondary-color;
		}

		#{$root}__back-button-link {
			display: flex;
			align-items: center;
			background-color: $white;
			color: $secondary-color;

			.m-mainnav__arrow-left {
				display: block;
				margin-right: map-get($spacing, xsmall);
			}
		}

		#{$root}__teaser-title {
			@include use-font(roman);
			display: inline;
			margin: 0;
			font-size: $global-font-size;
			line-height: 1;
			color: $body-font-color;
		}

		.is-drilldown-submenu-parent,
		.is-drilldown-submenu-parent:hover {
			& > #{$root}__main-link,
			& > #{$root}__submenu-link {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;

				.m-mainnav__arrow-right {
					display: block;
				}
			}
		}
	}

	h3 {
		@include breakpoint(xmedium) {
			font-size: rem-calc(20px);
			text-transform: uppercase;
		}
	}

	&__navigation-container {
		@include xy-grid-container();
		height: $nav-height--mobile;
		position: relative;
		display: flex;

		@include breakpoint(xmedium) {
			height: $nav-height;
			position: static;
		}
	}

	/**
	 * Logo
	 */
	&__logo-container {
		transition: $transition-fade;
		z-index: z(logo);
		width: auto;

		@include breakpoint(xmedium) {
			width: auto;
		}
	}

	&__logo {
		width: 70px;
		height: 60px;
		margin-top: map-get($spacing, xsmall);

		@include breakpoint(xmedium) {
			width: 90px;
			height: 90px;
		}
	}

	&__burger {
		$burgerRoot: &;
		padding: map-get($spacing, xsmall);
		margin-right: map-get($spacing, xsmall) * -1;
		cursor: pointer;

		&-menu-icon,
		&-close-icon {
			color: $white;
		}

		&-menu-icon {
			display: block;
		}

		&-close-icon {
			display: none;
			padding: 6px;
		}

		&.is-active-navitem {
			#{$burgerRoot}-menu-icon {
				display: none;
			}

			#{$burgerRoot}-close-icon {
				display: block;
			}
		}

		@include breakpoint(xmedium) {
			display: none;
		}
	}

	&__main-items,
	&__language-items {
		@include menu-base;
		@include menu-direction(vertical);
		display: none;
		height: auto;

		&.is-active-navitem {
			display: flex;
		}

		@include breakpoint(xmedium) {
			flex-direction: row;
			align-items: center;
			position: static;
			z-index: z(main-navigation);
			display: flex;
			margin: 0 $global-margin;
		}
	}

	&__language-items {

		@include breakpoint(xmedium) {
			display: none;
		}
	}

	&__main-item {
		@include breakpoint(xmedium) {
			&.is-active-navitem,
			&:hover {
				#{$root}__main-link {
					color: $primary-color;
				}
			}
		}
	}
	&__main-link,
	&__menu-link {
		&:after {
			@include css-triangle(10px, $white, up);
			position: absolute;
			bottom: -1px;
			left: 50%;
			transform: translateX(-50%) scale(0);
			transition: transform $expo-tween-t1-time $expo-tween-t1;
			transform-origin: 50% 100%;
		}

		@include breakpoint(small) {
			height: 70px;
		}
	}

	&__main-items &__main-link,
	&__language-items &__main-link {
		color: $white;
		position: relative;
		display: flex;
		align-items: center;

		@include breakpoint(xmedium) {
			@include use-font(bold);
			height: 100%;
			text-transform: uppercase;
		}

		.m-mainnav__arrow-left,
		.m-mainnav__arrow-right {
			display: none;
		}
	}

	&__submenu-link {
		@include use-font(roman);
		overflow: hidden;
	}

	a#{$root}__submenu-link,
	&__submenu {
		color: $white;
	}

	&__submenu-item {
		&:hover,
		&.is-active-navitem {
			#{$root}__teaser-title,
			#{$root}__teaser-text {
				color: $secondary-color;
			}
		}

		&.is-active-navitem,
		&.is-animating {
			> #{$root}__submenu {
				position: absolute;

				@include breakpoint(xmedium) {
					display: block;
				}
			}
		}
	}

	&__submenu {
		padding: 0;
		margin: 0;
		list-style: none;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		max-height: 100vh;

		li {
			@include disable-mouse-outline;
		}

		@include menu-direction(vertical);

		@include breakpoint(xmedium) {
			width: 100%;
			position: relative;
			display: none;
			left: 0;
			border: none;
			margin: 0;
			color: $secondary-color;
			background-color: $white;

			&--1 {
				z-index: z(navigation-flyout); //needed of IE
				padding-top: map-get($spacing, xmedium); //needed of IE
				padding-bottom: map-get($spacing, xmedium); //needed of IE

				@include breakpoint(xxlarge) {
					padding: map-get($spacing, xmedium)
						calc(50% - #{$global-width / 2 - rem-calc(30)}); // +15 equals grid gutter divided by 2
				}

				top: $nav-height;
				overflow: visible;

				> #{$root}__submenu-item {
					margin: map-get($spacing, xsmall) 0;

					&.is-active-navitem {
						#{$root}__submenu-link.has-submenu {
							&:after {
								transform: translateX(-50%) scale(1);
							}
						}
					}

					> a {
						padding-left: 0;
						padding-right: 0;
					}
				}

				#{$root}__submenu-link.has-submenu {
					&:after {
						@include css-triangle(10px, $secondary-color, up);
						position: absolute;
						bottom: -1px;
						left: 50%;
						transform: translateX(-50%) scale(0);
						transition: transform $expo-tween-t1-time $expo-tween-t1;
						transform-origin: 50% 100%;
					}
				}
			}

			&--2 {
				@include xy-cell();

				background-color: $dark-gray;
				padding-top: map-get($spacing, xsmall);
				box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.4);
				top: 100%;
				overflow: hidden;
				z-index: 1;

				&::after {
					display: block;
					content: '';
					width: 100%;
					height: 5px;
					margin-top: map-get($spacing, xsmall);
					background: $primary-color;
				}

				#{$root}__submenu-item:hover {
					background: $light-gray;
				}
			}
		}
	}

	&__submenu-container {
		@include breakpoint(xmedium) {
			@include xy-grid-container;
		}
	}

	&__main-item {
		position: static;
		height: 100%;
		display: flex;
		align-items: center;

		#{$root}__teaser-title,
		#{$root}__teaser-text {
			color: $light-gray;
		}
	}

	&__submenu-item,
	&__submenu {
		@include breakpoint(xmedium) {
			position: relative;
		}

		.m-mainnav__arrow-left,
		.m-mainnav__arrow-right {
			display: none;
		}
	}

	&__main-item,
	&__menu-item {
		&.is-active-navitem {
			#{$root}__main-link,
			#{$root}__menu-link {
				&:after {
					transform: translateX(-50%) scale(1);
				}
			}

			#{$root}__menu-link {
				color: $primary-color;
			}

			> #{$root}__submenu {
				@include breakpoint(xmedium) {
					@include xy-grid;
					@include xy-grid-layout(
						3,
						'.m-mainnav__submenu-item',
						$gutter-type: padding,
						$gutters: (medium: 60px)
					);
					position: absolute;
					display: flex;
				}

				@include breakpoint(xxlarge) {
					@include xy-grid-layout(
						6,
						'.m-mainnav__submenu-item',
						$gutter-type: padding
					);
				}
			}
		}

		&.is-animating {
			z-index: z(navigation-flyout-animate);

			> #{$root}__submenu {
				@include breakpoint(xmedium) {
					@include xy-grid;
					@include xy-grid-layout(
						3,
						'.m-mainnav__submenu-item',
						$gutter-type: padding
					);
					position: absolute;
					display: flex;
				}

				@include breakpoint(xxlarge) {
					@include xy-grid-layout(
						6,
						'.m-mainnav__submenu-item',
						$gutter-type: padding
					);
				}
			}
		}
	}

	&__teaser-container {
		position: relative;
		height: 100%;

		&.has-submenu {
			cursor: pointer;
		}
	}

	&__teaser-title,
	&__teaser-text {
		transition: color $expo-tween-t1-time $expo-tween-t1;
	}

	&__teaser-title {
		@include breakpoint(xmedium) {
			@include use-font(roman);
			hyphens: auto;
		}

		@media #{$mobile-landscape} {
			margin-bottom: 0;
		}
	}

	&__teaser-text {
		display: none;

		@include breakpoint(xmedium) {
			@include use-font(roman);

			display: block;
			font-size: $global-font-size;
			line-height: 1.75;
			margin-bottom: 0;
		}

		@media #{$mobile-landscape} {
			display: none;
		}
	}

	/**
	 * Searchbar
	 */
	&__search-container {
		background-color: $dark-gray;
		height: $searchbar-height;
	}

	&__search-inner {
		@include xy-grid-container();
		height: 100%;
	}

	&__menu {
		margin: 0 0 0 auto;
		height: 100%;
		align-items: center;
		display: flex;
		z-index: z(main-navigation);

		@include breakpoint(xmedium) {
			display: flex;
		}

		#{$root}__menu-item,
		#{$root}__menu-link {
			height: 100%;
		}

		#{$root}__menu-link {
			color: $white;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			font-size: rem-calc(12px);
			padding: 0 map-get($spacing, xsmall);

			&:hover {
				color: $primary-color;
			}
		}

		#{$root}__menu-item {
			width: auto;
			position: static;
			margin-right: map-get($spacing, xsmall);

			@include breakpoint(xmedium) {
				&:last-of-type {
					margin-right: map-get($spacing, xsmall) * -1;
				}
			}

			#{$root}__menu-link {
				&:before {
					display: block;
					font-size: 30px;
					height: 30px;
					margin-bottom: 5px;
				}
			}
		}

		#{$root}__menu-item#{$root}__submenu-item
			#{$root}__menu-flyout-container {
			width: 100%;
			margin: 0;
			display: flex;
			align-items: center;

			@include breakpoint(xmedium) {
				width: 100%; // needed to be more specific
			}

			@include breakpoint(xxlarge) {
				width: 100%; // needed to be more specific
			}

			& > a {
				padding: 0;

				&#{$root}__watchlist-link {
					padding-right: 200px;
				}
			}
		}

		#{$root}__languageswitch {
			position: relative;
			text-align: center;
			padding-top: 0px;
			display: flex;
			align-items: center;
			align-content: center;

			@include breakpoint(medium) {
				display: block;
				padding-top: 1px;
			}

			@include breakpoint(xmedium) {
				padding-top: 9px;
			}

			button.m-mainnav__menu-languageselection {
				cursor: pointer;
				width: 32px;

				@include breakpoint(medium) {
					cursor: default;
				}

				&.is-active-navitem {
					.m-mainnav__language-select {
						display: none;
					}

					.m-mainnav__burger-close-icon {
						display: block;
					}
				}
			}

			.a-svgicon {
				color: $white;

				&:not(.a-svgicon--large) {
					width: 24px;
					height: 24px;
				}

				&:hover {
					color: $primary-color;

					@include breakpoint(medium) {
						color: $white;
					}
				}

				@include breakpoint(medium) {
					position: absolute;
					top: 8px;
					left: 50%;
					transform: translateX(-50%);
				}

				@include breakpoint(xmedium) {
					top: 16px;
				}
			}

			.m-mainnav__language-link {
				@include use-font(roman);
				text-decoration: none;
				color: $white;
				font-size: rem-calc(12px);
				box-shadow: none;
				transition: color 300ms ease-out;
				display: none;

				@include breakpoint(medium) {
					display: inline-block;
				}

				&.is-active {
					box-shadow: none !important;
					color: $primary-color;
				}

				&:hover, &:active {
					color: $primary-color;
					box-shadow: none !important;
				}

				&:not(:first-of-type):before {
					content: '';

					@include breakpoint(medium) {
						content: '|';
					}
				}

				&:before {
					padding: 0 rem-calc(5px);
				}
			}
		}

		.o-countryflyout {
			flex: 1 1 0; // IE Fix: "px"
		}

		#{$root}__submenu {
			display: none;
		}
	}

	&__menu-label {
		display: none;

		@include breakpoint(medium) {
			display: block;
		}
	}
}
