.m-hierarchyfilter {
	$root: &;

	select {
		margin-bottom: 0;
	}

	& &__container {
		padding: 0;
	}

	&__folder-select {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		&:last-child {
			#{$root}__icon-arrow-right {
				display: none;
			}
		}
	}

	&__icon-arrow-right {
		margin: 0 map-get($spacing, xsmall);

		@include breakpoint(medium) {
			margin-left: $grid-gutter;
			margin-right: 0;
		}
	}

	&__folder-select:last-child {
		margin-right: 36px;

		@include breakpoint(medium) {
			margin-right: 46px;
		}
	}

	&__options {
		flex-grow: 1;
	}
}
