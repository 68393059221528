.a-textarea {
	$root: &;

	&__input {
		@include yellow-bar-hover;
	}

	&__input {
		height: 137px;
		appearance: none;
		resize: none;
	}

	&__max-length {
		@include use-font(bold);

		margin-top: -16px;
		margin-bottom: 16px;
		text-align: right;
		color: $form-label-color;
		font-size: $form-label-font-size;
		line-height: $form-label-line-height;
	}

	&.has-error {
		label {
			color: $alert-color;
		}
	}
}
