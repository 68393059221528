.m-teaserlarge {
	$root: &;

	@include xy-grid;

	&--image-right {
		@include breakpoint(large) {
			flex-flow: row-reverse;
		}

		#{$root}__image-container {
			@include breakpoint(large) {
				margin-right: rem-calc(
					map-get($grid-margin-gutters, medium) / 2
				);
				margin-left: 0;
			}
		}

		#{$root}__flag {
			right: 0;
		}

		#{$root}__text-container {
			@include breakpoint(large) {
				margin-left: rem-calc(
					map-get($grid-margin-gutters, medium) / 2
				);
				margin-right: 0;
			}
		}
	}

	&__image-container {
		@include xy-cell(12 of 12, $gutters: 0);

		@include breakpoint(large) {
			@include xy-cell(6 of 12, $gutters: 0);
			position: relative;
			margin: 0;
		}
	}

	&__image-content {
		display: block;
		position: relative;
		overflow: hidden;

		@include breakpoint(large) {
			position: absolute;
			height: 100%;
			width: 100%;
		}

		&--has-link {
			&:hover {
				#{$root}__image {
					transform: scale(1.1);
				}
			}
		}
	}

	&__image {
		@include object-fit(cover);
		max-height: calc(90vh - #{$header-height});

		@include breakpoint(large) {
			height: 100%;
			max-height: 100%;
		}
	}

	&__flag {
		position: absolute;
		z-index: z(content-navigation);
		background: $primary-color;
		padding: 5px rem-calc(8px);
		top: 15%;

		@include breakpoint(xmedium) {
			padding: 5px rem-calc(24px);
			top: 7.5%;
		}

		&__text {
			@include use-font(roman);
		}
	}

	&__videosvg-container {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 50%;
		width: 72px;
		height: 72px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		@include breakpoint(medium) {
			width: 96px;
			height: 96px;
		}

		@include breakpoint(large) {
			width: 120px;
			height: 120px;
		}
	}

	&__videosvg {
		color: $white;
	}

	&__text-container {
		@include xy-cell(12 of 12, $gutters: 0);
		background-color: $light-gray-01;
		padding: map-get($spacing, small);

		@include breakpoint(large) {
			@include xy-cell(6 of 12, $gutters: 0);
			padding: map-get($spacing, xmedium);
		}
	}

	&__title-container {
		margin-bottom: map-get($spacing, small);
	}

	&__sticker {
		margin-right: map-get($spacing, xsmall);
	}

	&__headline {
		hyphens: auto;
	}

	&__subline {
		@include use-font(roman);
		hyphens: auto;
	}

	&__contact-head {
		@include use-font(roman);

		padding-top: map-get($spacing, small);

		@include breakpoint(medium) {
			padding-top: map-get($spacing, xmedium);
		}
	}

	&__contact {
		margin-top: map-get($spacing, medium);
	}

	&__buttons {
		margin-top: map-get($spacing, xmedium);
		text-align: right;

		@include breakpoint(medium) {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
		}

		@include breakpoint(large) {
			margin-top: map-get($spacing, large);
		}
	}

	&__button {
		&--secondary {
			@include breakpoint(medium) {
				margin-right: map-get($spacing, xsmall);
			}
		}
	}
}
