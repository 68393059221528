.m-infobox {
	@mixin choose-background-color($colorName) {
		$bg-colors: (
			background-viega-yellow: $viega-yellow,
		);

		@if(map-has-key($bg-colors, $colorName)) {
			background-color: map-get($bg-colors, $colorName);
		} @else {
			background-color: $light-gray-01;
		}
	}

	display: flex;
	flex-flow: column;
	background-color: $light-gray-01;
	padding-top: map-get($spacing, medium);
	padding-bottom: map-get($spacing, medium);
	align-items: center;

	&--viega-yellow {
		@include choose-background-color(background-viega-yellow);
	}

	@include breakpoint(medium) {
		flex-flow: row;
	}

	&__icon {
		@include xy-gutters;
		margin-bottom: 20px;

		@include breakpoint(medium) {
			@include xy-gutters($gutter-position: left right);
			flex-shrink: 0;
			margin-bottom: 0;
			width: 48px;
			height: 48px;
		}
	}

	&__content {
		@include xy-gutters($gutter-position: right);
		list-style-type: none;

		@include breakpoint(small only) {
			margin-left: map-get($spacing, small);
		}

		> * {
			margin-bottom: map-get($spacing, small);
		}

		> :last-child {
			margin-bottom: 0;
		}
	}
}
