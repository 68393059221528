.o-distributorform{

	&__whitespace.a-checkbox{
		margin-left: map-get($spacing, large);
		margin-bottom: map-get($spacing, small);
		margin-top: 0px;
	}

	&__noItems.a-checkbox{
		margin-bottom: 0px;
	}

	&__withItems.a-checkbox{
		margin-bottom: map-get($spacing, medium);
	}

	&__mediaListRight{
		margin-top: map-get($spacing, medium);

		@include breakpoint(medium) {
			margin-top: 0px;
		}
	}
}
