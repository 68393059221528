.m-carousel {
	$root: &;

	position: relative;
	background-color: $accent-color;

	// force same height of each slide - slick slider fix
	.slick-track {
		display: flex !important;
		margin: 0;
	}
	.slick-slide {
		height: inherit !important;
	}

	&__slide {
		position: relative;
	}

	&__image {
		@include object-fit(cover);
		max-height: calc(90vh - #{$header-height});
	}

	&__slider-description {
		padding: map-get($spacing, small) map-get($spacing, small)
			map-get($spacing, xmedium);

		@include breakpoint(xmedium) {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			padding: map-get($spacing, large) map-get($spacing, xmedium);
			color: $white;
			background: linear-gradient(
				10deg,
				rgba($black, 0.3) 40%,
				transparent 100%
			);
			// safari fix
			-webkit-transform: translate3d(0, 0, 0);

			&.is-dark {
				color: $black;
				background: linear-gradient(
					10deg,
					rgba($white, 0.3) 40%,
					transparent 100%
				);

				#{$root}__link {
					color: $black;
				}
			}
		}
	}

	&__link {
		align-self: flex-start;

		@include breakpoint(xmedium) {
			color: $white;
			margin-bottom: map-get($spacing, small);
		}
	}

	&__arrows {
		display: none;

		@include breakpoint(xmedium) {
			display: flex;
		}
	}

	/**
	 *	Dot-navigation
	 */
	&__dots {
		@include slider-dots($animation: false);

		&.has-animation {
			@include slider-dots($animation: true, $animation-duration: 5000);
		}
	}
}
