.m-countryhint {
	display: none;
	width: 100%;
	padding: map-get($spacing, small) 0;
	background: $white;
	z-index: z(hint);
	box-shadow: 0 4px 2px -2px $light-gray;

	&.is-active {
		display: block;
		animation: fadeInFromNone 300ms ease-out;
	}

	&__content {
		@include xy-grid-container;
		@include xy-grid;
		flex-flow: row nowrap;
		justify-content: space-between;
	}

	&__language-item-wrapper {
		display: inline;
		flex-shrink: 0;
	}

	&__language-item {
		&:not(:last-child) {
			&::after {
				content: '|';
				display: inline-block;
			}
		}
	}

	&__link {
		display: inline-block;
		margin: 0 map-get($spacing, xsmall);
	}

	&__close-button {
		display: flex;
		flex-direction: column;
		cursor: pointer;
		margin-left: map-get($spacing, xsmall);

		@include breakpoint(medium) {
			margin-left: map-get($spacing, small);
		}
	}
}
