.o-countryflyout {
	&__content {
		@include xy-grid;
		border-top: 1px solid lightgray;
		padding-top: map-get($spacing, small);
	}

	h3 {
		@include use-font(roman);
		margin-bottom: rem-calc(10px);
	}

	li {
		list-style-type: none;
	}

	&__mobile-container {
		@include breakpoint(xmedium) {
			display: none;
		}
	}

	&__scroll-container {
		@extend %scrollbar;
		@include xy-cell(12, false, 0);

		.has-vcards & {
			display: none;

			@include breakpoint(xmedium) {
				display: block;
				@include xy-cell(8, false, 0);
			}
		}
	}

	&__country-list {
		columns: 4 240px;
		margin: 0;
	}

	&__character {
		display: flex;
		align-items: center;
		margin-bottom: 2px;
	}

	&__char {
		margin-left: 24px;
		margin-bottom: 0;
		margin-top: 20px;
		font-size: rem-calc(22px);
		display: block;

		&.no-margin {
			margin-top: 0
		}
	}

	&__country {
		@include use-font(roman);
		font-size: $global-font-size;
		cursor: pointer;
		line-height: 2.2;
		width: fit-content;
		color: $secondary-color;
		transition: color $expo-tween-t1-time $expo-tween-t1;

		&:first-child {
			margin-left: $countryflyout-char-size;
		}

		&:hover,
		&.is-active {
			box-shadow: inset 0 -5px 0 0$primary-color !important
		}
	}

	&__map {
		display: block;

		&.is-inactive {
			display: none;
		}
	}

	&__vcards {
		width: 100%;
		margin-left: 0;
		position: relative;

		@include breakpoint(xmedium) {
			@include xy-cell(
				4,
				$gutter-position: left,
				$gutters: rem-calc(30px)
			);
		}
	}

	&__card {
		padding: 0 20px 10px;
		display: none;
		animation-duration: 2s;
		animation-timing-function: $expo-tween-t1;
		background: $light-gray-01;

		&.is-active {
			width: 100%;
			max-width: 400px;
			position:sticky;
			top: 20px;
			display: block;
			animation-name: fadeIn;
		}
	}

	&__vcard-country {
		padding: 20px 0;
	}

	&__button-container {
		&--1 {
			margin-top: map-get($spacing, xsmall);
		}
	}
}
