.o-watchlistoverview {
	display: flex;
	flex-flow: column;

	@media print {
		&__watchlist-category-list {
			.m-accordion__item {
				display: none;

				&.is-active {
					display: block;
				}
			}
		}

		a {
			text-decoration: none;
		}
	}

	&__email-input {
		@include xy-cell(12 of 12, $gutters: 0);
	}

	&__counter {
		margin-left: 8px;
	}

	&__button {
		margin-left: auto;
	}

	&__options-button {
		@include use-font(bold);
		display: flex;
		padding: 5px 0;
		align-items: center;
		margin-bottom: map-get($spacing, xsmall);
		cursor: pointer;

		&.is-active {
			&::after {
				transform: rotate(-180deg);
			}
		}

		&::after {
			@include css-triangle(7px, $dark-gray, down);
			display: inline-block;
			margin-left: 5px;
		}
	}

	&__category-actions-container {
		height: 0;
		overflow: hidden;
		transition: margin-bottom $expo-tween-t1 400ms;

		&.is-active {
			margin-bottom: map-get($spacing, small);
		}
	}

	&__category-actions {
		display: flex;
		justify-content: flex-start;
		flex-flow: column;
		padding: map-get($spacing, xsmall) map-get($spacing, small)
			map-get($spacing, small);
	}

	&__category-delete-link {
		cursor: pointer;
	}

	&__category-download-link,
	&__category-delete-link {
		@include use-font(roman);
		display: flex;
		align-items: center;
		text-decoration: none;
		align-self: flex-start;

		&:hover {
			color: $gray;
		}
	}

	&__category-download-link {
		margin-right: map-get($spacing, medium);
		margin-bottom: map-get($spacing, small);
	}

	&__download-icon {
		margin-right: map-get($spacing, xsmall);
	}

	&__overlay--copytoken {
		display: flex;
		flex-direction: row;
		margin-bottom: map-get($spacing, small);
		padding: 10px 0;

		> div:first-child {
			max-width: 95%;
			white-space: nowrap;
  			overflow: hidden;
  			text-overflow: ellipsis;
		}

		button:hover {
			cursor: pointer;
		}
	}

	&__overlay-buttons-container {
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;
	}
}
