.o-symposium2022form {
	$root: &;

	&__reset-margin {
		margin-top: 60px !important;
		margin-bottom: -60px !important;
	}

	&__hidden {
		display: none;
	}

	&__delete-button {
		margin-top: 5px;
	}
}
