@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInFromNone {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}

@keyframes pulsate {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.8);
	}
	90% {
		transform: scale(1);
	}
}

@keyframes shadow-pulse {
	0% {
		transform: scale(1);
		opacity: 0.6;
	}
	40% {
		transform: scale(1.4);
		opacity: 0.6;
	}
	100% {
		transform: scale(1.4);
		opacity: 0;
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes teaserLoadingAnimation {
	from {
		left: -100%;
	}
	to {
		left: 100%;
	}
}

@keyframes expand {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}

@keyframes bounce {
	from {
		transform: translateY(-5px);
	}
	to {
		transform: translateY(5px);
	}
}

@keyframes bounceLeft {
	from {
		transform: translateX(-5px);
	}
	to {
		transform: translateX(5px);
	}
}
