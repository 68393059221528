.c-productrenderer {
	$root: &;

	position: relative;
	background: $accent-color;

	/**
	 * Progress Bar
	 */
	&__progress {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 10px;

		-webkit-appearance: none;
		appearance: none;

		&::-webkit-progress-bar {
			background-color: $gray;
		}

		&::-webkit-progress-value {
			background-color: $primary-color;
			transition: width 200ms ease-out;
		}

		&.is-loading {
			display: block;
		}
	}

	/**
	 * 3D Viewport
	 */
	&__viewport {
		width: 100%;
		height: 450px;
		display: flex;
		align-items: center;
		justify-content: center;

		& > canvas {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	/**
	 * Placeholder
	 */
	&__placeholder {
		display: flex;
		flex-flow: column;
		width: 100%;
		height: 100%;
		text-align: center;
		justify-content: center;
		cursor: pointer;
		padding: map-get($spacing, medium);

		#{$root}__icon {
			@include svg-stroke(2px);
		}

		&:hover {
			@include svg-scale(1.05);
		}
	}

	&__fallback-image {
		align-self: flex-start;
		max-width: 70%;
		max-height: 70%;
		margin: auto;
	}

	&__fallback-content {
		margin-top: map-get($spacing, medium);
		text-align: right;
	}

	&__fallback-cta {
		margin: auto;
	}

	/**
	 * Help Dropdown
	 */
	&__help {
		display: none;
		position: absolute;
		top: map-get($spacing, small);
		right: map-get($spacing, small);
		text-align: right;

		#{$root}__help-popup {
			display: none;
			background-color: $white;
			padding: map-get($spacing, small);
			text-align: left;
		}

		&--open {
			#{$root}__help-popup {
				display: block;
			}
		}
	}

	&__controls {
		margin: 0;
		list-style: none;

		& > li {
			display: flex;
			margin-bottom: map-get($spacing, small);

			&:last-of-type {
				margin: 0;
			}
		}

		#{$root}__icon {
			width: 30px;
			height: 30px;
			margin-right: map-get($spacing, xsmall);
		}

		&--desktop {
			display: none;
		}

		@media (hover: hover),
			all and (-ms-high-contrast: none),
			(-ms-high-contrast: active) {
			&--desktop {
				display: block;
			}
			&--mobile {
				display: none;
			}
		}
	}

	/**
	 * Viewport Settings (Auto-Rotate, Reset)
	 */
	&__settings {
		position: absolute;
		bottom: map-get($spacing, small);
		left: map-get($spacing, small);
		display: none;

		& > button {
			margin-right: map-get($spacing, medium);
		}
	}

	/**
	 * Buttons
	 */
	&__button {
		display: flex;
		align-items: center;
		cursor: pointer;

		&:hover {
			use {
				stroke-width: 8px;
			}
		}

		&--fullscreen {
			position: absolute;
			bottom: map-get($spacing, small);
			right: map-get($spacing, small);
		}

		&--help {
			display: inline-block;
		}

		&--autorotate {
			#{$root}__icon--play {
				display: none;
			}

			&.is-paused {
				#{$root}__icon--play {
					display: flex;
				}

				#{$root}__icon--pause {
					display: none;
				}
			}
		}
	}

	&__button-text {
		display: block;
		margin-left: map-get($spacing, xsmall);
	}

	/**
	 * Icons
	 */
	&__icon {
		use {
			stroke-width: 8px;
			transition: stroke-width 200ms ease-out;
		}

		&--fullscreen-activate,
		&--fullscreen-deactivate {
			display: none;
		}
	}

	/**
	 * Component Loaded
	 */
	&--loaded {
		#{$root}__icon--fullscreen-activate {
			display: flex;
		}

		#{$root}__help {
			display: block;
		}

		#{$root}__settings {
			display: inline-flex;
		}
	}

	/**
	 * Component Fullscreen
	 */
	&--is-fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999;
		width: 100%;
		height: 100%;

		#{$root}__viewport {
			width: 100%;
			height: 100%;
		}

		#{$root}__icon--fullscreen-activate {
			display: none;
		}

		#{$root}__icon--fullscreen-deactivate {
			display: block;
		}
	}
}
