.m-cookielayer {
	position: fixed;
	transform: translateY(100%);
	bottom: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	background: $white;
	padding: map-get($spacing, medium) 0;
	border-top: 1px solid $light-gray;
	z-index: z(main-navigation);
	transition: transform 300ms ease-out, opacity 300ms ease-out;

	@include breakpoint(medium) {
		padding: map-get($spacing, small) 0;
	}

	&.is-active {
		opacity: 1;
		transform: translateY(0);
	}

	&__content {
		@include xy-grid-container;
		@include xy-grid;

		align-items: center;
		flex-flow: column wrap;

		@include breakpoint(medium) {
			flex-flow: row nowrap;
		}
	}

	&__richtext {
		flex: 1 1 auto;

		@include breakpoint(small only) {
			width: 100%;
		}

		@include breakpoint(medium) {
			margin-right: map-get($spacing, small);
		}
	}

	&__button {
		flex: 0 0 auto;
		margin: map-get($spacing, xsmall) 0 0 0;
		align-self: flex-end;

		@include breakpoint(medium) {
			margin: 0;
			width: auto;
		}
	}
}
