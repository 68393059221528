.o-flushingstationform {
	$root: &;

	&__dataprivacy-link {
		@include use-font(bold);
	}

	&__subline {
		@include use-font(bold);

		display: inline-block;

		&::before {
			content: counter(section) '.';
		}
	}

	&__dataprivacy {
		margin-bottom: map-get($spacing, small);
	}

	&__delete-registration {
		display: none;
	}

	&__registration-entries {
		counter-reset: section;

		#{$root}__subline {
			counter-increment: section;
		}

		#{$root}__registration-entry {
			margin-top: map-get($spacing, small);
		}

		&.is-active {
			#{$root}__delete-registration {
				display: inline-block;
			}
		}
	}

	&__icon {
		margin-left: map-get($spacing, xsmall);
	}

	.g-form__footer {
		margin-top: 10px;
	}
}
