.m-teaserlistitem {
	$root: &;
	$image-size: 170px;

	@include xy-grid($wrap: false);
	padding: 0;

	&__image-container {
		display: none;

		@include breakpoint(xmedium) {
			display: flex;
			align-items: center;
			flex: 0 0 auto;
			width: $image-size;
			min-height: $image-size;
			background: $accent-color;
			overflow: hidden;

			&--light {
				background-color: $white;
				border: 1px solid $light-gray-01;
			}

			&:hover {
				#{$root}__image {
					transform: scale(1.1);
				}
			}
		}
	}

	&__image {
		@include object-fit(scale-down);
		max-height: $image-size;
	}

	&__text-container {
		flex: 1 1 auto;
		padding: map-get($spacing, small);
		background: $body-background;

		@include breakpoint(small only) {
			width: 100%;
		}
	}

	&__sticker-bookmark-container {
		display: flex;
		justify-content: space-between;
	}

	&__sticker-container {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		margin-bottom: map-get($spacing, xsmall);
	}

	&__sticker {
		margin-right: 5px;
		margin-bottom: 5px;

		&--tag {
			padding: 1px 4px;
			box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
			pointer-events: none;
			background: $white;
			border-radius: 3px;

			> :first-child {
				color: $black;
			}
		}
	}

	&__bookmark-container {
		display: flex;
		justify-content: flex-end;
	}

	&__bookmark {
		display: flex;
		height: 24px;
		justify-content: center;
	}

	&__title {
		display: block;
	}

	&__headline {
		word-break: break-word;
		hyphens: auto;
	}

	&__description {
		display: block;
	}

	&__details {
		@include use-font(bold);
		display: flex;
		flex-flow: row wrap;
		position: relative;

		&-item {
			margin-right: rem-calc(24px);
			white-space: nowrap;

			@include breakpoint(xmedium) {
				position: relative;
			}
		}
	}

	&__button {
		display: none;
		margin: 0;
		white-space: nowrap;

		@include breakpoint(large) {
			display: inline-flex;
		}
	}

	&--is-searchresult {
		#{$root}__sticker-container {
			@include breakpoint(xmedium) {
				margin-left: -40px;
				z-index: 1;
			}
		}
	}

	&--seminar-teaser {
		@include breakpoint(large) {
			#{$root}__details {
				display: flex;
				justify-content: space-between;

				&--withbtn {
					display: flex;
					align-items: center;
				}
			}
		}
	}

	&--ics-download-teaser {
		#{$root}__details {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		#{$root}__button {
			display: inline-flex;
		}
	}

	&--reference-teaser {
		flex-direction: column;

		@include breakpoint(xmedium) {
			flex-direction: row;
		}

		#{$root}__image-container {
			position: relative;
			display: flex;

			@include breakpoint(xmedium) {
				@include xy-cell(5 of 12, $gutters: 0);

				position: relative;
			}
		}

		#{$root}__image {
			max-height: 100%;

			@include breakpoint(medium) {
				position: initial;
			}

			@include breakpoint(xmedium) {
				@include object-fit(cover);
				width: 640px;
				height: auto;
				top: 0;
				left: 0;
			}
		}

		#{$root}__sticker-container {
			@include breakpoint(xmedium) {
				margin-left: 0;
				z-index: 0;
			}
			@include breakpoint(xmedium) {
				margin-left: -40px;
				z-index: 1;
			}
		}

		#{$root}__text-container {
			@include breakpoint(xmedium) {
				flex-grow: 1;
			}
		}

		#{$root}__title {
			@include use-font(roman);

			display: block;
			margin-top: map-get($spacing, xsmall);
		}

		#{$root}__description {
			margin-bottom: map-get($spacing, xsmall);
		}
	}

	&__details-copy {
		@include breakpoint(xmedium) {
			margin-bottom: 0;
		}
	}

	&__details-item {
		strong {
			@include use-font(bold);
		}

		&.has-flyout {
			cursor: pointer;

			#{$root}__details-copy {
				position: relative;
				display: flex;
				align-items: center;

				&:before {
					transition: transform $expo-tween-t1-time $expo-tween-t1;
				}

				&:after {
					@include css-triangle(8px, $secondary-color, down);
					margin-left: map-get($spacing, xsmall);
				}

				&:before {
					@include css-triangle(11px, $secondary-color, up);
					position: absolute;
					bottom: -12px;
					left: 50%;
					transform: translateX(-50%) scale(0);
					transform-origin: 50% 100%;
				}
			}
		}

		&.is-active {
			#{$root}__details-copy {
				&:before {
					transform: translateX(-50%) scale(1);
				}
			}
		}
	}

	&__details-flyout {
		display: block;
		cursor: default;
		position: absolute;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%) translateY(100%);
		height: 0;
		width: 100%;
		background-color: $secondary-color;
		color: $white;
		overflow: hidden;
		z-index: z(overlays);

		@include breakpoint(xmedium) {
			width: 320px;
			bottom: -(map-get($spacing, xsmall));
		}

		&-inner {
			padding: map-get($spacing, small);
		}

		ul {
			list-style: none;
			margin: 0;
			column-count: 3;
		}

		li {
			cursor: auto;
		}
	}

	&__quantity-input {
		margin-left: 0;

		@include breakpoint(xmedium) {
			margin-left: auto;
		}
	}
}
