.m-placeholderimage {
	position: relative;
	background: $accent-color;
	padding: map-get($spacing, xsmall);

	img {
		margin-bottom: map-get($spacing, small);
	}

	&__content {
		text-align: center;
		font-size: 14px;
	}

	&__buttons {
		margin-top: map-get($spacing, small);

		.a-button--left {
			@include breakpoint(medium) {
				margin-right: map-get($spacing, small);
			}
		}
	}
}
