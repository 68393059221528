.m-watchlistflyoutteaser {
	&__header {
		display: flex;
	}

	&__link {
		display: flex;
		flex-grow: 1;
		justify-content: space-between;
	}

	&__headline {
		@include use-font(bold);
		hyphens: auto;
	}

	&__category {
		@include use-font(light);
	}

	&__date {
		@include use-font(light);
		color: $light-gray;
		margin-right: map-get($spacing, small);
	}

	&__text {
		@include use-font(light);
	}
}
