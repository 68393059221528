.o-softwareorderform {
	$root: &;

	transition: opacity 500ms ease-out;
	opacity: 1;

	&.fade-out {
		opacity: 0;
	}

	&.is-summary {
		.a-quantity__plus,
		.a-quantity__minus {
			display: none;
		}

		#{$root}__submit-cta {
			display: block;
		}

		#{$root}__back-cta {
			opacity: 1;
		}

		#{$root}__overview-cta {
			display: none;
		}

		/**
		* Override a-input stylings for summary page
		*/
		.a-input,
		.a-select,
		.a-textarea {
			&__input {
				background: transparent;
				color: inherit;
				box-shadow: none;
				padding-left: 0;
				border: 0;
				pointer-events: none;

				&:hover,
				&:active,
				&:focus {
					&:not(:-webkit-autofill) {
						// override important global styling
						box-shadow: none !important;
					}
				}

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					border: 0;
					-webkit-text-fill-color: currentColor;
					// override important global styling
					box-shadow: 0 0 0 1000px $white inset !important;
				}
			}
		}
		.a-radiobutton__input[type='radio']:checked + .a-radiobutton__checked {
			display: none;
		}

		.a-radiobutton {
			&__input {
				display: none;
			}
		}
	}

	&__table-container {
		margin-bottom: map-get($spacing, xlarge);

		&.has-error {
			#{$root}__table {
				margin-bottom: map-get($spacing, small);
			}
		}
	}

	&__table {
		.m-table__head-item,
		.m-table__body-item {
			@include breakpoint(xmedium) {
				white-space: initial;
			}
		}
	}

	&__total-price-container {
		display: flex;
		justify-content: flex-end;
		flex-flow: row nowrap;
		margin-top: map-get($spacing, small);
	}

	&__total-price {
		margin: 0 5px;
	}

	&__richtext01 {
		margin-top: map-get($spacing, medium);
	}

	&__submit-cta {
		display: none;
	}

	&__back-cta {
		opacity: 0;
	}
}
