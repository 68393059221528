@font-face {
	font-family: 'M Ying HeiTM PRC for Viega W3';
	src: url('../resources/fonts/MYingHeiPRCforViegaW3_normal_normal_subset1.woff2')
	format('woff2'),
	url('../resources/fonts/MYingHeiPRCforViegaW3_normal_normal_subset1.woff')
	format('woff');
}
@font-face {
	font-family: 'M Ying HeiTM PRC for Viega W3 Italic';
	src: url('../resources/fonts/MYingHeiPRCforViegaW3Italic_italic_normal_subset1.woff2')
	format('woff2'),
	url('../resources/fonts/MYingHeiPRCforViegaW3Italic_italic_normal_subset1.woff')
	format('woff');
}
@font-face {
	font-family: 'M Ying HeiTM PRC for Viega W4';
	src: url('../resources/fonts/MYingHeiPRCforViegaW4_normal_normal_subset1.woff2')
	format('woff2'),
	url('../resources/fonts/MYingHeiPRCforViegaW4_normal_normal_subset1.woff')
	format('woff');
}

@font-face {
	font-family: 'M Ying HeiTM PRC for Viega W4 Italic';
	src: url('../resources/fonts/MYingHeiPRCforViegaW4Italic_italic_normal_subset1.woff2')
	format('woff2'),
	url('../resources/fonts/MYingHeiPRCforViegaW4Italic_italic_normal_subset1.woff')
	format('woff');
}

@font-face {
	font-family: 'M Ying HeiTM PRC for Viega W7';
	src: url('../resources/fonts/MYingHeiPRCforViegaW7_normal_normal_subset1.woff2')
	format('woff2'),
	url('../resources/fonts/MYingHeiPRCforViegaW7_normal_normal_subset1.woff')
	format('woff');
}

@font-face {
	font-family: 'M Ying HeiTM PRC for Viega W7 Italic';
	src: url('../resources/fonts/MYingHeiPRCforViegaW7Italic_italic_normal_subset1.woff2')
	format('woff2'),
	url('../resources/fonts/MYingHeiPRCforViegaW7Italic_italic_normal_subset1.woff')
	format('woff');
}

$fonts: (
	thin: 'M Ying HeiTM PRC for Viega W3',
	light: 'M Ying HeiTM PRC for Viega W3',
	lt-it: 'M Ying HeiTM PRC for Viega W3 Italic',
	roman: 'M Ying HeiTM PRC for Viega W4',
	bold: 'M Ying HeiTM PRC for Viega W7'
);

@function font($name) {
	@return map-get($fonts, $name);
}

@mixin use-font($name) {
	font-family: font($name), Helvetica, Arial, sans-serif;
}
