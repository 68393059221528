@media print {
	.is-hidden\@print {
		display: none !important;
	}

	// hide href value inside print views
	a[href]:after {
		display: none;
		visibility: hidden;
	}
}
