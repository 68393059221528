.m-accordion {
	$root: &;

	@include accordion-container;
	width: 100%;
	background: none;
	margin-bottom: 0;

	&__item {
		@include accordion-item;
		border-bottom: 1px solid $color-borders;

		&:first-of-type {
			border-top: 1px solid $color-borders;
		}

		&.is-inactive {
			#{$root}__title {
				color: $light-gray;
				cursor: not-allowed;
			}
		}

		&.is-active {
			#{$root}__arrow {
				transform: rotate(180deg);
			}

			#{$root}__link:hover {
				#{$root}__arrow {
					transform: scale3d(1.2, 1.2, 1.2) rotate(180deg);
				}
			}
		}

		&--with-title-image {
			#{$root}__content {
				@include breakpoint(medium) {
					padding-left: 110px;
				}
			}
		}
	}

	&__link {
		@include accordion-title;
		@include use-font(light);
		font-size: 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: color $expo-tween-t1-time $expo-tween-t1;

		&:hover,
		&:focus {
			background: none;
		}
	}

	&__title {
		@include use-font(light);
		font-size: 20px;
	}

	&__new-flag {
		background-color: $primary-color;
		padding: 3px map-get($spacing, xsmall);
		margin-bottom: 3px;
		width: fit-content;
	}

	&__content {
		@include accordion-content;

		&:hover,
		&:focus {
			background: none;
		}

		.a-image {
			object-fit: contain;
			object-position: top;
		}

		> div > div:nth-last-child(2) > div {
			margin-bottom: 0;
		}
	}

	&__headline {
		@include use-font(roman);
		display: inline-block;
	}

	&__footer {
		display: flex;
		justify-content: flex-end;
		margin: $accordion-content-padding 0;
	}

	&--hasBg {
		#{$root} {
			&__item {
				padding-bottom: map-get($spacing, xsmall);
				border: none;

				&.is-active {
					#{$root}__link {
						background-color: $accent-color;
					}
				}
			}

			&__link {
				padding: 0;
				background-color: $light-gray-01;

				&:hover {
					background-color: $accent-color;
				}
			}

			&__image-text-container {
				display: flex;
			}

			&__image-container {
				display: none;

				@include breakpoint(medium) {
					display: flex;
					flex-shrink: 0;
					align-items: center;
					justify-content: center;
					width: 100px;
					background: $accent-color;
				}
			}

			&__image {
				height: 50px;
				width: 50px;
			}

			&__text-container {
				@include use-font(roman);
				padding: map-get($spacing, small) $accordion-content-padding;
			}

			&__date {
				color: $light-gray;
				margin-bottom: 0;
			}

			&__description {
				@include use-font(light);
				font-size: $global-font-size;
				margin-bottom: 0;
			}

			&__arrow {
				flex-shrink: 0;
				margin-right: map-get($spacing, small);
			}

			&__content {
				background-color: $accent-color;
				border-top: 2px solid $white;
			}
		}
	}
}
