.m-teaserloading {
	&__loading-teaser {
		$teaser: &;
		background-color: $light-gray-01;
		display: flex;
		flex-flow: row;
		align-items: center;
		margin-bottom: 30px;

		&:not(#{$teaser}--small) {
			&:before {
				@include breakpoint(xmedium) {
					display: block;
					content: '';
					flex: 0 0 auto;
					width: 170px;
					height: 178px;
					background-color: $accent-color;
				}
			}
		}

		&--small {
			display: block;
			width: 260px;
			height: 16px;
			margin-top: 15px;
			margin-bottom: 28px;
			position: relative;
			overflow: hidden;
			background: $accent-color;

			&:before {
				width: 260px;
				animation: $animateLoadingTeaser;
				content: '';
				background: $loadingAnimationGradient;
				height: 100%;
				position: absolute;
				top: 0;
				left: -100%;
				z-index: 1;
			}
		}

		&--dark {
			background-color: #555;

			&:is(#{$teaser}--small),
			&:not(#{$teaser}--small) {
				&:before {
					@include breakpoint(xmedium) {
						background: $loadingAnimationGradientDark;
					}
				}
			}

			#{$teaser}-content-item {
				background: $loadingAnimationGradientDark;

				&:before {
					background: $loadingAnimationGradientDark;
					animation: $animateLoadingTeaserDark;
				}
			}

			#{$teaser}--small {
				background: $loadingAnimationGradientDark;

				&:before {
					background: $loadingAnimationGradientDark;
					animation: $animateLoadingTeaserDark;
				}
			}
		}

		&--reverse {
			// for autosuggestion results
			flex-flow: row-reverse;
			justify-content: space-between;
		}

		&-content {
			padding: map-get($spacing, medium) map-get($spacing, medium);
			width: 100%;

			@include breakpoint(xmedium) {
				padding: 0 map-get($spacing, medium);
			}

			&-item {
				position: relative;
				height: 8px;
				overflow: hidden;
				background: $accent-color;
				width: 100%;

				&:before {
					animation: $animateLoadingTeaser;
					content: '';
					background: $loadingAnimationGradient;
					height: 100%;
					position: absolute;
					top: 0;
					left: -100%;
					width: 100%;
					z-index: 1;
				}

				&:first-child {
					height: 20px;
					margin-bottom: 15px;
					width: 60%;

					@include breakpoint(xmedium) {
						width: 200px;
					}

					&:before {
						@include breakpoint(xmedium) {
							width: 200px;
						}
					}
				}

				&:nth-child(2) {
					margin-bottom: 8px;
					width: 80%;

					@include breakpoint(xmedium) {
						width: 310px;
					}

					&:before {
						@include breakpoint(xmedium) {
							width: 310px;
						}
					}
				}

				&:nth-child(3) {
					margin-bottom: 8px;
					width: 100%;

					@include breakpoint(xmedium) {
						width: 370px;
					}

					&:before {
						@include breakpoint(xmedium) {
							width: 370px;
						}
					}
				}

				&:nth-child(4) {
					width: 69%;

					@include breakpoint(xmedium) {
						width: 260px;
					}

					&:before {
						@include breakpoint(xmedium) {
							width: 190px;
						}
					}
				}
			}
		}
	}
}
