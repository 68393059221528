.m-footernavigation {
	$root: &;

	&__item-list {
		display: block;
		margin: 0;

		@include breakpoint(xmedium) {
			display: flex;
		}
	}

	&__item {
		display: flex;
		align-items: center;

		@include breakpoint(xmedium) {
			&:first-child #{$root}__link {
				padding-left: 0;
			}
		}

		&:not(:first-child):before {
			content: '';

			@include breakpoint(xmedium) {
				content: '|';
			}
		}
	}

	&__link {
		margin-bottom: 5px;
		text-decoration: none;

		@include breakpoint(xmedium) {
			margin-bottom: 0;
			padding: 6px 1rem;
		}
	}
}
