.m-metadata {
	display: flex;
	flex-direction: row;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: map-get($spacing, small);

	&__date {
		color: $light-gray;
		margin-right: map-get($spacing, xsmall);
	}

	&__author {
		color: $gray;
	}
}
