.a-button {
	$root: &;

	@include button;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	line-height: rem-calc(20px);
	transition: background-color, color 0.3s ease-out;
	text-transform: uppercase;

	&--primary {
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
	}

	&--secondary {
		background-color: $secondary-color;
		color: $white;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);

		&:hover,
		&:active,
		&:focus {
			background-color: $secondary-color;
			color: $primary-color;

			.a-svgicon {
				fill: $primary-color;
			}
		}

		.a-svgicon {
			fill: $white;
		}
	}

	&--tertiary {
		background-color: $white;
		color: $secondary-color;
		box-shadow: none;

		&:hover,
		&:active,
		&:focus {
			color: $secondary-color;
			background-color: $white;
		}
	}

	&--inactive,
	&.is-disabled {
		background-color: $light-gray-01;
		color: $light-gray;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
		cursor: not-allowed;

		&:hover,
		&:active,
		&:focus {
			background-color: $light-gray-01;
			color: $light-gray;
		}

		.a-svgicon {
			fill: $light-gray;
		}
	}

	&--left {
		align-self: start;
	}

	&--right {
		align-self: end;
		margin-left: auto;

		@include ie11Check {
			margin-left: 0;
		}
	}

	&--submit {
		position: relative;

		&.is-loading,
		&.is-successful {
			.a-svgicon:not(#{$root}__loader):not(#{$root}__success) {
				opacity: 0;
			}
		}
	}

	&__icon {
		&--left {
			margin-right: map-get($spacing, xsmall);
		}

		&--right {
			margin-left: map-get($spacing, xsmall);
		}
	}

	&__loader,
	&__success {
		&.a-svgicon {
			position: absolute;
			right: 0.85em;
			top: 50%;
			margin-top: -10px;
		}
	}

	&__loader {
		display: none;
		animation: rotate $expo-tween-t1-time linear infinite;

		.is-loading & {
			display: inherit;
		}
	}

	&__success {
		display: none;

		.is-successful & {
			display: inherit;
		}
	}
}
