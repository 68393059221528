.c-linkbuttonwithconfirmation {
	@include xy-grid-container;
	display: flex;
	flex-flow: column;

	&__button {
		align-self: flex-end;
		margin-top: map-get($spacing, medium);
		pointer-events: none;

		&:not(.a-button--inactive) {
			pointer-events: auto;
			background-color: $black;
		}
	}
}
