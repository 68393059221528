.a-checkbox {
	$root: &;
	$checkbox-size: 20px;

	display: flex;
	align-items: flex-start;
	position: relative;
	flex-wrap: wrap;

	&:hover {
		#{$root}__input {
			background-color: $light-yellow;
		}
	}

	&__input[type='checkbox'] {
		appearance: none;
		-webkit-appearance: none;
		background-color: $input-background;
		box-shadow: $input-shadow;
		border-radius: $input-radius;
		min-width: $checkbox-size;
		width: $checkbox-size;
		height: $checkbox-size;
		margin-bottom: 0;
		cursor: pointer;

		&:checked {
			background-color: $primary-color;

			+ #{$root}__svgicon {
				display: block;
			}
		}

		&:disabled {
			background-color: $input-background;

			~ #{$root}__label {
				color: $light-gray-02;
			}
		}
	}

	&__input-wrapper {
		position: relative;
		height: $checkbox-size;
		margin-right: $form-spacing / 2;
	}

	&__svgicon.a-svgicon {
		@include svg-stroke(25px);
		pointer-events: none;
		display: none;
		width: 12px;
		height: 12px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.is-inactive & {
			opacity: $input-opacity-disabled;
		}
	}

	&__label {
		line-height: 1.5;
		// IE11 needs separated flex properties
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: calc((100% - #{$checkbox-size}) - #{$form-spacing} / 2);
		cursor: pointer;
		hyphens: auto;

		// ie11 fix
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			flex-basis: auto;
			width: 90%;
		}
	}

	&.has-error {
		label {
			color: $alert-color;

			a {
				color: inherit;
			}
		}
	}

	&__error-message {
		margin-top: map-get($spacing, xsmall);
		flex: 1 0 100%;
	}

	&.is-disabled {
		#{$root}__label {
			color: $light-gray-02;
		}
	}
}
