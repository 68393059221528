.m-formpage {
	// a form page is initially hidden until it gets activated by a form
	display: none;
	opacity: 0;

	&.is-active {
		display: block;
		opacity: 1;
	}

	&__summary {
		.a-input,
		.a-select,
		.a-textarea,
		.a-checkbox {
			pointer-events: none;

			&__input,
			[type='checkbox']:checked {
				background: $input-background-disabled;
				color: inherit;
				box-shadow: none;
				// padding-left: 0;
				border: 0;
				pointer-events: none;

				&:hover,
				&:active,
				&:focus {
					&:not(:-webkit-autofill) {
						// override important global styling
						box-shadow: none !important;
					}
				}

				&:-webkit-autofill,
				&:-webkit-autofill:hover,
				&:-webkit-autofill:focus {
					border: 0;
					-webkit-text-fill-color: currentColor;
					// override important global styling
					box-shadow: 0 0 0 1000px $white inset !important;
				}
			}
		}
		.a-quantity__plus,
		.a-quantity__minus {
			display: none;
		}

		label {
			pointer-events: none;
		}

		.a-radiobutton__input[type='radio']:checked + .a-radiobutton__checked {
			display: none;
		}

		.a-radiobutton {
			&__input {
				display: none;
			}
		}
	}
}
