main {
	min-height: 80vh;
	max-width: $global-max-width;
	margin: auto;
	&:not(.p-homepage) {
		padding-top: 45px;
	}
}

.o-headerlite + main {
	min-height: calc(
		(100vh - #{$headerlite-height--mobile}) - #{$footerlite-min-height}
	);

	@include breakpoint(xmedium) {
		min-height: calc(
			(100vh - #{$headerlite-height}) - #{$footerlite-min-height}
		);
	}
}

.t-default {
	max-width: $global-max-width;
	margin: auto;

	&__content-shader {
		position: fixed;
		top: 0;
		left: 0;
		z-index: z(content-shader);
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0);
		transform: translateY(-100%);
		transition: background-color 400ms ease-in, transform 0ms ease-out;

		.is-active-shader & {
			transform: translateY(0);
			background-color: rgba(0, 0, 0, 0.6);
		}
	}
}

.o-gridcontainer--blog-page-columns {

	@mixin background-color-padding {
		padding-left: map-get($spacing, xsmall);
		padding-right: map-get($spacing, xsmall);
	}

	.o-gridcontainer--viega-yellow {
		@include background-color-padding;
	}

	.o-gridcontainer--light-grey {
		@include background-color-padding;
	}

	.o-gridcontainer--very-light-grey {
		@include background-color-padding;
	}
}

.section:not(.stickyNavigation), .section.cq-Editable-dom:not(.stickyNavigation) {

	@mixin grid-background-color($colorValue) {
		background-color: $colorValue;
		padding-top: map-get($spacing, medium);
	}

	&:has(.o-gridcontainer--viega-yellow) {
		@include grid-background-color($viega-yellow);

		::selection {
			background-color: $light-grey;
		}
		a {
			@include light-grey-bar-hover(true);
		}
	}

	&:has(.o-gridcontainer--black) {
		@include grid-background-color($black);
	}

	&:has(.o-gridcontainer--dark-grey) {
		@include grid-background-color($dark-grey);
	}

	&:has(.o-gridcontainer--mid-grey) {
		@include grid-background-color($mid-grey);
	}

	&:has(.o-gridcontainer--light-grey) {
		@include grid-background-color($light-grey);
	}

	&:has(.o-gridcontainer--stone-dark) {
		@include grid-background-color($stone-dark);
	}

	&:has(.o-gridcontainer--stone-light) {
		@include grid-background-color($stone-light);
	}

	&:has(.o-gridcontainer--blue) {
		@include grid-background-color($blue);
	}

	&:has(.o-gridcontainer--mid-blue) {
		@include grid-background-color($mid-blue);
	}

	&:has(.o-gridcontainer--light-blue) {
		@include grid-background-color($light-blue);
	}

	&:has(.o-gridcontainer--red) {
		@include grid-background-color($red);
	}

	&:has(.o-gridcontainer--green) {
		@include grid-background-color($green);
	}

	&:has(.o-gridcontainer--grey) {
		@include grid-background-color($grey);
	}

	&:has(.o-gridcontainer--very-light-grey) {
		@include grid-background-color($very-light-grey);
	}

}
