.o-gridcontainer {
	@include xy-grid-container;

	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto;
	grid-gap: $grid-gutter;
	width: 100%;
	margin-bottom: map-get($spacing, large);

	@include breakpoint(xlarge) {
		margin-bottom: map-get($spacing, xxlarge);
	}

	&--nomargin {
		margin-bottom: 0;

		@include breakpoint(xlarge) {
			margin-bottom: 0;
		}
	}

	&--2columns {
		@include breakpoint(large) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-template-rows: auto;
			grid-gap: $grid-gutter;
		}
	}

	&--3columns {
		@include breakpoint(medium) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-template-rows: auto;
			grid-gap: $grid-gutter;
		}

		@include breakpoint(large) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-template-rows: auto;
			grid-gap: $grid-gutter;
		}
	}

	&--4columns {
		@include breakpoint(medium) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-template-rows: auto;
			grid-gap: $grid-gutter;
		}

		@include breakpoint(large) {
			grid-template-columns: repeat(4, minmax(0, 1fr));
			grid-template-rows: auto;
			grid-gap: $grid-gutter;
		}
	}

	&--blog-page-columns {
		@include breakpoint(large) {
			grid-template-columns: 80% 20%;
			grid-template-rows: auto;
			grid-gap: $grid-gutter;
		}
	}

	&__column {
		overflow: visible;

		> .a-button--right,
		// special selector for AEM-Edit Mode
		> div > div.linkbutton.cq-Editable-dom > .a-button--right {
			float: right;

			+ *:not(.a-button)::before {
				content: '';
				display: table;
				clear: both;
			}
		}
	}

	// no spacing on nested grids
	.o-gridcontainer {
		padding: 0;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	> .a-button {
		margin-right: auto;

		&--right {
			margin-right: 0;
			margin-left: auto;
		}
	}
}
