.o-carouselstage {
	position: relative;

	&__slide-list {
		margin: 0;
		background-color: $primary-color;

		@include breakpoint(xmedium) {
			background-color: transparent;
		}
	}

	&__slide {
		background: $black;
		position: relative;
		width: 100vw;
	}

	&__image {
		@include object-fit(cover);
		width: 100%;
		max-height: calc(100vh - #{$header-height});
	}

	&__slide-teaser {
		background-color: $primary-color;
		color: $secondary-color;
		padding: map-get($spacing, medium) map-get($spacing, small)
			(map-get($spacing, medium) + $slider-nav-height)
			map-get($spacing, small);
		display: block;

		&:hover {
			cursor: pointer;
		}

		@include breakpoint(xmedium) {
			position: absolute;
			top: 50%;
			// for safari the element has to be transformed in 3d
			// -> prevents disappearing slide on slick slide change
			transform: translate3d(0, -50%, 0);
			padding: map-get($spacing, medium);
			width: 40%;
		}
	}

	&__slide-title {
		@include use-font(light);
		position: relative;
		line-height: 1.1;
		margin: 0;
	}

	&__slide-copy {
		@include use-font(light);
		position: relative;
		line-height: 1.5;
		margin: map-get($spacing, xsmall) 0 0;
	}

	&__slide-link {
		margin: map-get($spacing, xsmall) 0 0;
	}

	/**
	 *	Dot-navigation
	 */
	&__dots {
		@include slider-dots($animation: false);

		&.has-animation {
			@include slider-dots($animation: true, $animation-duration: 5000);
		}
	}

	&__scroll-indicator {
		display: none;

		@include breakpoint(xmedium) {
			@include use-font(bold);
			z-index: 1;
			position: absolute;
			bottom: 70px;
			right: -15px;
			display: flex;
			flex-direction: row-reverse;
			justify-content: center;
			padding: map-get($spacing, xsmall);
			font-size: 15px;
			color: $primary-color;
			text-shadow: 0 0 5px rgba(10, 10, 10, 0.1);
			transform: rotate(-90deg);
			span {
				padding-left: 10px;
			}

			&:hover {
				color: $primary-color;
			}
		}
	}

	&__icon {
		animation: bounceLeft 1s ease-in alternate infinite;
		color: black;
		background: $primary-color;
		border-radius: 50%;
		padding: 5px;
	}
}