.m-notification {
	$root: &;
	$m-notification-spacing-xsmall: map-get($spacing, xsmall);

	@include use-font(light);
	@include xy-grid-container();

	top: calc(#{$header-height--mobile} + 20px);
	z-index: z(notification);
	position: fixed;
	width: 100%;
	transition: opacity $expo-tween-t1-time $expo-tween-t1,
		transform $expo-tween-t1-time $expo-tween-t1;

	pointer-events: none;

	@include breakpoint(xmedium) {
		transform: translateX(-50%);
	}

	&.is-empty {
		pointer-events: none;
		opacity: 0;
		transform: translateY(-200%);

		@include breakpoint(xmedium) {
			transform: translateX(-50%) translateY(-200%);
		}

		#{$root}__container {
			pointer-events: none;
		}
	}

	.is-scrolldown > & {
		transform: translateY(-140%);

		&.is-empty {
			transform: translateY(-200%);
		}

		@include breakpoint(xmedium) {
			transform: translateX(-50%) translateY(-50%);

			&.is-empty {
				transform: translateX(-50%) translateY(-200%);
			}

			#{$root}__container {
				&::after {
					display: none;
				}
			}
		}
	}

	&__container {
		cursor: pointer;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
		position: relative;
		pointer-events: all;
		text-align: center;
		background-color: $primary-color;
		padding: $m-notification-spacing-xsmall $m-notification-spacing-xsmall 0
			$m-notification-spacing-xsmall;
		border-radius: 4px;

		@include breakpoint(xmedium) {
			border-radius: 0;
			box-shadow: none;
			text-align: left;
			padding: map-get($spacing, small);

			&::after {
				@include css-triangle(10px, $primary-color, up);

				position: absolute;
				top: 1px;
				left: calc(50% - 2px);
				transform: translateX(-50%) translateY(-100%) scale(1);
				transition: transform $expo-tween-t1-time $expo-tween-t1;
				transform-origin: 50% 100%;
			}
		}
	}

	&__button {
		margin-bottom: 0;

		.a-svgicon--undo {
			stroke: $secondary-color;

			@include breakpoint(xmedium) {
				stroke: $white;
			}
		}

		@include breakpoint(medium down) {
			background-color: transparent;
			box-shadow: none;
			color: $secondary-color;
			padding-top: 6px;

			&:hover,
			&:focus {
				background-color: inherit;
				color: inherit;
			}
		}

		@include breakpoint(xmedium) {
			&:hover,
			&:focus {
				.a-svgicon--undo {
					stroke: $primary-color;
				}
			}
		}
	}

	&__info {
		font-size: rem-calc(14px);
		text-align: center;

		@include breakpoint(xmedium) {
			font-size: rem-calc(16px);
			margin-bottom: 15px;
		}
	}

	@include breakpoint(xmedium) {
		top: $nav-height;
		width: auto;
	}

	@include breakpoint(xxlarge) {
		max-width: none;
	}
}
