.m-stickybutton {
    $root: &;
    opacity: 0;
    display: flex !important;
    position: fixed;
    right: 0;
    bottom: 40%;
    background: $primary-color;
    padding: 12px;
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg) translateY(150%);
    transition: transform 1s ease, opacity 0.8s ease;
    transform-origin: right bottom;
    will-change: transform;
    z-index: z(stickybutton);

    @include breakpoint(xmedium) {
        transform: translateX(150%);
        bottom: 25%;
        padding: 12px 20px;
    }

    & .a-svgicon {
        width: 26px;
        height: 26px;
        transform: rotate(90deg);

        @include breakpoint(xmedium) {
            transform: none;
            width: 36px;
            height: 36px;
        }
    }
    
    &__text {
        @include use-font(bold);
        text-transform:uppercase;
        font-size: 16px;
        line-height: 18px;
        padding-left: 9px;

        @include breakpoint(xmedium) {
            font-size: 20px;
            line-height: 22px;
        }
    }

    &:hover, &:focus, &:active{
        .a-svgicon {
            transform: rotate(90deg) scale3d(1.2, 1.2, 1.2);

            @include breakpoint(xmedium) {
                transform: scale3d(1.2, 1.2, 1.2);
            }
        }
    }

    &.is-visible {
        opacity: 1;
        transform: rotate(-90deg) translateY(0);
        @include breakpoint(xmedium) {
            transform: translateX(0);
        }
    }

    &--right {
        flex-direction: row-reverse;
        #{$root}__text {
            padding-left: 0;
            padding-right: 9px;
        }

        @include breakpoint(xmedium) {
            flex-direction: row-reverse;
            #{$root}__text {
                padding-left: 0;
                padding-right: 9px;
            }
        }

    }

    &--without-icon {
        #{$root}__text {
            padding-left: 0;
            padding-right: 0;
        }
    }
}