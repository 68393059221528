.c-hubspotform {
	/* Form Title */
	.form-title {
		line-height: 1.2;
		font-family: 'M Ying HeiTM PRC for Viega W3', Helvetica, Arial, sans-serif;
		font-size: 2.25rem;
		font-weight: normal;
		padding-bottom: 3px;
		border-bottom: 1px solid #e1e7e7;
		margin: 0 0 30px 0;
	}
	/* Form Field */
	.hs-form-field {
		margin-top: 20px;
	}

	/* Labels and Legends */
	label {
		font-family: HelveticaNeue65Medium, Helvetica, Arial, sans-serif;
		font-size: 0.8125rem;
		color: #999;
	}
	/* One Line Inputs */
	input[type='text'],
	input[type='password'],
	input[type='datetime'],
	input[type='datetime-local'],
	input[type='date'],
	input[type='month'],
	input[type='time'],
	input[type='week'],
	input[type='number'],
	input[type='email'],
	input[type='url'],
	input[type='search'],
	input[type='tel'],
	input[type='color'],
	input[type='file'],
	textarea,
	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		font-size: 0.8125rem;
		color: #111;
		font-family: HelveticaNeue65Medium, Helvetica, Arial, sans-serif;
		background-color: #f1f4f4;
		border: 1px solid #f1f4f4;
		border-top-color: #d1d7d7;
		border-radius: 5px;
		outline: 0;
		width: 100%;
		display: block;
		min-height: 40px;
		padding: 5px 10px;
		max-width: 500px;
	}

	/* Multiple Selection Inputs */
	form.hs-form .hs-form-field ul.inputs-list {
		list-style: none;
		padding: 0;
	}

	/* Error */
	.hs-error-msgs label {
		color: #cc3d5b;
		font-family: HelveticaNeue65Medium, Helvetica, Arial, sans-serif;
		font-size: 0.8125rem;
	}

	form.hs-form fieldset {
		max-width: none;
	}

	form.hs-form fieldset .hs-form-field {
		padding: 0 10px 0 0;
	}

	form.hs-form fieldset[class*='form-columns'] .input {
		margin-right: 0;
	}

	form.hs-form fieldset[class*='form-columns'] .hs-input {
		width: 100%;
	}

	form.hs-form fieldset[class*='form-columns'] .hs-input[type='checkbox'] {
		width: auto;
	}

	form.hs-form fieldset.form-columns-3 .hs-form-field {
		width: 33.33%;
	}

	@media (max-width: 400px) {
		form.hs-form fieldset .hs-form-field {
			padding: 0;
		}
		form.hs-form fieldset.form-columns-3 .hs-form-field,
		form.hs-form .form-columns-2 .hs-form-field .hs-input,
		form.hs-form .form-columns-3 .hs-form-field .hs-input {
			width: 100%;
		}
		form.hs-form .form-columns-3 .hs-form-field .hs-input[type='checkbox'] {
			width: auto;
		}
	}

	/* =============== Blog Comment Form =============== */

	#hs_form_target_blog_subscription input[type='radio'],
	#hs_form_target_blog_subscription input[type='checkbox'] {
		width: auto;
		margin-right: 10px;
	}

	/* =============== All Global Buttons (Excluding CTAs) =============== */
	.hs-button.primary,
	input[type='submit'],
	input[type='button'] {
		display: inline-block;
		margin: 15px 0;
		cursor: pointer;
		-webkit-appearance: none;
		text-decoration: none !important;
		line-height: 36px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		border-radius: 5px;
		color: #f1f4f4;
		background: #111
			url(//cdn2.hubspot.net/hubfs/3453709/Viega%20September2017%20Theme/Images/download.png)
			center right no-repeat;
		box-shadow: 0 1px 0 #999;
		border: 0;
		outline: 0;
		padding: 2px 40px 2px 25px;
		font-size: 0.875rem;
		font-family: HelveticaNeue75Bold, Helvetica, Arial, sans-serif;
		text-transform: uppercase;
	}

	.hs-button.primary:hover,
	input[type='submit']:hover,
	input[type='button']:hover {
		color: yellow;
		background-image: url(//cdn2.hubspot.net/hubfs/3453709/Viega%20September2017%20Theme/Images/download-hover.png);
	}
}
