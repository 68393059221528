.m-lightsearchoptionsfilter {
	$root: &;

	padding-top: map-get($spacing, small);

	&__filter-label {
		display: none;
		@include breakpoint(large) {
			display: block;
			font-weight: bold;
			font-size: 16px;
			margin-left: 0px;
			margin-bottom: map-get($spacing, xsmall);
		}
	}

	&__accordion {
		.m-accordion__link {
			display: flex;

			@include breakpoint(large) {
				display: none;
			}
		}

		.m-accordion__content {
			display: none;

			@include breakpoint(large) {
				display: block;
				background: none;
			}
		}
	}

	&__option {
		flex-direction: row;
		margin-bottom: map-get($spacing, small);
		margin-right: map-get($spacing, small);
	}

	&__legals-container {
		display: flex;
		justify-content: flex-end;
	}

	&__legal {
		@include xy-gutters;
		font-size: rem-calc(12px);
		display: inline-block;
	}
}
