.o-headerlite {
	$root: &;
	background-color: $secondary-color;
	height: $nav-height--mobile;
	max-width: $global-max-width;
	margin: auto;
	position: relative;
	z-index: z(main-navigation);

	@include breakpoint(large) {
		height: $nav-height;
	}

	&__navigation {
		@include xy-grid-container;
		@include xy-grid;
		margin: auto;
		height: 100%;
		justify-content: space-between;
	}

	&__logo-container {
		transition: $transition-fade;
		width: auto;
		position: relative;
		z-index: z(logo);

		@include breakpoint(large) {
			width: auto;
		}
	}

	&__logo {
		width: 70px;
		height: 60px;
		margin-top: map-get($spacing, xsmall);

		@include breakpoint(large) {
			width: 90px;
			height: 90px;
		}
	}

	&__navigation-list {
		display: flex;
		height: 100%;
		align-items: center;
		list-style-type: none;
		color: $white;
		margin: 0;

		&--link {
			display: none;
		}

		@include breakpoint(large) {
			&--link {
				display: flex;
				flex-grow: 1;
				margin-left: map-get($spacing, xsmall);
			}
		}
	}

	&__navigation-list-item {
		height: 100%;
	}

	&__navigation-link {
		@include use-font(bold);
		text-transform: uppercase;
		line-height: 1rem;
		display: flex;
		align-items: center;
		height: 100%;
		padding: map-get($spacing, small);
		color: $white;

		&:hover {
			color: $primary-color;
		}
	}

	&__button {
		@include use-font(large);
		cursor: pointer;
		color: $white;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		padding: 0 map-get($spacing, xsmall);
		height: 100%;
		transform: translateX(1 * map-get($spacing, xsmall));

		&:hover {
			color: $primary-color;
		}

		&--menu {
			width: 55px;

			@include breakpoint(large) {
				display: none;
			}
		}

		/* Toggle Button Icons */
		&.is-active {
			.open-icon {
				display: none;
			}

			.close-icon {
				display: block;
			}
		}

		.close-icon {
			display: none;
		}
	}

	&__button-label {
		display: none;

		@include breakpoint(large) {
			display: block;
			font-size: rem-calc(12px);
			letter-spacing: 0.4px;
			line-height: 19px;
		}
	}

	&__watchlist {
		$root: &;

		&-icon {
			color: inherit;
		}

		&-icon-container {
			position: relative;
			height: 24px;
		}

		&-counter {
			@include use-font(bold);
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 6px;
			right: -7px;
			height: 20px;
			min-width: 20px;
			margin: 0;
			padding: 0 5px;
			z-index: z(overlays);
			border: 2px solid $secondary-color;
			border-radius: 15px;
			font-size: rem-calc(14px);
			color: $secondary-color;
			line-height: 1;
			background-color: $primary-color;
		}
	}

	&__flyout {
		background: $white;
		height: 0;
		will-change: height;
		position: relative;
		z-index: z(navigation-flyout);
	}

	&__flyout-tab {
		@include xy-grid-container;
		padding: map-get($spacing, medium);
		opacity: 0;
		will-change: opacity;

		&--navigation {
			// Navigation tab has its own spacings for 100% link width
			padding: 0;
		}

		&--watchlist {
			$root: &;
			display: flex;
			flex-flow: row wrap;

			@include breakpoint(large) {
				flex-flow: row;
			}

			&-headline {
				@include use-font(roman);
				font-size: 1.25rem;
				text-transform: uppercase;
			}

			&-intro {
				width: 100%;

				@include breakpoint(large) {
					@include xy-cell(4 of 12, $gutters: rem-calc(60px));
				}

				@include breakpoint(xxlarge) {
					@include xy-cell(4 of 12, $gutters: rem-calc(60px));
				}
			}

			&.is-empty {
				#{$root}-intro,
				#{$root}-items {
					display: none;
				}

				#{$root}-empty {
					display: block;
					padding: map-get($spacing, xmedium) 0;
				}
			}

			&-items {
				padding-bottom: map-get($spacing, xmedium);

				@include breakpoint(large) {
					@include xy-cell(9 of 12);
					@include xy-grid;
					@include xy-grid-layout(
						2,
						'.m-watchlistflyoutteaser',
						$gutters: map-get($spacing, xsmall)
					);
				}

				@include breakpoint(xlarge) {
					@include xy-grid-layout(
						3,
						'.m-watchlistflyoutteaser',
						$gutters: map-get($spacing, xsmall)
					);
				}
			}
			.m-watchlistflyoutteaser {
				background-color: $light-gray-01;
				padding: map-get($spacing, small);
				margin-bottom: map-get($spacing, xsmall);
			}

			&-link {
				overflow: auto; //IE Fix
			}

			&-empty {
				@include xy-cell(12 of 12);
				display: none;
				text-transform: uppercase;
				text-align: center;
			}
		}
	}

	&__flyout-list {
		list-style-type: none;
		margin: 0;
	}

	&__flyout-list-item {
		&:not(:last-of-type) {
			border-bottom: 1px solid $secondary-color;
		}
	}

	&__flyout-link {
		@include use-font(large);
		display: flex;
		padding: map-get($spacing, small) map-get($spacing, small)
			map-get($spacing, small) map-get($spacing, medium);
		align-items: center;
		justify-content: space-between;
	}
}
