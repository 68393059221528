.m-softwaredownload {
	$root: &;

	@include accordion-container;
	width: 100%;

	&__image-container {
		display: none;

		@include breakpoint(medium) {
			display: flex;
			justify-content: center;
			width: 100%;
			height: auto;
			background-color: $accent-color;
			align-items: center;
			max-width: 100px;
		}
	}

	&__image {
		width: 50px;
		height: 50px;
	}

	&__item {
		@include accordion-item;

		&.is-active {
			#{$root}__link {
				background-color: $accent-color;
			}
		}
	}

	&__link {
		@include accordion-title;
		@include use-font(roman);
		padding: 0;
		background-color: $light-gray-01;
		display: flex;

		&:hover {
			background-color: $accent-color;
		}
	}

	&__text-container {
		width: 100%;
		padding: map-get($spacing, small) map-get($grid-margin-gutters, small) /
			2;
		display: flex;
		flex-flow: column;
		justify-content: center;

		@include breakpoint(medium) {
			padding: map-get($spacing, small);
		}
	}

	&__title {
		@include use-font(bold);
		margin-bottom: 0;
	}

	&__text {
		@include use-font(light);
		margin: 0;
	}

	&__headline {
		@include use-font(bold);
		margin-bottom: 5px;
	}

	&__arrow-container {
		display: flex;
		align-items: center;
		padding: map-get($spacing, small);
	}

	> .is-active {
		#{$root}__arrowicon {
			transform: rotate(180deg);
		}
	}

	&__collapsible {
		@include accordion-content;
		padding: map-get($spacing, small) 0;
		background-color: $accent-color;
		border-top: 2px solid $white;
	}

	&__collapsible-header {
		margin-bottom: map-get($spacing, medium);

		@include breakpoint(medium) {
			@include xy-grid;
		}

		@include breakpoint(large) {
			@include xy-grid;
			align-items: center;
			margin-bottom: map-get($spacing, xmedium);
		}
	}

	&__select-wrapper {
		margin-bottom: map-get($spacing, medium);

		@include breakpoint(medium) {
			@include xy-cell(6 of 12);
			margin-bottom: 0;
		}

		@include breakpoint(large) {
			@include xy-grid;
		}
	}

	&__label {
		@include xy-cell(12 of 12);
		font-size: 18px;

		@include breakpoint(medium) {
			@include xy-cell(6 of 6, $gutters: 0);
			font-size: 20px;
		}

		@include breakpoint(large) {
			@include xy-cell(2 of 6, $gutters: 0);
			@include xy-cell-offset(1 of 6, $gutters: 0);
			margin-bottom: 0;
		}
	}

	&__select {
		@include xy-cell(12 of 12);
		margin-bottom: map-get($spacing, xsmall);

		@include breakpoint(medium) {
			@include xy-cell(6 of 6, $gutters: 0);
			margin-bottom: 0;
		}
		@include breakpoint(large) {
			@include xy-cell(3 of 6, $gutters: 0);
			margin-bottom: 0;
		}

		.a-select__input {
			margin-bottom: 0;
		}
	}

	&__button {
		margin: 0 $grid-gutter/2;

		@include breakpoint(medium) {
			align-self: flex-end;
		}

		@include breakpoint(large) {
			@include xy-cell-offset(1.5 of 12, $gutters: 0);
		}
	}

	&__section {
		display: none;

		&.is-active {
			@include xy-grid;
		}

		&--smallmargin {
			padding: 0 map-get($spacing, medium) map-get($spacing, xsmall);
		}
	}

	&__column {
		@include xy-cell(12 of 12);

		@include breakpoint(medium) {
			@include xy-cell(6 of 12);
		}

		@include breakpoint(large) {
			@include xy-cell(4.5 of 12);

			&:first-of-type {
				@include xy-cell-offset(3 of 12, $gutters: 0);
			}
		}

		> #{$root}__headline {
			margin-bottom: map-get($spacing, small);
		}
	}

	&__download-link {
		display: inline-flex;
	}

	&__download-list {
		margin-bottom: map-get($spacing, medium);

		&--1 {
			list-style: none;
			margin-left: 0;

			a {
				text-decoration: underline;
			}

			#{$root}__headline {
				color: $light-gray;
			}
		}
	}

	&__download-list {
		margin-left: 0;
	}

	&__download-item {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 10px;
	}

	&__download-text {
		flex-basis: 340px;
	}

	&__download-controls {
		flex-shrink: 0;
		flex-grow: 1;
		display: flex;
		justify-content: center;
		margin-left: 10px;
	}

	&__watchlist-icon {
		margin-right: 10px;
	}

	&__richtext {
		margin-bottom: map-get($spacing, small);

		ul {
			margin-bottom: map-get($spacing, small);
		}
	}
}
