.o-footer {
	background: $secondary-color;
	color: $light-gray;
	max-width: 1920px;
	margin: 0 auto;
	width: 100%;

	a {
		@include use-font(roman);
		font-size: $global-font-size;
		color: $light-gray;

		&:hover {
			color: $light-gray;
		}
	}

	h3 {
		@include use-font(bold);
		font-size: $global-font-size;
		text-transform: uppercase;
		line-height: 1.6;
	}

	h4 {
		@include use-font(bold);
		font-size: $global-font-size;
		line-height: 1.6;
	}

	&__container {
		@include xy-grid-container;
		padding-top: map-get($spacing, xmedium);
		padding-bottom: map-get($spacing, xmedium);
		position: relative;
	}

	&__inner {
		@include xy-grid;

		@include breakpoint(large) {
			margin-bottom: map-get($spacing, small);
		}
	}

	&__back-to-top {
		position: absolute;
		top: map-get($spacing, medium);
		right: map-get($spacing, xmedium);
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		background: $white;
		border-radius: 4px;
		padding: 7px;
		transition: transform 300ms ease-out;

		.a-svgicon {
			use {
				color: $secondary-color;
			}
		}

		@include breakpoint(xmedium down) {
			right: map-get($spacing, small);
		}

		&:hover {
			transform: scale(1.2);
		}
	}

	&__link-list {
		display: none;
		@include xy-cell(12 of 12, $gutters: 0);
		margin-bottom: map-get($spacing, xmedium);

		@include breakpoint(medium) {
			@include xy-grid;
			@include xy-grid-layout(3, '.m-linklist');
		}

		@include breakpoint(large) {
			@include xy-cell(7, $gutters: 0);
			margin-bottom: 0;
		}
	}

	&__service-contacts {
		@include xy-cell(12 of 12, $gutters: 0);

		@include breakpoint(large) {
			@include xy-cell(5, $gutters: 0);
		}
	}

	&__service-headline {
		@include xy-gutters;
		margin-bottom: map-get($spacing, xsmall);
	}

	&__contact-references {
		@include xy-grid;
		@include xy-grid-layout(1, '.o-footer__reference');
		list-style-type: none;
		margin-left: 0;

		@include breakpoint(medium) {
			@include xy-grid-layout(
				2,
				'.o-footer__reference',
				$breakpoint: medium
			);
		}
	}

	&__reference {
		margin-bottom: map-get($spacing, xmedium);

		@include breakpoint(large) {
			margin-bottom: 0;
		}
	}

	&__vcard {
		.m-vcard__icon {
			color: $white;
		}
	}

	&__legal-social-container {
		@include xy-cell(12 of 12);
		display: flex;
		flex-flow: column;

		> :first-child {
			order: 2;
		}

		> :last-child {
			order: 1;
			margin-bottom: map-get($spacing, xmedium);
		}

		.o-footer__social {
			@include breakpoint(xmedium) {
				justify-content: flex-end;
			}
		}
	}

	&__social {
		svg,
		use {
			color: $white;
			fill: $white;
		}
	}

	.m-vcard__link-text,
	.a-link {
		box-shadow: none;
		transition: color 300ms ease-out;

		&:hover,
		&:active {
			box-shadow: none !important;
			color: $white;
		}
	}
}
