.o-roadshow {
	$root: &;
	$custom-gap: 5px;
	@include xy-grid-container;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto;
	grid-template-areas: 'map' 'schedule';
	gap: map-get($spacing, small);
	padding: 0;

	@include breakpoint(xmedium) {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: auto;
		grid-template-areas: 'map map' 'schedule schedule';
	}

	@include breakpoint(large) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: auto;
		grid-template-areas: 'map map schedule';
	}

	&__map {
		grid-area: map;
	}

	.m-placeholderimage {
		grid-area: map;
		min-height: 400px;

		@include breakpoint(xmedium) {
			min-height: 500px;
		}
	}

	&__schedule {
		@extend %scrollbar;
		grid-area: schedule;
		list-style: none;
		margin: 0;

		@include breakpoint(xmedium only) {
			max-height: 100vh;
			overflow-y: auto;
		}

		@include breakpoint(large) {
			max-height: 580px;
			overflow-y: auto;
		}
	}

	&__head {
		background-color: $secondary-color;
		color: $white;
		padding: map-get($spacing, xsmall) map-get($spacing, small);
		margin-bottom: $custom-gap;
		position: sticky;
		z-index: 1;
		top: 0;
	}

	&__list {
		list-style: none;
		margin: 0;

		@include breakpoint(xmedium only) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: auto;
			gap: 0 $custom-gap;
		}

		&-item {
			background-color: $light-gray-01;
			transition: background-color $expo-tween-t1-time $expo-tween-t1;
			margin-bottom: $custom-gap;
			align-self: start;

			&.is-active {
				background-color: $accent-color;
			}
		}
	}

	&__event {
		display: block;
		padding: map-get($spacing, small) map-get($spacing, large)
			map-get($spacing, small) map-get($spacing, small);
		position: relative;

		@include breakpoint(medium) {
			display: flex;
			flex-flow: row nowrap;
		}

		&-date {
			margin: 0 map-get($spacing, small) map-get($spacing, xsmall) 0;

			@include breakpoint(medium) {
				text-align: center;
			}

			&-weekday {
				@include use-font(light);
				text-transform: uppercase;
				margin-bottom: 0;
			}

			&-day {
				@include use-font(bold);
				font-size: 36px;
				line-height: 1;
				margin-bottom: 0;
			}
		}

		&-data {
			flex: 1;

			&-location {
				font-style: normal;
			}

			&-city {
				@include use-font(bold);
				font-style: normal;
			}

			&-headline {
				margin-bottom: 0;
			}

			&-street {
				display: inline-block;
				font-style: normal;

				&:not(:last-child) {
					&::after {
						content: '|';
						display: inline-block;
						margin: 0 5px;
					}
				}
			}

			&-timespan {
				display: inline-block;
			}
		}

		&-arrow {
			position: absolute;
			top: calc(#{map-get($spacing, small)} + 30px);
			right: map-get($spacing, small);
			transform: rotate(90deg);
			transition: background-color $expo-tween-t1-time $expo-tween-t1,
				transform 300ms ease-out;
			margin-left: map-get($spacing, xsmall);

			#{$root}__event:hover & {
				transform: scale3d(1.2, 1.2, 1.2) rotate(90deg);
			}

			.is-active & {
				transform: scale3d(1, 1, 1) rotate(-90deg);
			}

			.is-active #{$root}__event:hover & {
				transform: scale3d(1.2, 1.2, 1.2) rotate(-90deg);
			}
		}

		&-content {
			display: none;
			padding: 0 map-get($spacing, small) map-get($spacing, xsmall);
		}
	}
}
