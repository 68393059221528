/**
* calculate Highlighted Background
*/
@mixin highlight-background($pos-left: 0%, $pos-right: 0%) {
	$left-padding: $pos-left / 1%; /* strip %-units */
	$right-padding: $pos-right / 1%; /* strip %-units */
	$total-width: 100 - ($left-padding + $right-padding); /* total width of content */
	left: auto /* reset left from mobile */;
	right: calc(
		(((100% * (#{$right-padding} * -1)) + ((100% * 50))) / #{$total-width}) -
			50vw
	);
	width: 100vw;
}

/**
 * used for stroke-width-animation of svgs
 */
@mixin svg-stroke($stroke-width: 12px) {
	&,
	use {
		stroke-width: $stroke-width !important;
	}
}

/**
 * used for scale of svgs
 */
@mixin svg-scale($scale-factor: 1.2) {
	.a-svgicon {
		transform: scale3d($scale-factor, $scale-factor, $scale-factor);
	}
}

/**
 * Heading styles
 */
@each $size, $headers in $header-styles {
	@include breakpoint($size) {
		@each $header, $header-defs in $headers {
			#{$header},
			.#{$header} {
				@each $key, $value in $header-defs {
					//exclude props which will be generated by foundation
					@if $key !=
						font-size and
						$key !=
						line-height and
						$key !=
						margin-top and
						$key !=
						margin-bottom
					{
						#{$key}: #{$value};
					}
				}
			}

			.a-headline--#{$header} {
				@each $key, $value in $header-defs {
					@if $key == font-size {
						#{$key}: rem-calc($value);
					} @else {
						#{$key}: #{$value};
					}
				}
			}
		}
	}
}

/**
 * Yellow bar hover / focus animation
 */
@mixin yellow-bar-hover($initial-border: false, $triggerSelectors: '') {
	transition: box-shadow 300ms ease-out, color 300ms ease-out;

	@if $initial-border {
		box-shadow: inset 0 -2px 0 0 $primary-color;
	}

	&:hover,
	&:active,
	&:focus,
	#{$triggerSelectors} {
		&:not(:disabled) {
			box-shadow: inset 0 -5px 0 0 $primary-color !important;
			text-decoration: none;
		}
	}
}

/**
 * Blue bar hover / focus animation
 */
@mixin light-grey-bar-hover($initial-border: false, $triggerSelectors: '') {
	transition: box-shadow 300ms ease-out, color 300ms ease-out;

	@if $initial-border {
		box-shadow: inset 0 -2px 0 0 $light-gray;
	}

	&:hover,
	&:active,
	&:focus,
	#{$triggerSelectors} {
		&:not(:disabled) {
			box-shadow: inset 0 -5px 0 0 $light-gray !important;
			text-decoration: none;
		}
	}
}

/**
 *
 */
@mixin slider-dots($animation: false, $animation-duration: 5000) {
	position: absolute;
	margin: 0;
	display: flex;
	width: 100%;
	justify-content: center;
	z-index: 1;
	bottom: 0;
	list-style-type: none;

	li {
		cursor: pointer;

		&:hover {
			button {
				&::before,
				&::after {
					height: 5px;
					width: 100%;
				}
			}
		}

		&.slick-active button {
			&::after {
				width: 100%;

				@if $animation {
					// same as slide animation duration that is set in js
					animation: expand $animation-duration + ms linear forwards;
				}
			}
		}
		@if $animation {
			&.is-paused button {
				&::after {
					@include breakpoint(xmedium) {
						animation-play-state: paused;
					}
				}
			}

			&.reset-animation button {
				&::after {
					@include breakpoint(xmedium) {
						animation: none;
					}
				}
			}
		}

		button {
			height: $slider-nav-height;
			width: 30px;
			margin: 0 map-get($spacing, xsmall);
			color: transparent;
			position: relative;
			cursor: pointer;

			@include breakpoint(xmedium) {
				height: $slider-nav-height;
				width: 60px;
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				height: 3px;
				border-radius: 5px;
				transition: height 300ms $expo-tween-t1;
			}

			&::before {
				width: 100%;
				background-color: lighten($black, 60%);

				@include breakpoint(xmedium) {
					background: $white;
					box-shadow: 0 0 5px rgba($black, 0.1);
				}
			}

			&::after {
				width: 0%;
				background-color: $black;

				@include breakpoint(xmedium) {
					background-color: $primary-color;
				}
			}
		}
	}
}

@mixin ie11Check {
	@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}
