.m-checklist {
	margin: 0;

	&__item {
		@include use-font(bold);
		display: flex;
		align-items: flex-start;
		margin-bottom: map-get($spacing, small);
	}

	&__icon.a-svgicon {
		width: 25px;
		height: 25px;
		margin-right: map-get($spacing, small);
		flex-shrink: 0;
	}
}
