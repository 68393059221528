.m-searchinput {
	$root: &;

	position: relative;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	&:not(&--header) {
		#{$root}__input[type='search'] {
			@include yellow-bar-hover;

			&:focus {
				z-index: z(search-input);
			}
		}
	}

	&__container {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	&__input[type='search'] {
		-webkit-appearance: none;
		border-radius: 4px;
		margin-bottom: 0;
		margin-right: 10px;

		.m-searchinput.m-mainnav__search.m-searchinput--header
			> .m-searchinput__container
			> &::-webkit-search-cancel-button {
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(3deg)
				brightness(100%) contrast(104%);
		}
	}

	&__search-loading {
		height: 80vh;
		overflow: hidden;
		background-color: $white;
		padding: map-get($spacing, xmedium) map-get($spacing, small);
	}

	&--header {
		height: 100%;

		#{$root}__input[type='search'] {
			@include use-font(light);
			border: none;
			border-radius: 0;
			background-color: $dark-gray;
			box-shadow: none;
			width: 100%;
			font-size: rem-calc(18px);
			text-align: right;
			color: $white;
			margin: 0 map-get($spacing, 'xsmall') 0 0;
			padding: 0;
			overflow: hidden;

			&::placeholder {
				@include use-font(light);
				padding: 0;
				color: $light-gray;
				transform-origin: right center;
				transition: all 300ms ease-out;
			}

			&::-ms-clear {
				margin-left: map-get($spacing, 'xsmall');
			}

			&:hover,
			&:focus {
				& + #{$root}__search-submit {
					color: $primary-color;
				}
			}

			&:focus {
				caret-color: $primary-color;

				&::placeholder {
					transform: scaleX(0);
					opacity: 0;
				}
			}
		}

		#{$root}__search-submit {
			color: $white;
			cursor: pointer;
			border-radius: 0;
			transition: color 300ms ease-out;

			&:hover {
				color: $primary-color;
			}
		}

		#{$root}__search-icon {
			color: inherit;
		}

		.autocomplete-suggestions {
			height: calc(
				100vh - 270px
			); // minus doubled the margin of the bottom line of the input to the top
		}
	}

	.autocomplete-suggestions {
		height: 0;
		overflow-y: auto;
		background-color: $white;
		transition: height 200ms ease-out;

		&:not(.is-hidden) {
			display: block !important;
		}

		@include breakpoint(xmedium) {
			min-width: 450px;
		}

		.category-title {
			@include use-font(roman);
			text-transform: none;
			border-bottom: 1px solid $color-borders;
			margin: map-get($spacing, xmedium) 0 0 0;
			padding: 0 map-get($spacing, small) map-get($spacing, xsmall)
				map-get($spacing, small);

			&:first-of-type {
				display: none;
			}
		}
	}

	&__suggestions {
		position: absolute;
		z-index: z(search-input);
		width: 100%;
		right: 0;
		top: 63px;

		&::before {
			@include css-triangle(10px, $dark-gray, down);

			position: absolute;
			top: -1px;
			right: map-get($spacing, xlarge);
			z-index: 10000; // because .autocomplete-suggestion has z-index: 9999
			transform: scale(0);
			transition: transform $expo-tween-t1-time $expo-tween-t1;
			transform-origin: top center;
		}

		&.is-open {
			.autocomplete-suggestions {
				height: 80vh;
			}

			.m-mainnav &::before {
				transform: scale(1);
			}
		}
	}

	.autocomplete-suggestion {
		cursor: pointer;
		transition: background-color 300ms ease-out;

		&.autocomplete-selected {
			background-color: $accent-color;
		}
	}

	&__no-suggestion,
	&__suggestion {
		@include use-font(roman);
		padding: map-get($spacing, xsmall) map-get($spacing, small);
	}

	&__result {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: map-get($spacing, small);
	}

	&__result-image {
		display: none;

		@include breakpoint(medium) {
			@include object-fit(contain);
			display: block;
			min-width: 120px;
			max-width: 150px;
			max-height: 120px;
			margin-left: map-get($spacing, medium);
		}
	}

	&__result-title {
		@include use-font(roman);
	}

	&__result-copy {
		@include use-font(light);
	}
}
