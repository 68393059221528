.a-svgicon {
	$root: &;
	width: 24px;
	height: 24px;
	transform: scale3d(1, 1, 1);
	transition: transform 300ms ease-out;

	// please note cascading styles
	&,
	use {
		color: inherit;
		stroke-width: 8px;
	}

	&--xsmall {
		width: 12px;
		height: 12px;
	}

	&--small {
		width: 16px;
		height: 16px;

		&,
		use {
			stroke-width: 9px;
		}
	}

	&--large {
		width: 32px;
		height: 32px;
	}

	&--xlarge {
		width: 48px;
		height: 48px;
	}
}
