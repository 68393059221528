.m-teasersmall {
	$root: &;

	@include xy-grid;

	background-color: $light-gray-01;

	&__image-container {
		@include xy-cell(8 of 12, $gutters: 0);
		position: relative;
		display: block;
		border: 1px solid $light-gray-01;
		background: $accent-color;
		min-height: 200px;
		max-height: calc(30vh - #{$header-height});
		overflow: hidden;
		margin: map-get($spacing, small) auto 0;

		&--light {
			background-color: $white;
		}

		@include breakpoint(medium) {
			@include xy-cell(4 of 12, $gutters: 0);
			max-height: calc(90vh - #{$header-height});
			margin-top: 0;
		}
	}

	&__image {
		@include object-fit(cover);
		height: 100%;

		@include breakpoint(xmedium) {
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__headline,
	&__subline {
		margin-bottom: 0 !important;
		hyphens: auto;
	}

	&__stickertag {
		@include use-font(roman);
		display: inline-block;
		padding: 1px 4px;
		border-radius: 3px;
		font-size: rem-calc(14px);
		line-height: rem-calc(24px);
		background: $white;
		color: $black;
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.25);
		margin-top: map-get($spacing, xsmall);
	}

	&__videosvg-container {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 50%;
		width: 72px;
		height: 72px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);

		@include breakpoint(medium) {
			width: 100px;
			height: 100px;
		}
	}

	&__videosvg {
		color: $white;

		use {
			stroke-width: 6px;
		}
	}

	&__new-flag {
		position: absolute;
		top: 60%;
		z-index: 1;

		&--background {
			display: flex;
			background-color: $primary-color;
		}

		&--text {
			@include use-font(roman);
			font-size: 1.5rem;
			text-transform: uppercase;
			padding: 0 map-get($spacing, xsmall);
		}
	}

	&__text-container {
		@include xy-cell(auto, $gutters: 0);

		padding: map-get($spacing, small);
		flex: 1 1 35%;
	}

	&__icon-container {
		@include xy-cell(shrink, $gutters: 0);

		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
		align-items: flex-end;
		padding: map-get($spacing, small) map-get($spacing, small)
			map-get($spacing, small) 0;
	}

	&--no-image {
		#{$root}__image-container {
			@include breakpoint(large) {
				display: none;
			}
		}

		#{$root}__text-container {
			flex: 1 1 35%;
		}

		#{$root}__icon-container {
			flex: 0 0 auto;
		}
	}

	&__text {
		margin-top: map-get($spacing, small);
	}

	&:hover {
		color: inherit;

		#{$root}__image {
			transform: scale(1.1);
		}
	}
}
