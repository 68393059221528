.o-servicetechnologyorderform {
	$root: &;

	&__dataprivacy-link {
		@include use-font(bold);
	}

	&__position {
		position: relative;

		&-label {
			&::after {
				@include use-font(bold);
				content: counter(section);
				height: 43px;
				padding: 0 0 0 5px;
				color: $black;
				display: inline-block;
				@include breakpoint(large) {
					padding: 10px 0;
					display: block;
				}
			}
		}

		@include xy-cell(12 of 12);

		@include breakpoint(medium) {
			@include xy-cell(6 of 24);
		}

		@include breakpoint(large) {
			@include xy-cell(3 of 24);
		}
	}

	&__count {
		@include xy-cell(12 of 12);

		@include breakpoint(medium) {
			@include xy-cell(8 of 24);
		}

		@include breakpoint(large) {
			@include xy-cell(4 of 24);
		}
	}

	&__service,
	&__product,
	&__serial {
		@include xy-cell(12 of 12);

		@include breakpoint(medium) {
			@include xy-cell(10 of 24);
		}

		@include breakpoint(large) {
			@include xy-cell(5 of 24);
		}
	}

	&__delete {
		@include xy-cell(12 of 12);

		display: flex;
		align-items: flex-start;

		@include breakpoint(medium) {
			@include xy-cell(4 of 24);
		}

		@include breakpoint(large) {
			@include xy-cell(2 of 24);
		}
	}

	&__additional-registrations-list {
		counter-reset: section;

		#{$root}__position-label {
			counter-increment: section;
		}

		&.is-active {
			#{$root}__delete-registration {
				display: block;
			}
		}

		#{$root}__additional-registrations {
			margin-bottom: 10px;

			@include breakpoint(large) {
				margin-bottom: 0;
			}

			+ #{$root}__additional-registrations {
				margin-top: 35px;

				@include breakpoint(large) {
					margin-top: 0;
				}
			}
		}
	}

	&__delete-icon {
		margin: 10px;
		display: block;

		@include breakpoint(medium) {
			margin: 13px;
		}
	}

	&__delete-registration {
		position: relative;
		top: 5px;
		display: none;

		@include breakpoint(medium) {
			top: 30px;
		}
	}

	.g-form__footer {
		margin-top: 20px;
	}
}
