.m-teasermedium {
	$root: &;

	@include xy-grid(vertical);

	background-color: $light-gray-01;

	&__image-container {
		@include xy-cell(12 of 12, $gutters: 0);
		position: relative;
		border: 1px solid $light-gray-01;
		display: flex;
		background: $white;
		justify-content: center;
		align-content: center;
		overflow: hidden;
	}

	&__image {
		@include object-fit(cover);
		width: 100%;
		height: 100%;
		max-height: calc(90vh - #{$header-height});

		@include breakpoint(xmedium) {
			max-height: calc(60vh - #{$header-height});
		}
	}

	&__headline {
		hyphens: auto;
	}

	&__videosvg-container {
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		background-color: rgba(0, 0, 0, 0.7);
		border-radius: 50%;
		width: 72px;
		height: 72px;
		position: absolute;
		left: 50%;
		top: 55%;
		transform: translate(-50%, -50%);

		@include breakpoint(medium) {
			width: 96px;
			height: 96px;
		}

		@include breakpoint(large) {
			width: 120px;
			height: 120px;
		}
	}

	&__videosvg {
		color: $white;

		use {
			stroke-width: 6px;
		}
	}

	&__content {
		display: flex;
		flex-flow: row nowrap;
		flex-grow: 1;
		width: 100%; // IE11 fix
	}

	&__text-container {
		@include xy-cell(auto, $gutters: 0);
		padding: map-get($spacing, small);
	}

	&__icon-container {
		@include xy-cell(shrink, $gutters: 0);

		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
		align-items: flex-end;
		padding: map-get($spacing, small) map-get($spacing, small)
			map-get($spacing, small) 0;
	}

	&__subline {
		@include use-font(roman);
		hyphens: auto;
	}

	&:hover {
		color: inherit;

		#{$root}__image {
			transform: scale(1.1);
		}
	}

	&--centered {
		pointer-events: none;
		cursor: default;
		background: none;

		#{$root}__image-container {
			display: flex;
			justify-content: center;
			border: none;
			padding: 0;
		}

		#{$root}__image {
			width: 150px;
			height: auto;
			border-radius: 50%;
		}

		#{$root}__text-container {
			display: flex;
			flex-flow: column;
			align-items: center;
		}

		#{$root}__text {
			@include use-font(roman);
			text-align: center;
			width: 100%;
		}

		#{$root}__headline {
			@include use-font(bold);
		}
	}
}
