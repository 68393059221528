.c-headline {
	$root: &;

	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;

	&--h1 {
		border-bottom: 1px solid $color-borders;
		margin-bottom: map-get($spacing, small);
		padding-bottom: map-get($spacing, xsmall);

		#{$root}__headline.a-headline--h1 {
			margin-bottom: 0;
			padding-bottom: 0;
			border: none;
			width: 100%;
		}
	}
}
