// These styles are applied to a <meta> tag, which is read by the State-Class JavaScript
.css-variables {
	font-family: '#{map-serialize($cssStateMap)}';
	--breakpoints: '#{map-serialize($breakpoints)}';
	--current-breakpoint: null;
	@each $key, $value in $breakpoints {
		--breakpoint--#{$key}: #{$value};
	}

	@include breakpoint(small) {
		--current-breakpoint: #{screenSize(small)};
	}

	@include breakpoint(medium) {
		--current-breakpoint: #{screenSize(medium)};
	}

	@include breakpoint(large) {
		--current-breakpoint: #{screenSize(large)};
	}

	@include breakpoint(xlarge) {
		--current-breakpoint: #{screenSize(xlarge)};
	}
}

.list.no-margin {
	margin-bottom: 0;
}

// global tag stylings
body {
	overflow-x: hidden;
	letter-spacing: 0.025rem;

	::selection {
		background-color: $primary-color;
	}

	&.is-active-shader {
		height: 100vh;

		@media #{$mobile-landscape} {
			height: auto;
			overflow: scroll;
		}
	}
}

main {
	background-color: $white;
	display: block;
	border-bottom: 1px solid transparent;
	transition: filter $expo-tween-t1-time $expo-tween-t1 200ms;

	&.is-blurry {
		filter: blur(20px);
		transition: filter $expo-tween-t1-time $expo-tween-t1;
	}
}

footer {
	transition: filter $expo-tween-t1-time $expo-tween-t1 200ms;

	&.is-blurry {
		filter: blur(20px);
		transition: filter $expo-tween-t1-time $expo-tween-t1;
	}
}

em,
i {
	@include use-font(lt-it);
	font-weight: normal;
	font-style: normal;
}

strong,
b {
	@include use-font(bold);
	font-weight: normal;
	font-style: normal;
}

%table {
	@include table(even);
	@include table-scroll;
	@include table-hover;
	margin-bottom: 0;

	tr {
		transition: background-color 0.6s $expo-tween-t1;

		// revert specific styles for the case table has no thead
		&:nth-child(even) {
			background-color: $table-background;

			&.is-even {
				background-color: $table-background;
			}

			&.is-odd {
				background-color: $table-striped-background;
			}
		}

		&:nth-child(odd) {
			background-color: $table-striped-background;

			&.is-even {
				background-color: $table-background;
			}

			&.is-odd {
				background-color: $table-striped-background;
			}
		}
	}

	thead + tr,
	thead + tbody tr {
		// if table has no thead, it is supposed to start with dark bg instead of white.
		&:nth-child(even) {
			background-color: $table-striped-background;

			&.is-even {
				background-color: $table-background;
			}

			&.is-odd {
				background-color: $table-striped-background;
			}
		}

		&:nth-child(odd) {
			background-color: $table-background;

			&:hover {
				background-color: $table-row-hover;
			}

			&.is-even {
				background-color: $table-background;
			}

			&.is-odd {
				background-color: $table-striped-background;
			}
		}
	}

	tr:not(.m-table__head-row--fixed) {
		&::after {
			content: '';
			display: table-cell;
			vertical-align: middle;
			width: 0;
			height: rem-calc(60px);
		}
	}

	th {
		@include use-font(roman);
		background-color: $table-head-background;
		color: $table-head-font-color;
		box-shadow: 0 1px 0 0 $light-gray;
		padding: 1.25rem 0 1.25rem 1.25rem;

		&:last-child {
			padding-right: map-get($spacing, small);
		}
	}

	td {
		&:last-child {
			padding-right: map-get($spacing, small);
		}
	}
}

%scrollbar {
	$scrollbar-width: 6px;

	&::-webkit-scrollbar {
		width: #{map-get($spacing, xsmall) + $scrollbar-width};
	}

	&::-webkit-scrollbar-track {
		background-image: linear-gradient(
			to left,
			transparent 0,
			transparent 2px,
			$light-gray 2px,
			$light-gray 4px,
			transparent 4px
		);
	}

	&::-webkit-scrollbar-thumb {
		background-image: linear-gradient(
			to left,
			$secondary-color 0,
			$secondary-color #{$scrollbar-width},
			transparent #{$scrollbar-width}
		);
	}
}

a,
area,
input,
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
select,
textarea,
button,
iframe,
[tabindex],
[contentEditable='true'] {
	&:focus {
		outline: none;
	}
}

a:hover,
button:hover {
	@include svg-scale;
}

input[type='search'] {
	&::-webkit-search-cancel-button {
		-webkit-appearance: none;
		height: 16px !important;
		width: 16px !important;
		background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgMjAwIDIwMCI+PHRpdGxlPjIwMDQwMV92aWVnYV9JY29uLVNldF9jb21wbGV0ZTwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzMiIGRhdGEtbmFtZT0iTGF5ZXIgMyI+PGxpbmUgeDE9IjE5My43MiIgeTE9IjUuNDMiIHgyPSI0LjgzIiB5Mj0iMTk0LjMyIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMTExO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4Ii8+PGxpbmUgeDE9IjE5My43MiIgeTE9IjE5NC4zMiIgeDI9IjQuODMiIHkyPSI1LjQzIiBzdHlsZT0iZmlsbDpub25lO3N0cm9rZTojMTExO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4Ii8+PC9nPjwvZz48L3N2Zz4=);
		margin-left: map-get($spacing, 'xsmall') !important;
		cursor: pointer;
	}
}
