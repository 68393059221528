.o-keycloakbaseform {

	/*ToDo: Ask SYZYGY for correct styles */
	&__error {
		color: $form-label-color-invalid;
	}
	&__success{
		color: $helptext-color;
		font-size: $helptext-font-size;
		font-style: $helptext-font-style;
	}
}
