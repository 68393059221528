.a-actionbutton {
	$root: &;
	cursor: pointer;

	&:focus,
	&:active {
		#{$root}__icon {
			animation: elastic-bounce 300ms ease-out;
		}
	}

	&__icon {
		&--active {
			display: none;
		}
	}

	&.is-active {
		#{$root}__icon {
			&--active {
				display: block;
			}

			&--inactive {
				display: none;
			}
		}
	}
}

@keyframes elastic-bounce {
	50% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1.2);
	}
}
