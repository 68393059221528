.g-formrow {
	@include xy-grid;

	$formrow-cols: 4 6 8 12;

	min-height: 43px;

	&--tall {
		padding-top: 10px;
	}

	> a {
		align-self: flex-start;
	}

	@each $col in $formrow-cols {
		&--col-#{$col} {
			@include xy-cell(12 of 12);

			@include breakpoint(medium) {
				@include xy-cell($col of 12);
			}
		}
	}
}

label {
	@include use-font(bold);

	.is-inactive & {
		opacity: $input-opacity-disabled;
	}
}

form {
	fieldset {
		margin: $fieldset-margin;

		legend {
			@include use-font(bold);
			margin: 0;
			color: $form-label-color;
			font-size: $form-label-font-size;
			line-height: $form-label-line-height;
		}
	}
}

.g-form {
	$root: &;

	&__section {
		@include breakpoint(medium) {
			grid-area: section1;

			+ #{$root}__section {
				grid-area: section2;

				-ms-grid-column: 2;
				-ms-grid-row: 2;
			}
		}
	}

	&__divider {
		grid-area: header;

		.a-divider {
			margin-bottom: 20px;
		}
	}

	&row {
		grid-area: row;

		.a-checkbox {
			margin-top: map-get($spacing, small);
			margin-bottom: map-get($spacing, small);
		}
	}

	&__block {
		display: grid;
		grid-template-rows: auto;
		grid-template-columns: minmax(
			120px,
			1fr
		); // prevent grid from growing over 100% width with minmax
		grid-template-areas: 'header' 'section1' 'section2' 'row' 'footer';

		&:not(.hide) {
			+ #{$root}__block {
				margin-top: 60px;

				&--nomargintop {
					margin-top: 0px;
				}
			}
		}

		&--nomargintop:not(.hide) {
			margin-top: 0px;
		}

		#{$root}__cta-divider {
			margin-bottom: map-get($spacing, xsmall);
		}

		@include breakpoint(medium) {
			grid-template-columns: repeat(2, 1fr);
			grid-template-areas: 'header header' 'section1 section2' 'row row' 'footer footer';
		}
	}

	&__footer {
		margin-top: 30px;
		grid-area: footer;
	}

	&__error-message,
	&__error-code {
		display: none;
		color: $alert-color;
		margin-bottom: map-get($spacing, small);
		font-size: 13px;
	}

	&__error-message {
		.has-error & {
			display: block;
		}
	}

	&__legend {
		&.has-error {
			color: $alert-color;
		}
	}

	&__error-jumper {
		cursor: pointer;
		display: none;
		padding: 10px 15px;
		background-color: $alert-bg-color;
		color: $alert-color;
		font-size: 13px;
		margin-bottom: map-get($spacing, small);

		&.is-active {
			display: block;
		}

		&.no-pointer {
			cursor: default;
		}
	}

	&__success-message {
		display: none;
		padding: 10px 15px;
		background-color: $success-bg-color;
		color: $success-color;
		font-size: 13px;
		margin-bottom: map-get($spacing, small);

		&.is-active {
			display: block;
		}

		&.no-pointer {
			cursor: default;
		}
	}


	&__link {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		width: 100%;
		margin-bottom: map-get($spacing, medium);

		.a-svgicon--viega_arrow {
			width: 15px;
			height: 15px;
			margin-left: map-get($spacing, xsmall);
		}
	}

	&__cta {
		justify-content: space-between;
		align-items: flex-start;
	}

	&__registration-button {
		justify-content: flex-start;
		@include breakpoint(medium) {
			justify-content: flex-end;
		}
	}
}

input,
textarea,
select {
	&:disabled,
	.is-inactive & {
		opacity: $input-opacity-disabled;
		pointer-events: none;
		box-shadow: inset 0 1px 0 0 rgba(240, 240, 240, 1);
		color: #cfcfcf;

		::placeholder {
			color: #cfcfcf;
		}
	}
}

select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	height: auto;
	box-shadow: $input-shadow;
	border-radius: $input-radius;
}
