.m-interactiveimages {
    $root: &;
    hyphens: auto;

    &__grid {
        @include xy-grid-container(100%, 0);

        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: minmax(0, 200px);
        grid-gap: $grid-gutter;
        width: 100%;

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, minmax(0, 450px));
            grid-template-rows: auto;
            grid-auto-rows: auto;
            grid-gap: $grid-gutter;
        }

        @include breakpoint(large) {
            grid-template-columns: repeat(4, minmax(0, 450px));
            grid-template-rows: auto;
            grid-gap: $grid-gutter;
        }

        &__container {
            position: relative;
            $container: &;

            &__image {
                height: 100%;
                object-fit: cover;
            }

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.3);
                top: 0;
                left: 0;
                z-index: 1;
                position: absolute;
            }

            &__label {
                padding: 24px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                font-size: 30px;
                line-height: 36px;
                width: 100%;
                text-align: center;
                z-index: 1;
            }

            &__info {
                position: absolute;
                right: 8px;
                bottom: 8px;
                color: $white;
                z-index: 1;


                @include breakpoint(large) {
                    display: none;
                }
            }

            &__background {
                padding: 24px;
                background: $accent-color;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                transition: opacity .6s $expo-tween-t1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                z-index: 1;

				.a-link {
					margin-top: map-get($spacing, small);
				}
            }

            &:hover, &:focus {
                #{$container}__background {
                    opacity: 1;
                }
            }
        }

    }

    &__toggle {
        cursor: pointer;
        padding: 20px 0;
        float: right;
        display: flex;
        align-items: center;

        span {
            pointer-events: none;
        }

        .a-svgicon {
            width: 21px;
            height: 21px;
            margin-left: 5px;
            border-radius: 50%;
            padding: 5px;
            background: $primary-color;
            pointer-events: none;
        }
    }
}
