.m-mediagallery {
    $root: &;
    position: relative;
    &__headline {
        line-height: 36px;
        margin-bottom: 50px;
        @include breakpoint(xmedium) {
            margin-bottom: 80px;
            line-height: 1.3;
        }
    }

    &__gallery {
        .slick-list {
            margin: 0 -15px;

            @include breakpoint(large) {
                margin: 0 42px;
            }

            .slick-track {
                display:flex;
            }
            .slick-slide {
                display: flex;
                height: auto;
                margin: 0 15px;

                @include breakpoint(large) {
                    margin: 0 15px;
                }

                > div {
                    width: 100%;

                    @include breakpoint(large) {
                        width: auto;
                    }
                }
            }
        }


        &__slide {
            display: flex !important;
            flex-direction: column;
            height: 100%;

            &__image {
                overflow: hidden;
                width: 100%;
                height: 100%;
                max-height: 256px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                @include breakpoint(large) {
                    max-height: 330px;
                    max-width: 330px;
                }

                &:hover {
                    @include breakpoint(large) {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

			&__caption-title-container {
				font-size: 14px;
				height: 45px;
				overflow: hidden;
				display: block;
				background: $accent-color;
				padding: 8px 20px 11px;

				@include breakpoint(medium) {
					font-size: 16px;
					padding-top: 11px;
				}

				&--extended {
					font-weight: bold;
					font-size: 20px;
					background: $white;
					padding: 10px 0px 10px 0px;
				}
			}

			&__caption-text-container {
				height: 100px;
				padding: 10px 5px 0px 0px;

				@include breakpoint(large){
					max-width: 330px;
				}

				&--longCaptionText{
					height: 210px;
					@include breakpoint(medium){
						height: 160px;
					}
				}
			}
        }

        &__wrapper {
            position: relative;
        }

        &--bigCaption {
            #{$root}__gallery {
                &__slide {
                    &__caption-title-container {
                        height: 65px;

                        @include breakpoint(medium) {
							height: 75px;
							max-width: 330px;
                        }
                    }
                }
            }
        }
    }

    &__arrows {

        &__left, &__right {
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            cursor: pointer;
            padding: 20px;
            transition: opacity 0.3s ease;
            background: $white;

            &.slick-disabled {
                opacity: 0;
                cursor: initial;
            }
        }

        &__left {
            left: 0;
        }

        &__right {
            right: 0;
        }
    }

    &--flex-width {
        #{$root}__gallery{
            .slick-list {
                margin: 0 -10px;

                @include breakpoint(large) {
                    margin: 0;
                }

                .slick-slide {
                    margin: 0 10px;

                    @include breakpoint(large) {
                        margin: 0;
                    }
                }
            }
            &__slide {
                img {
                    max-height: 156px;
                    width: 100%;
                    max-width: 100%;
                    height: 100%;
                    object-fit: contain;

                    @include breakpoint(large) {
                        max-width: 100%;
                        max-height: 156px;
                    }
                }
            }
        }
    }
}
