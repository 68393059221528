.m-breadcrumb {
	$root: &;

	&__breadcrumbs {
		@include breadcrumbs-container;
		margin: 0;

		&.is-active {
			#{$root}__breadcrumb {
				display: block;
			}

			#{$root}__mobile-toggle {
				display: none;
			}
		}
	}

	&__breadcrumb {
		@include use-font(roman);
		display: none;

		@include breakpoint(large) {
			display: flex;
			align-items: center;
		}

		&:first-child,
		&:last-child {
			display: flex;
		}

		&:first-child {
			#{$root}__icon {
				display: none;

				@include breakpoint(large) {
					display: flex;
				}
			}

			.is-active & {
				#{$root}__icon {
					display: inline;
				}
			}
		}

		&:last-child {
			color: $secondary-color;
		}

		&::after {
			font-size: rem-calc(8px);
		}

		span {
			text-transform: initial;
		}

		a {
			@include yellow-bar-hover;
			text-transform: initial;
		}
	}

	&__icon {
		margin-left: map-get($spacing, xsmall);
	}

	&__mobile-toggle {
		padding: 0 5px;
		cursor: pointer;
		display: block;

		@include breakpoint(large) {
			display: none;
		}
	}
}
