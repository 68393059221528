.a-select {
	$root: &;

	select {
		height: 2.6875rem;
	}
	// for alignment inside g-formrow because a-select has no label
	align-self: flex-end;

	&__input {
		@include yellow-bar-hover;
	}

	&__input {
		&.has-placeholder {
			@include use-font(light);
			color: $form-label-color;
		}
	}

	&.has-error {
		label {
			color: $alert-color;
		}
	}

	&.is-inactive {
		pointer-events: none;

		#{$root}__input {
			opacity: 0.5;
		}
	}

	&.is-disabled {
		#{$root}__input {
			color: $light-gray-02;
		}
	}
}
