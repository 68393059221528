.m-hero {
	$root: &;
	position: relative;
	max-width: $global-max-width;

	&.has-text {
		#{$root}__image {
			@include breakpoint(small only) {
				position: absolute;
				height: 100%;
			}
		}

		#{$root}__text-container {
			@include breakpoint(small only) {
				position: relative;
				padding: map-get($spacing, medium) 0;
				top: 0;

				&--left {
					padding-right: 10%;
				}

				&--right {
					padding-left: 10%;
				}
			}
		}
	}

	&__image {
		@include object-fit(cover);
		width: 100%;
		max-height: calc(100vh - #{$header-height});
	}

	&__text-container {
		position: absolute;
		top: 10%;

		&--left {
			left: 0;
			right: 0;
			text-align: left;

			@include breakpoint(medium) {
				top: 20%;
				right: 30%;
			}

			@include breakpoint(large) {
				top: 35%;
			}

			> * {
				padding-left: 5%;
			}
		}

		&--right {
			right: 0;
			left: 0;
			text-align: right;

			@include breakpoint(medium) {
				top: 20%;
				left: 30%;
			}

			@include breakpoint(large) {
				top: 35%;
			}

			> * {
				padding-right: 5%;
			}
		}

		&--yellow {
			background-color: $primary-color;
		}
		&--black {
			background-color: $secondary-color;
		}
		&--gray {
			background-color: $accent-color;
		}
		&--no-background {
			background: none;
		}
		> .a-headline--h1 {
			border-bottom: none;
		}
	}

	&__headline {
		@include use-font(bold);
	}

	&__subline {
		@include use-font(bold);
	}

	&__font-color {
		&--black {
			color: $secondary-color;
		}
		&--yellow {
			color: $primary-color;
		}
		&--gray {
			color: $accent-color;
		}
		&--white {
			color: $white;
		}
	}
}
