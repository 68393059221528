.m-teaserevent {
	display: flex;

	&__date-container {
		display: flex;
		flex-shrink: 0;
		flex-direction: column;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background: $primary-color;
		margin-right: map-get($spacing, small);
		align-items: center;
		justify-content: center;
	}

	&__weekday {
		@include use-font(bold);

		display: block;
	}

	&__day {
		@include use-font(bold);

		font-size: 36px;
		line-height: normal;
	}

	&__locationName {
		@include use-font(bold);
	}

	&__headline {
		@include use-font(bold);
		hyphens: auto;
	}

	&__separator {
		margin: 0 5px;
	}
}
