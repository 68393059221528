.m-sitemap {
	$root: &;

	@include xy-grid-container;
	@include xy-grid;

	ul {
		list-style-type: none;
	}

	&__list {
		@include xy-cell(12 of 12);
		margin-bottom: rem-calc(40px);

		&:last-child {
			margin-bottom: 0;
		}

		@include breakpoint(medium) {
			@include xy-cell(6 of 12);
			margin-bottom: rem-calc(60px);

			&:nth-last-child(-n + 2) {
				margin-bottom: 0;
			}
		}
	}

	&__headline {
		@include xy-cell(12 of 12, $breakpoint: medium);
		margin-bottom: 60px;
	}

	&__topic {
		margin-left: 0;

		> #{$root}__submenu-item {
			margin-top: 20px;

			> #{$root}__link {
				text-transform: uppercase;
			}
		}
	}

	&__link {
		text-decoration: none;
		box-shadow: none;
	}

	&__submenu {
		margin-left: rem-calc(30px);

		> #{$root}__submenu-item > #{$root}__link {
			@include use-font(light);
		}
	}
}
