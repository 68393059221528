.m-linklist {
	$root: &;

	&__list {
		list-style-type: none;
		margin: 0;
	}

	&__list-item {
		&:first-child {
			margin-bottom: map-get($spacing, xsmall);

			#{$root}__link {
				@include use-font(bold);

				padding-top: 0;
				text-transform: uppercase;
			}
		}
	}

	&__link {
		padding: 3px 0;
		text-decoration: none;
		box-shadow: none;
		hyphens: auto;
	}
}
