.c-link {
	&--with-icon {
		display: inline-flex;
		align-items: center;

		.a-svgicon {
			margin-left: 5px;
		}
	}
}
