.c-contactinfobox {
	background-color: $light-gray-01;
	padding-top: map-get($spacing, medium);
	padding-bottom: map-get($spacing, medium);

	&__icon-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__icon {
		@include breakpoint(medium) {
			width: 48px;
			height: 48px;
		}
	}
}
