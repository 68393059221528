.a-input {
	$root: &;

	&__input {
		@include yellow-bar-hover;
	}

	&.has-error,
	&.has-error-code {
		#{$root}__input {
			margin-bottom: 8px;
		}

		label {
			color: $alert-color;
		}
	}

	&.is-disabled {
		#{$root}__label {
			color: $light-gray-02;
		}

		#{$root}__wrapper:after {
			display: none;
		}
	}
}
