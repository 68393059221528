.o-symposiumform {
	$root: &;

	&__tabs {
		@include tabs-container;
		list-style: none;
		border: 0;
		border-bottom: 2px solid $light-gray-01;
	}

	&__tab-title {
		@include tabs-title(
			$background-hover: transparent,
			$background-active: transparent
		);
		@include use-font(bold);
		position: relative;

		@include yellow-bar-hover($triggerSelectors: '&.is-active');
	}

	&__tabs-content {
		padding-top: map-get($spacing, xmedium);
	}

	&__subline {
		@include use-font(bold);

		display: inline-block;

		&::before {
			content: counter(section) '.';
		}
	}

	&__delete-registration {
		display: none;
	}

	&__additional-registrations-list {
		counter-reset: section;

		#{$root}__subline {
			counter-increment: section;
		}

		&.is-active {
			#{$root}__delete-registration {
				display: inline-block;
			}
		}

		#{$root}__registration-entry {
			margin-top: 46px;

			@include breakpoint(large) {
				margin-top: 36px;
			}
		}

		#{$root}__additional-registrations {
			+ #{$root}__additional-registrations {
				margin-top: 0;
			}
		}
	}

	&__divider {
		margin-top: map-get($spacing, small);
	}

	&__delete-icon {
		margin-left: map-get($spacing, xsmall);
	}

	&__icon {
		margin-left: map-get($spacing, xsmall);
		margin-bottom: map-get($spacing, xsmall);
	}

	&__delivery {
		margin-bottom: map-get($spacing, large);
	}

	&__form-account {
		.g-form__block {
			+ .g-form__block {
				margin-top: 0;
			}
		}
	}

	&__form
		> .g-form__block
		> .g-form__footer
		> .g-formrow
		> .a-button__additionalRegistrations {
		margin: 1rem 0 1rem 0;
	}
}
