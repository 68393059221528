.a-radiobutton {
	$root: &;
	$radiobutton-size: 20px;

	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	position: relative;
	margin-right: 40px;

	&:last-child {
		margin-right: 0px;
	}

	&:hover {
		#{$root}__input {
			background-color: $light-yellow;
			cursor: pointer;
		}
		#{$root}__label {
			cursor: pointer;
		}
	}

	&__input[type='radio'] {
		appearance: none;
		-webkit-appearance: none;
		background-color: $input-background;
		box-shadow: $input-shadow;
		border-radius: 50%;
		min-width: 20px;
		width: 20px;
		height: 20px;
		margin-bottom: 0;
		margin-right: $form-spacing / 2;

		&:checked {
			background-color: $primary-color;

			+ #{$root}__checked {
				display: block;
			}
		}

		&:disabled {
			background-color: $input-background;

			~ #{$root}__label {
				color: $light-gray-02;
			}
		}
	}

	&__checked {
		display: none;
		background-color: $form-label-color;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		position: absolute;
		top: 6px;
		left: 6px;
	}

	&__label {
		line-height: 1.5;
		// IE11 needs separated flex properties
		flex-grow: 1;
		flex-shrink: 1;
		flex-basis: calc((100% - #{$radiobutton-size}) - #{$form-spacing} / 2);
		cursor: pointer;
		hyphens: auto;

		// ie11 fix
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			flex-basis: auto;
			width: 90%;
		}
	}

	&__error-message {
		// IE11 needs separated flex properties
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 100%;
		margin-top: map-get($spacing, xsmall);

		// show only the first radio button error message
		.has-error:not(:first-child) > & {
			display: none;
		}
	}
}
