.m-icongallery {
    $root: &;
    // margin-top: 42px;

    // @include breakpoint(xmedium) {
    //     margin-top: 80px
    // }

    & .a-headline {
        line-height: 24px;
        text-align: center;
        margin-bottom: 10px;
        @include breakpoint(xmedium) {
            margin-bottom: 30px;
        }

        @include breakpoint(xmedium) {
            text-align: left;
            line-height: 39px;
        }
    }

    &__icons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(xmedium) {
            margin-top: 80px;
        }

        &__icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            padding: 0 20px;

            @include breakpoint(xmedium) {
                flex: 0 1 15%;
                margin: 0 20px 40px;
            }

            & .a-svgicon {
                width: 50px;
                height: 50px;
                @include breakpoint(xmedium) {
                    width: 60px;
                    height: 60px;
                }
            }

            &__label {
                padding-top: 12px;
                font-size: 16px;
                line-height: 18px;
                max-width: 72px;
                text-align: center;
            }

            &:hover {
                & .a-svgicon {
                    transform: none;
                    @include breakpoint(xmedium) {
                        transform: scale3d(1.2, 1.2, 1.2)
                    }
                }

            }
        }
    }

    &--no-headline {
        #{$root}__icons {
            margin-top: 0;
        }
    }
}
