.m-badge {
	display: flex;
	flex-flow: column;

	@include breakpoint(medium) {
		align-items: center;
		flex-flow: row wrap;
	}

	&__badgelink {
		margin-bottom: map-get($spacing, xsmall);

		@include breakpoint(medium) {
			margin-right: map-get($spacing, xsmall);
		}

		&:last-child {
			@include breakpoint(medium) {
				margin-right: 0;
				margin-bottom: map-get($spacing, xsmall);
			}
		}

		&-img {
			width: 140px;
			height: auto;

			@include breakpoint(medium) {
				width: 160px;
			}
		}
	}
}
