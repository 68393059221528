.m-socialmedialinks {
	display: flex;
	flex-wrap: wrap;

	&__link {
		display: flex;
		align-items: center;
		justify-content: center;
		transition: transform 300ms ease-out;
		padding: map-get($spacing, xsmall);
		transform: translateX(-1 * map-get($spacing, xsmall));

		&:not(:last-child) {
			margin-right: map-get($spacing, xsmall);
		}

		&:hover {
			@include svg-scale;
		}

		@include breakpoint(xmedium) {
			transform: none;
		}
	}
}
