.o-lightsearch {
	$root: &;

	&__container {
		display: flex;
		flex-direction: column;
		padding-left: map-get($spacing, small);
		padding-right: 1.25rem;
		margin-top: map-get($spacing, medium);

		@include breakpoint(large) {
			flex-direction: row;
		}
	}

	&__totalresults {
		margin-bottom: map-get($spacing, small);

		@include breakpoint(large) {
			margin-bottom: map-get($spacing, medium);
		}

		&-query {
			@include use-font(roman);
		}

		&-query-suggestion {
			font-size: rem-calc(14px);

			a {
				font-size: rem-calc(16px);
			}
		}
	}

	&__column-wrapper {
		@include breakpoint(large) {
			position: sticky;
			top: $header-height + map-get($spacing, small);
		}
	}

	&__column--small {
		width: 100%;
		position: relative;

		@include breakpoint(large) {
			width: 25%;
		}

		label {
			@include use-font(light);
		}
	}

	&__column--large {
		align-content: flex-start;

		@include breakpoint(large) {
			width: 100%;
		}
	}

	&__filterheadline {
		display: flex;
		@include use-font(bold);
		font-size: rem-calc(14px);
		margin-right: map-get($spacing, xsmall);
		margin-bottom: map-get($spacing, medium);

		@include breakpoint(large) {
			@include use-font(light);
			font-size: $global-font-size;
		}

		#{$root}__icon-container {
			align-self: center;
		}

		&-copy {
			margin-left: map-get($spacing, xsmall);
			align-self: center;
		}
	}

	&__search-results,
	&__search-loading {
		margin-bottom: map-get($spacing, medium);
		padding-left: 0;
		padding-right: 0;
	}

	&__icon-close {
		width: 15px;
		height: 15px;
		margin-left: map-get($spacing, xsmall);
	}

	&__page-controls {
		@include xy-gutters;
		display: flex;
		justify-content: center;
		flex-flow: wrap;
		margin-top: map-get($spacing, xmedium);

		@include breakpoint(xmedium) {
			margin-top: map-get($spacing, xlarge);
		}
	}

	&__page-first,
	&__page-last,
	&__page-next,
	&__page-previous {
		cursor: pointer;
	}

	&__page-first,
	&__page-last {
		font-size: rem-calc(14px);
		text-decoration: underline;
		align-self: flex-end;
		margin-bottom: 5px;
		flex-grow: 1;
		flex-shrink: 0;

		@include breakpoint(medium) {
			flex-grow: 0;
		}
	}

	&__page-first {
		margin-right: map-get($spacing, small);
		order: 4;

		@include breakpoint(medium) {
			order: 1;
		}
	}

	&__page-count {
		font-size: rem-calc(24px);
		margin: 0 map-get($spacing, small) 0;
	}

	&__page-last {
		margin-left: map-get($spacing, small);
		order: 5;
	}

	&__pagination-container {
		display: flex;
		justify-content: center;
		flex-basis: 100%;
		margin-bottom: map-get($spacing, xsmall);

		@include breakpoint(medium) {
			flex-basis: auto;
			order: 2;
			margin-bottom: 0;
		}
	}

	&__pages {
		@include xy-cell(12 of 12);

		@include breakpoint(medium) {
			@include xy-cell(3 of 12);
		}
	}

	&__results-headline.a-headline {
		@include breakpoint(medium) {
			margin-bottom: 0;
		}
	}

	&__split {
		display: flex;
		justify-content: space-between;
		flex-flow: column;

		@include breakpoint(medium) {
			flex-flow: row wrap;
			align-items: flex-end;
		}
	}

	&__search-sort {
		width: 100%;

		@include breakpoint(medium) {
			flex: 0 0 315px;
		}
	}

	&__filter-button {
		@include use-font(roman);
		background-color: $accent-color;
		border-radius: $button-radius;
		padding: 10px 15px;
		cursor: pointer;
		margin-right: 20px;
		display: inline-flex;
		margin-bottom: map-get($spacing, medium);

		span {
			font-size: rem-calc(14px);
		}
	}

	&__active-filters {
		margin-top: map-get($spacing, medium);
	}

	&__arrow-left {
		transform: rotate(180deg);
	}

	&__suggestion-text {
		display: inline-block;
		margin-top: map-get($spacing, xsmall);
	}

	&__js-search-headline{
		margin-top: map-get($spacing, xsmall);
	}
}
