.m-richtext {
	a {
		@include yellow-bar-hover(true);
		@include use-font(bold);
	}

	&--viega-yellow {
		// Background color of richtext is viega-yellow. Therefore default link hover bar color yellow can not be used.
		// Set hover bar color to other color.
		a {
			@include light-grey-bar-hover(true);
		}
	}

	ul,
	ol {
		margin-left: map-get($spacing, small);
	}

	*:last-child {
		margin-bottom: 0;
	}

	table {
		@extend %table;
	}

	.text-align-left {
		text-align: left;
	}

	.text-align-center {
		text-align: center;
	}

	.text-align-right {
		text-align: right;
	}
}
