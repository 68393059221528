.m-searchfacetfilter {
	$root: &;

	&__facet {
		> .o-gridcontainer {
			padding: 0;

			&#{$root}__filter-container {
				grid-gap: map-get($spacing, xsmall);
			}
		}
	}

	&__filter-item {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&--with-image {
			align-items: stretch;
			background-color: $white;
			padding-left: map-get($spacing, small);
			min-height: 6.25rem;

			#{$root}__checkbox {
				margin-bottom: 0;
			}
		}
	}

	&__image {
		@include object-fit(contain);
		width: auto;
		height: auto;
		max-width: 50px;
		max-height: 50%;
		align-self: center;
		margin-left: map-get($spacing, small);
		margin-right: map-get($spacing, xsmall);
		flex: 0 1 50px;

		@include breakpoint(medium) {
			max-height: 100px;
			max-width: 100px;
			flex: 0 0 100px;
		}
	}

	&__checkbox {
		align-self: center;
		flex-wrap: nowrap;
		margin-bottom: map-get($spacing, small);

		&:last-child {
			margin-bottom: 0;
		}

		& > .a-checkbox__input[type='checkbox'] {
			margin: auto 0.5rem auto auto;
		}

		@include breakpoint(medium) {
			&:nth-last-child(-n + 3) {
				margin-bottom: 0;
			}
		}

		// ie11 fix
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			flex-wrap: inherit;

			.a-checkbox__label {
				flex-basis: auto;
			}
		}
	}

	&__accordion.m-accordion--hasBg {
		.m-accordion__title {
			.m-accordion__text-headline {
				font-size: rem-calc(14px);
			}
		}

		.m-accordion__content {
			padding: rem-calc(20px) rem-calc(10px);
		}
	}
}
