.a-galleryarrows {
	$root: &;

	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__arrow {
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		width: 40px;
		height: 40px;
		pointer-events: auto;
		cursor: pointer;
		opacity: 0.5;
		background: $accent-color;
		transition: opacity $expo-tween-t1-time $expo-tween-t1;

		&:hover {
			opacity: 1;
		}

		&.slick-disabled {
			opacity: 0;
			pointer-events: none;
		}
	}

	&__arrow-icon.a-svgicon {
		color: black;
	}
}
