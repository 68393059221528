.m-lightboxactionimage {
	$root: &;

	position: relative;
	cursor: pointer;
	border: 1px solid $color-borders;

	&:hover {
		#{$root}__zoom-button {
			opacity: 1;
		}
	}

	// overwrite foundation button styling
	&__zoom-button[type='button'] {
		display: block;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 40px;
		height: 40px;
		z-index: 1;
		border-radius: 0;
		background-color: $accent-color;
		opacity: 0.6;
		transition: opacity $expo-tween-t1-time $expo-tween-t1;
		cursor: pointer;
	}

	&__headlines {
		margin-bottom: map-get($spacing, small);

		#{$root}__headline,
		#{$root}__subline {
			margin-bottom: 0;
		}
	}

	&__image {
		@include object-fit(contain);
		max-height: 100vh;

		&.has-margin {
			margin-bottom: map-get($spacing, small);
		}
	}

	&__link {
		display: block;
		margin-bottom: map-get($spacing, xsmall);
		width: fit-content;
	}
}
