.m-pagination {
	$button-width: 40px;
	// set min-width to prevent jumping pagination controls
	$max-button-width: calc(#{$button-width} + 10px);

	display: inline-flex;

	&__top-pagination {
		margin-bottom: map-get($spacing, small);
	}

	&__certificates {
		display: flex;
		justify-content: center;
		flex-flow: wrap;
	}

	&__button,
	&__list-item {
		min-width: $button-width;
		height: $button-width;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: background-color 0.2s ease-out;
	}

	&__page-list {
		margin: 0;
		list-style-type: none;
		display: flex;
		justify-content: center;
		min-width: $max-button-width;

		@include breakpoint(medium) {
			min-width: calc(#{$max-button-width} * 5);
		}
	}

	&__list-item {
		// only show the active pagination (1) item on mobile
		display: none;

		@include breakpoint(medium) {
			// show all (5) current pagination items
			display: flex;
		}

		&.is-active {
			background-color: $primary-color;
			display: flex;
			font-weight: bold;
		}

		&:hover {
			background-color: $light-yellow;
		}
	}
}
